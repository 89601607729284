// import BigNumber from 'bignumber.js'
import React, { createContext, useContext } from 'react'

// interface VaultBalance {
//   total: BigNumber
//   delegated: BigNumber
//   deployed: BigNumber
//   nav: number
//   openedPositions: number
//   multisig: number
//   idle: number
// }

interface VaultBalanceContextProps {
  refreshVaultBalance: () => void
}

const VaultBalanceContext = createContext<VaultBalanceContextProps | undefined>(undefined)

export const useVaultBalance = () => {
  const context = useContext(VaultBalanceContext)
  if (!context) {
    throw new Error('useVaultBalance must be used within a VaultBalanceProvider')
  }
  return context
}

export const VaultBalanceProvider: React.FC<{
  value: VaultBalanceContextProps
  children: React.ReactNode
}> = ({ value, children }) => {
  return <VaultBalanceContext.Provider value={value}>{children}</VaultBalanceContext.Provider>
}
