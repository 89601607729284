import { Button } from '@/app/components'
import { clsxm } from '@/app/helpers/clsxm'
import { type AlertType, type IAlert, useAlertsStore } from '@/app/stores/useAlertsStore'
import { type HTMLAttributes, useEffect, useState } from 'react'

type Props = HTMLAttributes<HTMLDivElement> & {
  alert: IAlert
  index: number
}

export function Alert({ alert, index }: Props) {
  const { reasonMessage, type, tx } = alert
  const [visible, setVisible] = useState(false)
  const removeAlert = useAlertsStore(state => state.removeAlert)
  const hideAlert = useAlertsStore(state => state.hideAlert)
  const dismissAlert = () => {
    hideAlert(index)
    setVisible(false)
    removeAlert(index)
  }
  useEffect(() => {
    if (alert.visible === true) {
      setVisible(true)
    }
  }, [alert.visible])
  const alertMessageByType = (type: AlertType) => {
    switch (type) {
      case 'inProgress':
        return 'Please wait while transaction is in progress...'
      case 'success':
        return 'Transaction is confirmed successfully. Note that it may take a few minutes for it to finalize on the blockchain and for position statuses to update.'
      case 'transactionFails':
        return `An error has occurred while completing the transaction: ${reasonMessage}`
      case 'transactionWaiting':
        return 'Transaction is taking longer than usual and may have expired due to network congestion.'
      case 'error':
        return `An error has occurred: ${reasonMessage}`
      default:
        break
    }
  }
  const testColorByType = (type: AlertType) => {
    switch (type) {
      case 'inProgress':
        return 'text-main'
      case 'success':
        return 'text-main'
      case 'transactionFails':
      case 'transactionWaiting':
        return 'text-alt'
      case 'error':
        return 'text-alt'
      default:
        break
    }
  }
  return (
    <>
      {visible && <div className='bg-translucent fixed inset-0 z-40 bg-opacity-60'/>}
      <div
        className={clsxm(
          'bg-translucent fixed left-1/2 top-0 z-50 w-[300px] -translate-x-1/2 -translate-y-20 transform rounded-lg px-[10px] py-5 opacity-0 transition-all duration-500',
          {
            'translate-y-20 opacity-100': visible,
            '-translate-y-20 opacity-0': !visible,
          },
        )}
      >
        <div className='text-center'>
          <p className={`${testColorByType(type)} mb-[10px] text-sm`}>{alertMessageByType(type)}</p>
          <div className='flex justify-center gap-[10px]'>
            {type !== 'error' && type !== 'transactionWaiting' && (
              <a href={`https://solscan.io/tx/${tx}`} rel='noreferrer' target='_blank'>
                <Button color={type === 'inProgress' ? 'solid' : 'main'} size='s'>
                  View on Solscan
                </Button>
              </a>
            )}
            {type === 'transactionWaiting' && (
              <div className='flex justify-center gap-[10px]'>
                <a href={`https://solscan.io/tx/${tx}`} rel='noreferrer' target='_blank'>
                  <Button color='main' size='s'>
                    View on Solscan
                  </Button>
                </a>
                <Button color='alt' size='s' onClick={dismissAlert}>
                  Stop Waiting
                </Button>
              </div>
            )}
            {type !== 'inProgress' && type !== 'transactionWaiting' && (
              <Button color={type === 'success' ? 'solid' : 'alt'} size='s' onClick={dismissAlert}>
                OK
              </Button>
            )}
          </div>
          {type === 'transactionFails' && alert.sentryId && (
            <span className='mt-[10px] text-[10px] opacity-60'>
              ID:
              {alert.sentryId}
            </span>
          )}
        </div>
      </div>
    </>
  )
}
