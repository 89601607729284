import { trackNavigationClickGA } from '@/app/analytics'
import { IconDiscordLogo, IconTelegram, IconTwitterLogo } from '@/assets/svgs'
import clsx from 'clsx'
import type { HTMLAttributes } from 'react'

type Props = HTMLAttributes<HTMLDivElement>

export function Footer({ className, ...props }: Props) {
  return (
    <footer
      {...props}
      className={clsx(className, 'border-vibrant px-safe-or-[10px] pb-safe-or-5 desk:px-[30px] flex items-center justify-center gap-5 border-t pt-5 text-xs')}
    >
      <a
        className='btn-icon'
        href='https://lavarage.gitbook.io/'
        rel='noreferrer'
        target='_blank'
        onClick={() => trackNavigationClickGA('Docs')}
      >
        Docs
      </a>
      <a
        className='btn-icon'
        href='https://lavarage.gitbook.io/lavarage/terms-and-conditions'
        rel='noreferrer'
        target='_blank'
        onClick={() => trackNavigationClickGA('Terms')}
      >
        Terms
      </a>
      <a
        className='btn-icon'
        href='https://lavarage.gitbook.io/lavarage/privacy-policy'
        rel='noreferrer'
        target='_blank'
        onClick={() => trackNavigationClickGA('Privacy')}
      >
        Privacy
      </a>
      <a
        className='btn-icon'
        href='https://lavarage.gitbook.io/lavarage/referral-and-affiliates-program'
        rel='noreferrer'
        target='_blank'
        onClick={() => trackNavigationClickGA('Affiliate')}
      >
        Affiliate
      </a>
      <a href='https://x.com/lavaragexyz' rel='noreferrer' target='_blank' onClick={() => trackNavigationClickGA('Twitter')}>
        <IconTwitterLogo className='btn-icon h-[14px] w-auto'/>
      </a>
      <a href='https://discord.gg/lavarage' rel='noreferrer' target='_blank'>
        <IconDiscordLogo className='btn-icon h-[14px] w-auto' onClick={() => trackNavigationClickGA('Discord')}/>
      </a>
      <a href='https://t.me/lavarage_xyz' rel='noreferrer' target='_blank'>
        <IconTelegram className='btn-icon h-[14px] w-auto' onClick={() => trackNavigationClickGA('Telegram')}/>
      </a>
    </footer>
  )
}
