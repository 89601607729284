import { type TokenInfo } from '@/services'
import { type Pool } from '@lavarage/entities'

export const getRelatedPool = (token: TokenInfo, pools: Pool[]) => {
  const relatedPools = pools.filter(pool => pool.baseCurrency.address === token?.address && pool.maxBorrow.gt(0))

  const relatedPoolWithBiggestLiquidity = relatedPools.sort((a, b) => b.nodeWallet.totalFunds?.minus(a.nodeWallet.totalFunds).toNumber())[0]

  return relatedPoolWithBiggestLiquidity
}
