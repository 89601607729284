import { Conditional, TabItem, TabsList } from '@/app/components'
import { useWallet } from '@solana/wallet-adapter-react'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

type StakeTabControlProps = {
  quantityOfClaim: number
}

interface StakeTabItemProps {
  path: string
  isExactPath?: boolean
  label: string
  quantityOfClaim?: number
}

const StakeTabItem = ({ path, isExactPath, label, quantityOfClaim }: StakeTabItemProps) => {
  const location = useLocation()
  const navigate = useNavigate()

  const isActive = isExactPath ? location.pathname === path : location.pathname.startsWith(path)
  const handleClick = () => {
    navigate({ pathname: path, search: location.search })
    window.scrollTo(0, 0)
  }

  return <TabItem handleClick={handleClick} isActive={isActive} label={label} quantity={quantityOfClaim}/>
}

export const StakeTabControl = ({ quantityOfClaim }: StakeTabControlProps) => {
  const { connected } = useWallet()
  const location = useLocation()
  const navigate = useNavigate()

  /* @todo move this out */
  useEffect(() => {
    if (!connected && location.pathname === '/stake/claim') {
      navigate({ pathname: '/stake', search: location.search })
    }
  }, [connected])

  return (
    <TabsList>
      <StakeTabItem isExactPath label='Stake' path='/stake'/>
      <StakeTabItem label='Unstake' path='/stake/unstake'/>
      <Conditional if={connected}>
        <StakeTabItem label='Claim' path='/stake/claim' quantityOfClaim={quantityOfClaim}/>
      </Conditional>
    </TabsList>
  )
}
