import { JUPITER_PLATFORM_FEE_BPS } from '@/config'
import { BaseService } from '@/services/BaseService'
import { formatSolToLamports } from '@/utils'
import { type PriceResponse } from '@lavarage/entities'
import { PublicKey } from '@solana/web3.js'
import { type AxiosResponse } from 'axios'
import type BigNumber from 'bignumber.js'

export class JupiterSource extends BaseService {
  readonly urls = {
    price: 'https://price.jup.ag/v4/price',
    swapQuote: (from: string, to: string, amount: string, slippage: number, avoidPlatformFee?: boolean) => `https://quote-api.jup.ag/v6/quote?inputMint=${from}&outputMint=${to}&amount=${amount}&slippageBps=${Math.floor(slippage)}&maxAccounts=26&platformFeeBps=${avoidPlatformFee ? 0 : JUPITER_PLATFORM_FEE_BPS}`,
    swap: 'https://quote-api.jup.ag/v6/swap',
    swapIx: 'https://quote-api.jup.ag/v6/swap-instructions',
  } as const

  async getPrice(ids: string, vsToken?: string): Promise<PriceResponse> {
    try {
      const response: AxiosResponse<PriceResponse> = await this.http.get(this.urls.price, {
        params: {
          ids,
          vsToken,
        },
      })

      return response.data
    }
    catch (error) {
      // ignore the error here
    }
  }

  async getSwapTx(from: string, to: string, amount: number, slippage: number, userPubKey: string, avoidPlatformFee?: boolean) {
    const quoteResponse = await this.getSwapQuote(from, to, formatSolToLamports(amount), slippage, avoidPlatformFee)

    const result = await (
      await this.http.post(this.urls.swap, {
        quoteResponse,
        userPublicKey: new PublicKey(userPubKey),
        wrapAndUnwrapSol: true,
      })
    ).data

    return result
  }

  async getSwapIx(from: string, to: string, amount: BigNumber, slippage: number, userPubKey: string, positionAccountTokenAccountPubKey?: string, avoidPlatformFee?: boolean) {
    const quoteResponse = await this.getSwapQuote(from, to, amount, slippage, avoidPlatformFee)
    const [feeAccount] = PublicKey.findProgramAddressSync(
      [
        Buffer.from('referral_ata'),
        new PublicKey('A55dyZ5rBz4UtB2ursLhGS7CzXMAJM7bKm3StJWAtew1').toBuffer(),
        new PublicKey('So11111111111111111111111111111111111111112').toBuffer(),
      ],
      new PublicKey('REFER4ZgmyYx9c6He5XfaTMiGfdLwRnkV4RPp9t9iF3'),
    )

    const instructions = await (
      await this.http.post(this.urls.swapIx, {
        quoteResponse,
        userPublicKey: userPubKey,
        wrapAndUnwrapSol: true,
        destinationTokenAccount: positionAccountTokenAccountPubKey,
        feeAccount,
      })
    ).data

    return { instructions, quoteResponse }
  }
  private async getSwapQuote(from: string, to: string, amount: BigNumber, slippage: number, avoidPlatformFee: boolean = false) {
    const amountInLamports = amount.toString().split('.')[0]
    const response = await this.http.get(this.urls.swapQuote(from, to, amountInLamports, slippage * 100, avoidPlatformFee))

    return response.data
  }
}

export const jupiterSource = new JupiterSource()
