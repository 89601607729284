import { trackWalletConnectGA, trackWalletSuccessConnectGA } from '@/app/analytics.js'
import { type Wallet, useWallet } from '@solana/wallet-adapter-react'
import { useWalletModal } from '@solana/wallet-adapter-react-ui'
import { useEffect, useRef, useState } from 'react'
import { RewardsButton } from './buttons/RewardsButton.js'
import { Conditional } from './Conditional.js'
import { ConnectButton } from './ConnectButton.js'
import { UserMenu } from './UserMenu.js'

function trackAndRemoveWalletsFromLocalStorage(wallets: Wallet[]) {
  const localStorageWallet = localStorage.getItem('walletName')
  if (!localStorageWallet) return
  const parsedWalletValue = JSON.parse(localStorageWallet)
  const walletsToCheck = [
    { name: 'Phantom', globalObject: window.phantom },
    { name: 'Solflare', globalObject: window.solflare },
    { name: 'OKX Wallet', globalObject: window.okxwallet },
    { name: 'Gate Wallet', globalObject: window.gatewallet },
  ]

  walletsToCheck.forEach(({ name, globalObject }) => {
    if (parsedWalletValue === name && globalObject === undefined) {
      localStorage.removeItem('walletName')

      const wallet = wallets.find(({ adapter }) => adapter.name === name)

      if (wallet && wallet.adapter.disconnect) {
        wallet.adapter.disconnect()
      }
    }
  })
}

const CustomWalletMultiButton = () => {
  const hasWalletModalBeenOpened = useRef(false)
  const { setVisible: setModalVisible, visible: isModalVisible } = useWalletModal()
  const { wallets, disconnect, publicKey } = useWallet()
  const [isUserMenuVisible, setIsUserMenuVisible] = useState(false)
  const rootRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (!rootRef.current || !menuRef.current) return

      if (menuRef.current && !rootRef.current.contains(event.target as HTMLElement)) {
        setIsUserMenuVisible(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuRef])

  useEffect(() => {
    if (!isModalVisible && hasWalletModalBeenOpened.current) {
      trackAndRemoveWalletsFromLocalStorage(wallets)
    }
  }, [isModalVisible, wallets])

  useEffect(() => {
    if (!publicKey) return
    const location = window.location.pathname
    trackWalletSuccessConnectGA({ walletAddress: publicKey?.toBase58(), url: location })
  }, [publicKey])

  const connectWallet = () => {
    trackWalletConnectGA()
    setModalVisible(true)
    hasWalletModalBeenOpened.current = true
  }

  const showUserMenu = () => {
    setIsUserMenuVisible(!isUserMenuVisible)
  }

  return (
    <div ref={rootRef} className='relative flex h-[44px] items-center justify-end'>
      <Conditional if={publicKey && isUserMenuVisible}>
        <UserMenu
          ref={menuRef}
          className='desk:top-[-10px] absolute right-[-10px] top-0 w-[calc(100%+20px)] min-w-40'
          onChangeWallet={() => connectWallet()}
          onDisconnect={disconnect}
        />
      </Conditional>
      <RewardsButton alwaysShowsTephras={isUserMenuVisible}/>
      <ConnectButton publicKey={publicKey ?? undefined} onClick={() => publicKey ? showUserMenu() : connectWallet()}/>
    </div>
  )
}

export { CustomWalletMultiButton }
