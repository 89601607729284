import { Layout } from '@/app/pages/layout/Layout'

export function NotFoundPage() {
  return (
    <Layout backgroundType='not-found'>
      <div className='h-[30svh]'/>
      <span className='mb-[20px]'>Looks like you got lost in the fiery abyss...</span>
      <a
        className='gradient-button desk:hover:opacity-70 desk:group-hover:opacity-70 flex h-[44px] w-[122px] items-center justify-center rounded-lg'
        href='/'
        rel='noreferrer'
      >
        <span>Head Back</span>
      </a>
    </Layout>
  )
}
