import {
    trackRepayCancelledGA,
    trackRepayExecuteGA,
    trackRepayFailedGA,
    trackRepaySuccessGA,
    trackSellCancelledGA,
    trackSellExecuteGA,
    trackSellFailedGA,
    trackSellSuccessGA,
} from '@/app/analytics'
import { Button, Conditional, SlippageModal, TokenImage } from '@/app/components'
import { Modal } from '@/app/components/Modal'
import { clsxm } from '@/app/helpers/clsxm'
import { type ActionType, sendTransaction } from '@/app/helpers/sendTransaction'
import { useBalance } from '@/app/hooks/useBalance'
import { useNetworkStatus } from '@/app/hooks/useNetworkStatus'
import { CloseModalButton, CloseModalTabControl } from '@/app/pages/positions/components'
import { calculatePositionMetrics, getCloseModalButtonMode } from '@/app/pages/positions/utils'
import { useLavarage } from '@/app/providers/LavarageProvider.js'
import { useAlertsStore, usePreferencesStore } from '@/app/stores'
import { IconBirdeye, IconSolscan } from '@/assets'
import { JUPITER_PLATFORM_FEE_BPS, PROFIT_FEE, SOL_ADDRESS } from '@/config'
import { jupiterSource } from '@/services/sources'
import { formatSol } from '@/utils/formatters'
import { web3 } from '@coral-xyz/anchor'
import { type Position } from '@lavarage/entities'
import { getAccount, getAssociatedTokenAddressSync } from '@solana/spl-token'
import { useWallet } from '@solana/wallet-adapter-react'
import { LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js'
import { useQueryClient } from '@tanstack/react-query'
import BigNumber from 'bignumber.js'
import { forwardRef, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import type { TokenInfo } from '../../../../services/types.js'
import { Hint } from '../../../components/Hint.js'
import { LabelledValue } from '../../../components/LabelledValue.js'
import { useNftAccess } from '../../../hooks/useNftAccess.js'

type Props = {
  position: Position
  prices?: Record<string, { price: number }>
  timezone: string
  tokens?: TokenInfo[]
  onExit?: () => void
}
export enum CloseModalTabs {
  SELL = 'Sell',
  REPAY = 'Repay',
}

export const CloseModal = forwardRef<HTMLDivElement, Props>(({
  position,
  prices,
  timezone,
  tokens,
  onExit,
}, ref) => {
  const { borrowedAmount, collateralSize, durationDays, interestAccrued, pool } = position
  const { baseCurrency } = pool
  const collateralPrice = baseCurrency ? prices?.[baseCurrency.address]?.price : undefined
  const solPrice = prices?.[SOL_ADDRESS]?.price
  const lavarage = useLavarage()
  const wallet = useWallet()
  const slippage = usePreferencesStore(state => state.slippage)
  const queryClient = useQueryClient()
  const [addAlert, removeAlert, loading, setLoading, setConfirming, confirming] = useAlertsStore(useShallow(state => [state.addAlert, state.removeAlert, state.loading, state.setLoading, state.setConfirming, state.confirming]))
  const token = tokens?.find(({ address }) => address === baseCurrency?.address)

  const {
    pnl,
    currentPrice,
    currentLTV,
    transferredToWallet,
    averageDailyInterest,
    sellFromPosition,
  } = calculatePositionMetrics(position, solPrice, collateralPrice, timezone)

  const isButtonDisabled = loading || confirming
  const [currentTab, setCurrentTab] = useState<CloseModalTabs>(CloseModalTabs.SELL)
  const totalOwing = borrowedAmount.plus(interestAccrued).toNumber()
  const [confirmedSellFromPosition, setConfirmedSellFromPosition] = useState(0)

  const isPositionCreatedBeforeAddedFee = position.openDate < new Date('2024-09-04') // 12th September 2024
  const { hasLavaRockNFT } = useNftAccess()

  const sellPosition = async () => {
    try {
      setLoading(true)
      setConfirming(true)
      trackSellExecuteGA()
      const positionATA = getAssociatedTokenAddressSync(new web3.PublicKey(position.pool.baseCurrency?.address), new web3.PublicKey(position.publicKey), true)
      const balance = await getAccount(lavarage.program.provider.connection, positionATA)
      const actionType: ActionType = 'sell'
      const info = await jupiterSource.getSwapIx(
        token.address,
        SOL_ADDRESS,
        BigNumber(Number(balance.amount)),
        parseFloat(slippage),
        lavarage.program.provider.publicKey?.toBase58(),
        undefined,
        isPositionCreatedBeforeAddedFee || hasLavaRockNFT,
      )

      setConfirmedSellFromPosition(Number(info.quoteResponse.outAmount) / LAMPORTS_PER_SOL)

      const tx = await lavarage.sellPosition(position.pool.publicKey, balance.amount.toString(), new PublicKey(position.seed), info)
      const txDetailsForSentry = {
        type: actionType,
        walletAddress: wallet.publicKey?.toBase58(),
        walletAdapter: wallet.wallet?.adapter.name,
        tokenSymbol: token.symbol,
        tokenAddress: token.address,
        amount: parseFloat(balance.amount.toString()),
        position,
        slippage: parseFloat(slippage),
      }

      const res = await sendTransaction(
        tx,
        addAlert,
        removeAlert,
        setConfirming,
        lavarage.program.provider,
        wallet,
        txDetailsForSentry,
        actionType,
        queryClient,
      )
      if (res) {
        trackSellSuccessGA()
      }
    }
    catch (error) {
      if ((error as Error).message.includes('User rejected the request')) {
        trackSellCancelledGA()
      }
      else {
        trackSellFailedGA()
        addAlert({
          reasonMessage: (error as Error).message,
          type: 'error',
        })
      }
      console.error('Error in selling position:', error)
    }
    finally {
      setConfirming(false)
      setLoading(false)
      setConfirmedSellFromPosition(0)
    }
  }

  const repaySol = async () => {
    try {
      setLoading(true)
      trackRepayExecuteGA()

      const tx = await lavarage.repaySol(position.pool.publicKey, borrowedAmount.toNumber(), new PublicKey(position.seed), Number(position.collateralSize))

      setConfirming(true)
      const txDetailsForSentry = {
        type: 'Repay',
        walletAddress: wallet.publicKey?.toBase58(),
        walletAdapter: wallet.wallet?.adapter.name,
        tokenSymbol: token.symbol,
        tokenAddress: token.address,
        amount: borrowedAmount.toNumber(),
        position,
      }

      const res = await sendTransaction(tx, addAlert, removeAlert, setConfirming, lavarage.program.provider, wallet, txDetailsForSentry, 'repay', queryClient)
      if (res) {
        trackRepaySuccessGA()
      }
    }
    catch (error) {
      if (!(error as Error).message.includes('User rejected the request')) {
        trackRepayCancelledGA()
      }
      else {
        trackRepayFailedGA()
      }
    }
    finally {
      setConfirming(false)
      setLoading(false)
    }
  }

  // FROZEN PARAMS
  const realPnL = confirmedSellFromPosition
    ? confirmedSellFromPosition - position.initialMargin.toNumber() - borrowedAmount.toNumber()
    : Number(pnl)
  const jupiterPlatformFee = (hasLavaRockNFT || isPositionCreatedBeforeAddedFee) ? 0 : Number(sellFromPosition) * JUPITER_PLATFORM_FEE_BPS / 10_000
  const displayedSellFromPosition = confirmedSellFromPosition ? confirmedSellFromPosition + jupiterPlatformFee : Number(sellFromPosition)
  const profitFee = realPnL >= 0 ? realPnL * (PROFIT_FEE / 100) : 0
  const realCurrentLTV = confirmedSellFromPosition ? (borrowedAmount.toNumber() / confirmedSellFromPosition) * 100 : currentLTV.toNumber()
  const displayedFee = profitFee + jupiterPlatformFee
  const displayedTotal = displayedSellFromPosition - totalOwing - displayedFee
  const isOnline = useNetworkStatus()
  const { availableSol } = useBalance()
  const closeModalButtonMode = getCloseModalButtonMode({
    isOnline,
    currentTab,
    confirming,
    availableSol,
    borrowedAmount: borrowedAmount.toNumber(),
    isRestricted: false,
  })

  return (
    <Modal className='z-40'>
      <div ref={ref} className='bg-vibrant modal-box max-w-[380px] p-[10px] pb-5 text-sm'>
        <div className='mb-[10px] flex grow items-center justify-between py-[8.5px] text-base'>
          <div className='w-[44px]'/>
          <span>Close Position</span>
          <div className='flex'>
            <IconSolscan className='my-auto mr-[10px] cursor-pointer' onClick={() => window.open(`https://solscan.io/account/${position?.publicKey}`, '_blank', 'noopener,noreferrer')}/>
            <IconBirdeye className='my-auto cursor-pointer' onClick={() => window.open(`https://birdeye.so/token/${position?.pool.baseCurrency?.address}?chain=solana`, '_blank', 'noopener,noreferrer')}/>
          </div>
        </div>
        <div className='flex flex-col gap-5'>
          <section className='bg-main flex flex-col rounded-lg bg-opacity-5 px-[10px] py-[15px]'>
            <div className='mb-[10px] flex items-center justify-between text-base'>
              <div className='flex items-center gap-2'>
                <div className='text-center'>
                  <TokenImage altName={token?.name} logoURI={token?.logoURI}/>
                </div>
                <span className='text-center'>{`${formatSol(collateralSize.toNumber(), position.pool.baseCurrency?.decimals)} ${token?.symbol.toLocaleUpperCase()}`}</span>
              </div>
              <div className='flex'>
                <span className='mr-1'>
                  {formatSol(displayedSellFromPosition)}
                  {' '}
                  SOL
                </span>
              </div>
            </div>
            <div className='flex h-[17px] gap-[10px]'>
              <div className='flex flex-1 items-center justify-between'>
                <span className='my-auto opacity-70'>LTV</span>
                <div>
                  <span
                    className={clsxm(
                      {
                        'text-purple': realCurrentLTV >= 90,
                      },
                      {
                        'text-yellow': realCurrentLTV >= 70 && realCurrentLTV < 90,
                      },
                      {
                        'text-main': realCurrentLTV < 70,
                      },
                    )}
                  >
                    {formatSol(realCurrentLTV, 1)}
                    %
                  </span>
                </div>
              </div>
              <div className='flex flex-1 items-center justify-between'>
                <span className='opacity-70'>PnL</span>
                <span
                  className={clsxm(
                    {
                      'text-green': realPnL > 0,
                    },
                    {
                      'text-yellow': realPnL < 0,
                    },
                  )}
                >
                  <span>
                    {formatSol(realPnL)}
                    {' '}
                    SOL
                  </span>
                </span>
              </div>
            </div>
          </section>

          <section className='border-main flex flex-col gap-[5px] rounded-lg border border-opacity-10 p-[10px]'>
            <div className='flex justify-center text-xs opacity-40'>LOAN BALANCE</div>
            <div className='flex justify-between'>
              <div className='opacity-70'>Borrowed Amount</div>
              <div>
                {formatSol(borrowedAmount.toNumber())}
                {' '}
                SOL
              </div>
            </div>
            <div className='flex justify-between'>
              <div className='opacity-70'>Duration</div>
              <div>
                {durationDays}
                {' '}
                {durationDays === 1 ? 'day' : 'days'}
              </div>
            </div>
            <div className='flex justify-between'>
              <div className='flex items-center gap-[3px]'>
                <span className='opacity-70'>Daily Interest</span>
              </div>
              <div>
                {formatSol(averageDailyInterest.toNumber())}
                %
              </div>
            </div>
            <div className='flex justify-between'>
              <div className='flex items-center gap-[3px]'>
                <span className='opacity-70'>Interest Accrued</span>
                <Hint text='Total interest accrued, calculated daily at 00:00 UTC.'/>
              </div>
              <div>
                {formatSol(interestAccrued.toNumber())}
                {' '}
                SOL
              </div>
            </div>
            <div className='flex justify-between font-bold'>
              <div className='opacity-70'>Total Owing</div>
              <div>
                {formatSol(totalOwing)}
                {' '}
                SOL
              </div>
            </div>
          </section>

          <section className='bg-alt flex flex-col gap-[5px] rounded-lg bg-opacity-10 p-[10px] text-sm'>
            <section
              className={clsxm(
                'padding-y-[8.5px] flex w-full items-center',
                {
                  'justify-between': currentTab === CloseModalTabs.SELL,
                },
                {
                  'justify-center': currentTab === CloseModalTabs.REPAY,
                },
              )}
            >
              {currentTab === CloseModalTabs.SELL && <div className={clsxm('w-[75px]')}/>}
              <div className='flex grow items-center justify-center text-base'>
                <CloseModalTabControl currentTab={currentTab} disabled={isButtonDisabled} setCurrentTab={setCurrentTab}/>
              </div>
              {currentTab === CloseModalTabs.SELL && (
                <div className='text-main w-[75px]'>
                  <SlippageModal disabled={isButtonDisabled}/>
                </div>
              )}
            </section>
            <section className='my-5 flex justify-center text-center'>
              {currentTab === CloseModalTabs.SELL
                ? 'Selling the collateral covers the outstanding loan balance and fee, with the surplus returned to you.'
                : 'Repaying the outstanding loan balance releases the collateral to you.'}
            </section>
            <div className='mb-[10px] flex justify-center text-xs opacity-40'>TRANSACTION SUMMARY</div>

            {currentTab === CloseModalTabs.SELL ? (
              <section className='flex flex-col gap-[5px]'>
                <div className='flex justify-between'>
                  <div className='opacity-70'>Sale from Position</div>
                  <div>{`${formatSol(displayedSellFromPosition)} SOL`}</div>
                </div>
                <div className='flex justify-between'>
                  <div className='opacity-70'>Loan Balance</div>
                  <div>
                    {formatSol(-totalOwing)}
                    {' '}
                    SOL
                  </div>
                </div>
                <LabelledValue
                  hintInternalComponent={
                    <div className='text-left'>
                      <Conditional if={hasLavaRockNFT}>
                        <span>Fee reduced for </span>
                        <span><a className='underline' href='https://lavarage.gitbook.io/lavarage/community/lava-rock-alpha' rel='noreferrer' target='_blank'>Lava Rock</a></span>
                        <span> holder!</span>
                        <p className='mb-5'/>
                      </Conditional>
                      <Conditional if={!hasLavaRockNFT}>
                        <span>Own a </span>
                        <span><a className='underline' href='https://lavarage.gitbook.io/lavarage/community/lava-rock-alpha' rel='noreferrer' target='_blank'>Lava Rock</a></span>
                        <span> to reduce your fees!</span>
                        <p className='mb-5'/>
                      </Conditional>
                      <span>More details on </span>
                      <span><a className='underline' href='https://lavarage.gitbook.io/lavarage/platform/fee' rel='noreferrer' target='_blank'>fees</a></span>
                    </div>
                  }
                  label='Fee'
                  value={`${formatSol(-displayedFee)} SOL`}
                />
                <div className='flex justify-between'>
                  <div className='flex'>
                    <span className='text-alt my-auto mr-[3px] font-bold opacity-70'>Transferred to Wallet</span>
                    <Hint text='Actual amount may differ due to slippage and network fees.'/>
                  </div>
                  <div className='text-alt font-bold'>
                    {`${formatSol(displayedTotal)} SOL`}
                  </div>
                </div>
              </section>
            ) : (
              <section className='flex flex-col gap-[5px]'>
                <div className='text-alt flex justify-between font-bold'>
                  <span className='my-auto mr-[3px] opacity-70'>Withdrawn from Wallet</span>
                  <div>
                    {formatSol(-totalOwing)}
                    {' '}
                    SOL
                  </div>
                </div>
                <div className='text-alt flex justify-between'>
                  <div className='flex'>
                    <span className='my-auto mr-[3px] font-bold opacity-70'>Transferred to Wallet</span>
                  </div>
                  <div className='font-bold'>
                    {formatSol(position.collateralSize.toNumber())}
                    {' '}
                    {token?.symbol.toLocaleUpperCase()}
                  </div>
                </div>
              </section>
            )}
          </section>
          <section>
            <div className='flex flex-row justify-center gap-[6px]'>
              <Button color='main' disabled={isButtonDisabled} size='s' onClick={onExit}>Cancel</Button>
              <CloseModalButton closeModalButtonMode={closeModalButtonMode} repay={repaySol} sell={sellPosition}/>
            </div>
          </section>
        </div>
      </div>
    </Modal>
  )
})
