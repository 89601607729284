import { formatTimestamp } from '@/utils/formatters'

export function timeSince(timestamp: number) {
  const currentTime = new Date().getTime()
  const seconds = Math.floor((currentTime - timestamp * 1000) / 1000)

  let interval = seconds / 86400
  if (Math.floor(interval) > 30) {
    return formatTimestamp(timestamp)
  }
  else if (Math.floor(interval) > 1) {
    return `${Math.floor(interval)} days ago`
  }
  else if (interval > 1) {
    return '1 day ago'
  }

  interval = seconds / 3600
  if (Math.floor(interval) > 1) {
    return `${Math.floor(interval)} hours ago`
  }
  else if (interval > 1) {
    return '1 hour ago'
  }

  interval = seconds / 60
  if (Math.floor(interval) > 1) {
    return `${Math.floor(interval)} minutes ago`
  }
  else if (interval > 1) {
    return '1 minute ago'
  }

  return Math.floor(seconds) > 1 ? `${Math.floor(seconds)} seconds ago` : '1 second ago'
}
