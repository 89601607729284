import { Conditional } from '@/app/components'
import { memo, useEffect, useState } from 'react'

type TokenImageProps = {
  altName: string
  logoURI: string | null
  tag?: 'NEW' | 'HOT' | null
}

export const TokenImage = memo(({ altName, logoURI, tag = null }: TokenImageProps) => {
  const [imgError, setImgError] = useState(false)
  const [imgAltError, setImgAltError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isAltLoading, setIsAltLoading] = useState(true)

  useEffect(() => {
    setImgError(false)
  }, [logoURI])

  const altLogoImgURI = logoURI ? getAltLogoURI(logoURI) : ''

  function getAltLogoURI(uri: string): string {
    // The function tries to replace the reference with one obtained from https://docs.birdeye.so/reference/get_defi-token-creation-info
    if (uri.startsWith('https://baf') || uri.startsWith('https://cf-ipfs.com/ipfs/')) {
      const correctPrefix = 'https://img.fotofolio.xyz/?url='
      const encodedUri = encodeURIComponent(uri)
      return correctPrefix + encodedUri
    }
    if (uri.startsWith('https://image-cdn.solana.fm/images/?imageUrl=https://cf-ipfs.com/ipfs/')) {
      const correctPrefix = 'https://img.fotofolio.xyz/?w=30&amp;h=30&amp;url='
      const identifier = uri.replace('https://image-cdn.solana.fm/images/?imageUrl=https://cf-ipfs.com/ipfs/', 'https://pump.mypinata.cloud/ipfs/')
      const encodedIdentifier = encodeURIComponent(identifier)
      return correctPrefix + encodedIdentifier
    }
    if (uri.startsWith('https://nftstorage.link/ipfs/')) {
      const correctPrefix = 'https://img.fotofolio.xyz/?url=https%3A%2F%2Fnftstorage.link%2Fipfs%2F'
      const identifier = uri.replace('https://nftstorage.link/ipfs/', '').replace('/', '%2F')
      return correctPrefix + identifier
    }
    if (uri.includes('ipfs.nftstorage.link')) {
      const correctPrefix = 'https://img.fotofolio.xyz/?url=https://cf-ipfs.com/ipfs/'
      const identifier = encodeURIComponent(uri.replace('https://', '').replace('.ipfs.nftstorage.link', ''))
      return correctPrefix + identifier
    }
    return uri
  }
  // TODO remove this when the token`s link will fixed by Jupiter
  // Replace an incorrect image URI with the correct one for Lilpump token
  const isLilpumpToken = logoURI === 'https://bafkreien64lco2u3jwti6oeuhua7jf3acwo3fnbhau2m6fpz4hdt3ggkgi.ipfs.nftstorage.link'
  const correctLogoURI = isLilpumpToken
    ? 'https://img.fotofolio.xyz/?url=https%3A%2F%2Fcf-ipfs.com%2Fipfs%2FQmZgQ76Ckgxe4FQvxmXPC9dfdvEkZ5JX498m9yRb1wAERg'
    : logoURI

  return (
    <div className='relative'>
      <Conditional if={tag}>
        <div
          className={`${tag === 'NEW' ? 'bg-main' : 'bg-alt'} absolute right-[7px] top-[-15px] flex h-[16px] w-[34px] items-center justify-center rounded-full`}
        >
          <span className={`${tag === 'NEW' ? 'text-background' : 'text-main'} text-[10px]`}>{tag}</span>
        </div>
      </Conditional>
      <Conditional if={logoURI && !imgError}>
        <div className={`min-w-[30px] items-center justify-center rounded-full text-transparent ${isLoading ? 'hidden' : 'block'}`}>
          <img
            alt={altName}
            className='size-[30px] rounded-full object-cover object-center'
            draggable='false'
            height={30}
            src={correctLogoURI ?? undefined}
            width={30}
            onError={() => setImgError(true)}
            onLoad={() => setIsLoading(false)}
          />
        </div>
        <div className={`bg-main flex size-[30px] items-center justify-center rounded-full bg-opacity-5 p-1 ${!isLoading ? 'hidden' : 'block'}`}/>
      </Conditional>
      <Conditional if={logoURI && altLogoImgURI && imgError && !imgAltError}>
        <div className={`min-w-[30px] items-center justify-center rounded-full text-transparent ${isAltLoading ? 'hidden' : 'block'}`}>
          <img
            alt={altName}
            className='size-[30px] rounded-full object-cover object-center'
            draggable='false'
            height={30}
            src={altLogoImgURI}
            width={30}
            onError={() => setImgAltError(true)}
            onLoad={() => setIsAltLoading(false)}
          />
        </div>
        <div className={`bg-main flex size-[30px] items-center justify-center rounded-full bg-opacity-5 p-1 ${!isAltLoading ? 'hidden' : 'block'}`}/>
      </Conditional>
      <Conditional if={!logoURI || (imgError && imgAltError)}>
        <div className='bg-main flex size-[30px] items-center justify-center rounded-full bg-opacity-5 p-1'/>
      </Conditional>
    </div>
  )
})
