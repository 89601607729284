import { NEW_COIN_ADDRESSES_ARRAY } from '@/app/app.config'
import { useLavarage } from '@/app/providers/LavarageProvider'
import { type TokenInfo } from '@/services/types'
import { useQuery } from '@tanstack/react-query'

export const useTopCoinsQuery = (tokens: TokenInfo[]) => {
  const lavarage = useLavarage()
  const fetchTopCoins = async () => {
    if (!tokens.length) return []

    const trendingCoins = await lavarage.getTrendingCoins()
    const newCoinAddresses = NEW_COIN_ADDRESSES_ARRAY

    const trendingCoinsWithoutNewCoins = trendingCoins.filter(coin => !newCoinAddresses.includes(coin[0]))
    const topTenTrendingCoins = trendingCoinsWithoutNewCoins.slice(0, 10)

    topTenTrendingCoins.forEach(coin => {
      const token = tokens.find(token => token.address === coin[0])

      if (token) {
        token.isTopTrendingCoins = true
      }
    })

    const newCoins = tokens.filter(coin => newCoinAddresses.includes(coin.address))

    newCoins.forEach(coin => {
      coin.isNewCoin = true
    })

    return Array.from(new Set([...tokens.filter(coin => coin.isNewCoin), ...tokens.filter(coin => coin.isTopTrendingCoins)]))
  }

  const {
    data: topCoins,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['topCoins', tokens.length, `${tokens[0]?.address} ${tokens[tokens.length - 1]?.address}`],
    queryFn: fetchTopCoins,
    initialData: [],
    enabled: !!lavarage,
  })

  return {
    topCoins,
    isLoading,
    isError,
    refetch,
  }
}
