import { useEffect, type DependencyList, type RefObject } from 'react'

type Options = {
  ref: RefObject<HTMLElement>
  isActive?: boolean
}

export function useClickOutsideEffect(onClickOutside: () => void, { ref, isActive = true }: Options, deps: DependencyList = []) {
  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (ref.current?.contains(event.target as Node) === false) onClickOutside()
    }

    if (isActive) {
      document.addEventListener('mousedown', handler)
    }

    return () => {
      document.removeEventListener('mousedown', handler)
    }
  }, [...deps, ref, isActive])
}
