import { formatPercentWithoutPlus } from '@/utils/formatters'
import clsx from 'clsx'
import { type CSSProperties, type ComponentPropsWithoutRef } from 'react'

type ProgressComponentProps = {
  delegatedValue: number
  deployedValue: number
}

interface ExtendedCSSProperties extends CSSProperties {
  '--value': string
  '--size': string
  '--thickness': string
}

interface ExtendedProgressBarProps extends ComponentPropsWithoutRef<'div'> {
  style: ExtendedCSSProperties
}

const ProgressBar = (props: ExtendedProgressBarProps) => {
  const { style, className, ...rest } = props

  return (
    <div className={clsx('radial-progress absolute bottom-0 left-0 right-0 top-0 mx-auto my-auto', className)} role='progressbar' style={style} {...rest}>
      {props.children}
    </div>
  )
}

export const ProgressComponent: React.FC<ProgressComponentProps> = ({ delegatedValue, deployedValue }: ProgressComponentProps) => {
  return (
    <div className='relative h-[130px] w-[130px]'>
      <ProgressBar
        className='opacity-5'
        style={{
          '--value': '100',
          '--size': '130px',
          '--thickness': '20px',
        }}
      />
      <ProgressBar
        className={delegatedValue ? 'text-alt' : 'text-transparent'}
        style={{
          '--value': `${delegatedValue + deployedValue}`,
          '--size': '130px',
          '--thickness': '20px',
        }}
      />
      <ProgressBar
        className={delegatedValue ? 'text-purple' : 'text-transparent'}
        style={{
          '--value': `${delegatedValue || 0}`,
          '--size': '130px',
          '--thickness': '20px',
        }}
      >
        <ul className='flex flex-col gap-[5px] text-xs'>
          <li className='flex items-center gap-[5px]'>
            <div className='h-[14px] w-[14px] rounded-full bg-purple'/>
            <span className='text-main'>{formatPercentWithoutPlus(delegatedValue)}</span>
          </li>
          <li className='flex items-center gap-[5px]'>
            <div className='h-[14px] w-[14px] rounded-full bg-alt'/>
            <span className='text-main'>{formatPercentWithoutPlus(deployedValue)}</span>
          </li>
        </ul>
      </ProgressBar>
    </div>
  )
}
