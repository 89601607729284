import { Button } from '@/app/components'
import { useCallback } from 'react'

export type ClaimButtonMode = 'no-connection' | 'confirming' | 'finalizing' | 'restricted' | 'claim' | 'timeLeft'

type ClaimButtonProps = {
  claimButtonMode: ClaimButtonMode
  timeLeft: string
  onClaim: () => void
}

function getLabelByMode(mode: ClaimButtonMode, timeLeft: string) {
  switch (mode) {
    case 'no-connection':
      return 'No Connection'
    case 'confirming':
      return 'Confirming...'
    case 'finalizing':
      return 'Finalizing...'
    case 'restricted':
      return 'Restricted'
    case 'claim':
      return 'Claim'
    case 'timeLeft':
      return timeLeft
    default:
      return 'Restricted'
  }
}

function getColorByMode(mode: ClaimButtonMode) {
  switch (mode) {
    case 'no-connection':
    case 'confirming':
    case 'finalizing':
    case 'restricted':
    case 'timeLeft':
      return 'main'
    case 'claim':
      return 'alt'
    default:
      return 'main'
  }
}

function isDisabledByMode(mode: ClaimButtonMode) {
  return mode === 'no-connection' || mode === 'restricted' || mode === 'confirming' || mode === 'timeLeft' || mode === 'finalizing'
}

export const ClaimButton = ({ claimButtonMode, timeLeft, onClaim }: ClaimButtonProps) => {
  const handleClick = useCallback(async () => {
    if (claimButtonMode === 'claim') {
      await onClaim()
    }
  }, [claimButtonMode, onClaim])

  return (
    <Button color={getColorByMode(claimButtonMode)} disabled={isDisabledByMode(claimButtonMode)} size='s' onClick={handleClick}>
      {getLabelByMode(claimButtonMode, timeLeft)}
    </Button>
  )
}
