import { CRYPTO_DECIMAL_POINTS } from '@/config'
import { formatCurrency, stringToNumber } from '@/utils'
import { type PriceResponse } from '@lavarage/entities'

class VaultUtils {
  static calculateReceiveAmount(mode: 'stake' | 'unstake', input: string, nav: number) {
    if (input === '') return ''
    const inputAmount = stringToNumber(input)
    if (mode === 'stake') {
      return (inputAmount / nav).toFixed(CRYPTO_DECIMAL_POINTS)
    }
    else {
      return (inputAmount * nav).toFixed(CRYPTO_DECIMAL_POINTS)
    }
  }

  static calculateExchangeRate(mode: 'stake' | 'unstake', nav: number) {
    if (mode === 'stake') {
      return (1 / nav).toFixed(CRYPTO_DECIMAL_POINTS)
    }
    else {
      return (1 * nav).toFixed(CRYPTO_DECIMAL_POINTS)
    }
  }

  static calculatePnlPercentage(total: number, pnl: number) {
    return ((pnl / total) * 100).toFixed(5)
  }

  static calculateApy(total: number, pnl: number) {
    return ((((pnl / total) * 1000000000) / 86400) * 31536000 * 100).toFixed(2)
  }

  static localFormatCurrency(val: number, toUSDC: PriceResponse['data'], isUsdModeOn: boolean, decimalPoints: number) {
    return formatCurrency(val, toUSDC, isUsdModeOn, decimalPoints)
  }
}

export { VaultUtils }
