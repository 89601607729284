import * as React from "react";
const SvgIconLavarage = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 1024 1024", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", "xmlns:serif": "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(2.83585,0,0,2.83585,-1.47881e-06,43.2793)" }, /* @__PURE__ */ React.createElement("path", { d: "M144.458,13.919C162.379,13.919 178.763,20.096 191.653,30.26C201.991,38.481 210.111,49.442 214.736,61.912C214.751,61.955 214.765,62.013 214.765,62.057C214.765,62.1 214.765,62.144 214.78,62.173L217.418,70.901L225.958,99.059L253.405,191.216L270.065,246.559C273.008,253.562 277.068,260.015 281.969,265.785C283.085,267.163 284.347,268.526 285.608,269.773C293.583,277.457 304.515,282.14 316.55,282.14C334.761,282.14 350.304,271.57 357.162,256.317C357.336,255.926 357.597,255.679 357.887,255.491C357.843,255.52 357.8,255.52 357.756,255.549C354.523,257.477 347.505,265.626 331.513,265.93C319.652,266.162 312.562,260.972 307.241,255.839C305.98,254.606 304.718,253.229 303.602,251.852C303.558,251.794 303.5,251.736 303.457,251.678C297.106,244.167 291.306,232.249 288.494,222.897L275.038,177.268L247.591,85.11L239.051,56.953L236.412,48.224C236.412,48.224 236.398,48.137 236.398,48.108C236.398,48.065 236.398,48.007 236.369,47.963C231.744,35.494 223.639,24.533 213.286,16.312C200.411,6.177 184.027,0 166.091,0C137.368,0 114.59,8.149 88.071,39.119C101.816,23.721 121.941,13.919 144.458,13.919Z", style: {
  fill: "url(#_Linear1)",
  fillRule: "nonzero"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(2.83585,0,0,2.83585,-1.47881e-06,43.2793)" }, /* @__PURE__ */ React.createElement("path", { d: "M217.563,70.858L214.924,62.129C214.924,62.129 214.91,62.042 214.91,62.013C214.91,62.013 214.91,61.912 214.881,61.868C210.256,49.399 202.15,38.438 191.798,30.217C178.923,20.052 162.539,13.876 144.603,13.876C115.75,13.876 90.825,29.941 78.226,53.299C72.759,63.463 69.831,75.005 69.831,87.227L69.831,87.358C69.831,89.417 72.774,90.098 73.615,88.184C80.473,72.946 96.016,62.361 114.227,62.361C126.276,62.361 137.194,67.03 145.169,74.715C146.43,75.947 147.691,77.324 148.808,78.702C153.984,84.748 158.189,91.606 161.132,99.03C162.8,103.133 177.11,152.851 177.241,153.271L177.241,153.3L204.963,245.298L213.358,273.586L215.997,282.314C215.997,282.314 216.012,282.401 216.012,282.43C216.012,282.43 216.012,282.532 216.041,282.575C220.666,295.045 228.771,306.006 239.123,314.227C251.999,324.522 268.383,330.568 286.318,330.568C315.172,330.568 340.096,314.503 352.696,291.144C358.162,280.981 361.091,269.439 361.091,257.216L361.091,257.086C361.091,255.027 358.148,254.345 357.307,256.259C350.449,271.498 334.905,282.082 316.694,282.082C304.645,282.082 293.728,277.414 285.753,269.729C284.492,268.497 283.23,267.119 282.114,265.742C277.213,259.971 273.153,253.519 270.21,246.516L253.55,191.172L226.103,99.015L217.563,70.858Z", style: {
  fill: "url(#_Linear2)",
  fillRule: "nonzero"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(2.83585,0,0,2.83585,-1.47881e-06,43.2793)" }, /* @__PURE__ */ React.createElement("path", { d: "M107.818,162.667C108.094,154.199 107.818,148.197 103.875,141.773C103.585,141.309 103.28,140.846 102.932,140.425C100.873,137.83 97.64,134.988 94.566,133.842C86.664,130.87 80.589,132.378 74.47,137.743C73.252,138.801 72.223,140.063 71.396,141.454C68.323,146.602 64.147,155.055 63.074,157.172L2.511,275.833C-4.913,290.391 5.019,308.094 20.606,308.094L89.39,308.094C100.902,308.094 110.182,298.061 109.964,285.838L107.804,162.667L107.818,162.667Z", style: {
  fill: "url(#_Linear3)",
  fillRule: "nonzero"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(2.83585,0,0,2.83585,-1.47881e-06,43.2793)" }, /* @__PURE__ */ React.createElement("path", { d: "M18.981,308.094L20.591,308.094C19.851,308.094 19.112,308.036 18.401,307.964C18.59,308.05 18.778,308.094 18.981,308.094Z", style: {
  fill: "url(#_Linear4)",
  fillRule: "nonzero"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(2.83585,0,0,2.83585,-1.47881e-06,43.2793)" }, /* @__PURE__ */ React.createElement("path", { d: "M173.093,303.396L133.873,155.417C131.147,141.73 122.23,130.798 110.616,127.318C96.306,123.012 79.37,127.564 71.758,140.904C72.527,139.744 73.397,138.656 74.455,137.743C80.588,132.378 86.649,130.87 94.551,133.842C97.625,135.002 100.858,137.844 102.917,140.425C103.265,140.86 103.57,141.31 103.86,141.774C107.803,148.182 108.079,154.199 107.803,162.667L109.964,285.838C110.181,298.061 100.902,308.094 89.389,308.094L169.759,308.094C172.644,308.094 173.731,305.76 173.108,303.396L173.093,303.396Z", style: {
  fill: "url(#_Linear5)",
  fillRule: "nonzero"
} })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("linearGradient", { id: "_Linear1", x1: 0, y1: 0, x2: 1, y2: 0, gradientUnits: "userSpaceOnUse", gradientTransform: "matrix(520.652,0,0,520.652,115.866,141.077)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "rgb(255,220,112)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.15, style: {
  stopColor: "rgb(255,220,112)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.38, style: {
  stopColor: "rgb(244,131,95)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.59, style: {
  stopColor: "rgb(238,71,69)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.78, style: {
  stopColor: "rgb(237,38,69)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "rgb(237,38,69)",
  stopOpacity: 1
} })), /* @__PURE__ */ React.createElement("linearGradient", { id: "_Linear2", x1: 0, y1: 0, x2: 1, y2: 0, gradientUnits: "userSpaceOnUse", gradientTransform: "matrix(291.26,0,0,291.26,69.8306,172.207)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "rgb(255,220,112)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.15, style: {
  stopColor: "rgb(255,220,112)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.38, style: {
  stopColor: "rgb(244,131,95)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.59, style: {
  stopColor: "rgb(238,71,69)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.78, style: {
  stopColor: "rgb(237,38,69)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "rgb(237,38,69)",
  stopOpacity: 1
} })), /* @__PURE__ */ React.createElement("linearGradient", { id: "_Linear3", x1: 0, y1: 0, x2: 1, y2: 0, gradientUnits: "userSpaceOnUse", gradientTransform: "matrix(207.672,76.121,-76.121,207.672,-17.6434,194.029)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "rgb(255,220,112)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.15, style: {
  stopColor: "rgb(255,220,112)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.38, style: {
  stopColor: "rgb(244,131,95)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.59, style: {
  stopColor: "rgb(238,71,69)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.78, style: {
  stopColor: "rgb(237,38,69)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "rgb(237,38,69)",
  stopOpacity: 1
} })), /* @__PURE__ */ React.createElement("linearGradient", { id: "_Linear4", x1: 0, y1: 0, x2: 1, y2: 0, gradientUnits: "userSpaceOnUse", gradientTransform: "matrix(2.2619,0,0,2.2619,18.0679,308.022)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "rgb(255,220,112)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.15, style: {
  stopColor: "rgb(255,220,112)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.38, style: {
  stopColor: "rgb(244,131,95)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.59, style: {
  stopColor: "rgb(238,71,69)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.74, style: {
  stopColor: "rgb(237,38,69)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.93, style: {
  stopColor: "rgb(193,31,65)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "rgb(193,31,65)",
  stopOpacity: 1
} })), /* @__PURE__ */ React.createElement("linearGradient", { id: "_Linear5", x1: 0, y1: 0, x2: 1, y2: 0, gradientUnits: "userSpaceOnUse", gradientTransform: "matrix(143.789,1.899,-1.899,143.789,11.398,215.821)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "rgb(255,220,112)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.15, style: {
  stopColor: "rgb(255,220,112)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.38, style: {
  stopColor: "rgb(244,131,95)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.59, style: {
  stopColor: "rgb(238,71,69)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.74, style: {
  stopColor: "rgb(237,38,69)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.93, style: {
  stopColor: "rgb(193,31,65)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "rgb(193,31,65)",
  stopOpacity: 1
} }))));
export default SvgIconLavarage;
