import { clsxm } from '@/app/helpers/clsxm'
import type { HTMLAttributes, PropsWithChildren } from 'react'

type Props = HTMLAttributes<HTMLButtonElement> & PropsWithChildren<{
  color: 'main' | 'alt' | 'solid' | 'gradient' | 'recalled' | 'liquidated' | 'active'
  disabled?: boolean
  size?: 's' | 'm' | 'adaptive'
}>

export function Button({
  children,
  className,
  size = 'adaptive',
  color,
  disabled = false,
  ...props
}: Props) {
  return (
    <button
      {...props}
      className={clsxm(
        className,
        'my-auto rounded-lg transition',
        {
          'h-[36px] px-[10px] text-sm': size === 's',
          'h-[44px] px-[20px] text-base': size === 'm',
          'h-[36px] px-[10px] text-sm desk:h-[44px] desk:px-[20px] desk:text-base': size === 'adaptive',
          'desk:hover:bg-opacity-10': color === 'main',
          'desk:hover:bg-opacity-70': color === 'solid',
          'desk:hover:bg-opacity-20': color === 'alt',
          'bg-main bg-opacity-5 text-main': color === 'main',
          'bg-main bg-opacity-10 text-main desk:hover:bg-opacity-5': color === 'active',
          'bg-alt bg-opacity-10 text-alt': color === 'alt',
          'bg-yellow bg-opacity-[0.28] text-yellow desk:hover:bg-opacity-[0.14]': color === 'recalled',
          'bg-purple bg-opacity-[0.32] text-purple desk:hover:bg-opacity-[0.16]': color === 'liquidated',
          'bg-main text-background': color === 'solid',
          'gradient-button': color === 'gradient',
          'pointer-events-none': disabled,
        },
      )}
    >
      <span
        className={clsxm('transition', {
          'opacity-40': disabled && color !== 'main',
          'desk:hover:opacity-100': color === 'gradient',
        })}
      >
        {children}
      </span>
    </button>
  )
}
