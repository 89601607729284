import { Button } from '@/app/components'
import { useCallback } from 'react'

export type ActionStakeButtonMode = 'connect-wallet' | 'no-connection' | 'confirming' | 'restricted' | 'enter-amount' | 'insufficient-funds' | 'stake' | 'unstake'

type ActionStakeButtonProps = {
  actionButtonMode: ActionStakeButtonMode
  connect: () => void
  stake: () => Promise<void>
  unstake: () => Promise<void>
}

function getLabelByMode(mode: ActionStakeButtonMode) {
  switch (mode) {
    case 'connect-wallet':
      return 'Connect Wallet'
    case 'no-connection':
      return 'No Connection'
    case 'confirming':
      return 'Confirming...'
    case 'restricted':
      return 'Restricted'
    case 'enter-amount':
      return 'Enter Amount'
    case 'insufficient-funds':
      return 'Insufficient Funds'
    case 'stake':
      return 'Stake'
    case 'unstake':
      return 'Unstake'
    default:
      return 'Restricted'
  }
}

function getColorByMode(mode: ActionStakeButtonMode) {
  switch (mode) {
    case 'connect-wallet':
      return 'solid'
    case 'no-connection':
    case 'confirming':
    case 'restricted':
    case 'enter-amount':
    case 'insufficient-funds':
      return 'main'
    case 'stake':
    case 'unstake':
      return 'gradient'
    default:
      return 'main'
  }
}

function isDisabledByMode(mode: ActionStakeButtonMode) {
  return mode === 'no-connection' || mode === 'restricted' || mode === 'enter-amount' || mode === 'insufficient-funds'
}

export const ActionStakeButton = ({ actionButtonMode, connect, stake, unstake }: ActionStakeButtonProps) => {
  const handleClick = useCallback(async () => {
    switch (actionButtonMode) {
      case 'stake':
        await stake()
        break
      case 'unstake':
        await unstake()
        break
      case 'connect-wallet':
        connect()
        break
      default:
        break
    }
  }, [actionButtonMode, stake, unstake])

  return (
    <Button className='w-[160px] !px-0' color={getColorByMode(actionButtonMode)} disabled={isDisabledByMode(actionButtonMode)} size='m' onClick={handleClick}>
      {getLabelByMode(actionButtonMode)}
    </Button>
  )
}
