import { NotificationService } from '@/services/NotificationService.js'
import { PhantomWalletName } from '@solana/wallet-adapter-phantom'
import { useWallet } from '@solana/wallet-adapter-react'
import { type PublicKey } from '@solana/web3.js'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState, type HTMLAttributes } from 'react'
import { useLocation } from 'react-router-dom'
import { IconLavarage, IconLogo } from '../../assets/svgs/index.js'
import { trackNavigationClickGA } from '../analytics.js'
import { FLASH_NOTIFICATION_MESSAGE } from '../app.config.js'
import { clsxm } from '../helpers/index.js'
import { Conditional } from './Conditional.js'
import { CustomWalletMultiButton } from './CustomWalletMultiButton.js'
import { FlashNotification } from './FlashNotification.js'
import { MainNav } from './MainNav.js'

type Props = HTMLAttributes<HTMLDivElement>

export const Header = ({ className, ...props }: Props) => {
  const location = useLocation()
  const [isScrolled, setIsScrolled] = useState(false)
  const [incorrectPublicKey, setIncorrectPublicKey] = useState(false)
  const [notificationVisible, setNotificationVisible] = useState(FLASH_NOTIFICATION_MESSAGE && localStorage.getItem('flash-notification') !== NotificationService.hashMessage(FLASH_NOTIFICATION_MESSAGE))
  const { disconnect, publicKey, connect, select } = useWallet()
  const queryClient = useQueryClient()
  const searchParams = new URLSearchParams(location.search)
  const referralCode = searchParams.get('ref') ?? searchParams.get('referrer')

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      setIsScrolled(scrollPosition > 0)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const getProvider = () => {
    if ('phantom' in window) {
      const provider = window.phantom?.solana

      if (provider?.isPhantom) {
        return provider
      }
    }
    return null
  }

  const provider = getProvider()

  if (publicKey && provider) {
    provider.on('accountChanged', (newPublicKey: PublicKey) => {
      if (newPublicKey && publicKey && newPublicKey.toBase58() !== publicKey.toBase58()) {
        setIncorrectPublicKey(true)
        disconnect()
        queryClient.invalidateQueries({ queryKey: ['positions'], exact: true })
      }
    })
  }
  useEffect(() => {
    if (incorrectPublicKey && !publicKey) {
      select(PhantomWalletName)
      connect()
      setIncorrectPublicKey(false)
    }
  }, [incorrectPublicKey, publicKey, connect, select])

  return (
    <>
      <Conditional if={notificationVisible}>
        <FlashNotification dismissible message={NotificationService.parseMessage(FLASH_NOTIFICATION_MESSAGE || '')} setVisibility={setNotificationVisible}/>
      </Conditional>

      <header
        {...props}
        className={clsxm(className, 'pt-safe-or-1 desk:pt-safe-or-4 px-safe-or-[10px] desk:px-safe-or-[30px] desk:pb-4 flex w-full justify-between border-b border-b-transparent bg-transparent pb-1 backdrop-blur-0 transition-colors', {
          'backing': isScrolled,
          'border-vibrant': isScrolled,
          'bg-vibrant': isScrolled,
        })}
        role='navigation'
      >
        <div className='desk:gap-5 flex items-center gap-4'>
          <a
            className='btn-icon flex items-center gap-[10px]'
            href={referralCode ? `https://lavarage.xyz/?ref=${referralCode}` : 'https://lavarage.xyz/'}
            target='_blank'
            onClick={() => {
              trackNavigationClickGA('Logo')
            }}
          >
            <div className='desk:hidden block'>
              <IconLavarage width={23}/>
            </div>
            <div className='desk:block hidden'>
              <IconLogo/>
            </div>
          </a>
          <MainNav className='desk:absolute desk:left-1/2 desk:top-1/2 desk:-translate-x-1/2 desk:-translate-y-1/2'/>
        </div>
        <CustomWalletMultiButton/>
      </header>
    </>
  )
}
