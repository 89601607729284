import axios, { AxiosError } from 'axios'
import { API_HOST } from '../app/app.config.js'
import { BaseService } from './BaseService'

export class ReferralService extends BaseService {
  private referralCodeGenerated = false
  private refereeTmpRegisteredWithCode: string | undefined = undefined
  private isRefereeFinalized = false

  async generateReferralCode(wallet: string) {
    try {
      if (this.referralCodeGenerated) {
        return
      }

      this.referralCodeGenerated = true
      axios.post(`${API_HOST}/referral-code`, { wallet })
    }
    catch (error) {
      if (error instanceof AxiosError && error.status === 400) {
        return
      }
    }

    this.referralCodeGenerated = true
    this.http.post(`${API_HOST}/referral-code`, { wallet })
  }

  async registerReferee(wallet: string, referralCode: string | undefined, isTrade = false) {
    if (this.refereeTmpRegisteredWithCode === referralCode && !isTrade) {
      return
    }
    if (this.isRefereeFinalized) {
      return
    }

    if (!isTrade) {
      this.refereeTmpRegisteredWithCode = referralCode
    }
    else {
      this.isRefereeFinalized = isTrade
    }
    if (referralCode) {
      this.http.put(`${API_HOST}/referral-code?code=${referralCode}`, {
        referee: {
          add: wallet,
        },
        isTrade,
      })
    }
    else {
      this.http.put(`${API_HOST}/referral-code`, {
        referee: {
          add: wallet,
        },
        isTrade,
      })
    }
  }
}

export const referralService = new ReferralService()
