import { useLavarage } from '@/app/providers/LavarageProvider'
import { useQuery, useQueryClient } from '@tanstack/react-query'

export const usePoolsQuery = () => {
  const lavarage = useLavarage()
  const queryClient = useQueryClient()

  const {
    data: pools,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ['pools'],
    queryFn: async () => await lavarage.getPools(),
    enabled: !!lavarage,
    initialData: [],
  })

  const refetchWithoutCache = () => queryClient.fetchQuery({
    queryKey: ['pools'],
    queryFn: async () => await lavarage.getPools(true),
  })

  return { pools, isLoading, error, refetch, refetchWithoutCache }
}
