import { clsxm } from '@/app/helpers/clsxm'
import { IconUsdSwitch } from '@/assets'

type Props = {
  onClick: () => void
  isActive: boolean
  className?: string
}

export const UsdSwitch = ({ onClick, isActive = false, className }: Props) => {
  return (
    <button
      className={clsxm(
        'desk:hover:opacity-[0.7] flex cursor-pointer items-center justify-center gap-[5px] bg-opacity-10 transition',
        { 'opacity-[0.5]': !isActive },
        className,
      )}
      onClick={onClick}
    >
      <IconUsdSwitch/>
      <span className='text-sm'>USD</span>
    </button>
  )
}
