import { Conditional, LSpan, NumericTokenInput, SlippageModal, TokenImage, UsdSwitch } from '@/app/components'
import { clsxm } from '@/app/helpers/clsxm'
import { usePoolsQuery } from '@/app/hooks/queries'
import { useBalance } from '@/app/hooks/useBalance'
import { useNetworkStatus } from '@/app/hooks/useNetworkStatus'
import { Layout } from '@/app/pages/layout/Layout'
import { useBorrow } from '@/app/pages/main/hooks/useBorrow'
import { useSwap } from '@/app/pages/main/hooks/useSwap'
import { getActionMainButtonMode } from '@/app/pages/main/utils/getActionMainButtonMode'
import { useAlertsStore, usePreferencesStore } from '@/app/stores'
import { CRYPTO_DECIMAL_POINTS, FIAT_DECIMAL_POINTS, JUPITER_PLATFORM_FEE_BPS, MIN_LEVERAGE, MIN_PLATFORM_FEE_IN_SOL, PLATFORM_FEE, SOL_ADDRESS, SOL_LOGO_URI } from '@/config'
import { coreService } from '@/services/CoreService'
import { getRelatedPool, stringToNumber } from '@/utils'
import { formatCurrency, formatSol } from '@/utils/formatters'
import BigNumber from 'bignumber.js'
import { useCallback, useEffect, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { type TokenInfo } from '../../../services/types.js'
import { Hint } from '../../components/Hint.js'
import { LabelledValue } from '../../components/LabelledValue.js'
import { useNftAccess } from '../../hooks/useNftAccess.js'
import { usePriceContext } from '../../providers/PriceProvider.js'
import { ActionMainButton, LeverageField, LoanSummary, TokenList } from './components'

type TradeDetailsGA = {
  leverage: number
  quoteAmount: number
  baseAmount: number
  token: string
  fee: number
  total: number
  positionSize: number
  dailyInterest: number
  entryPrice: number
  liquidationPrice: number
}

export function MainPage() {
  const [leverage, defaultLeverage, baseToken, slippage, solInput, setSolInput, isUsdModeOn, setIsUsdModeOn] = usePreferencesStore(useShallow(s => [
    s.leverage,
    s.defaultLeverage,
    s.baseToken,
    s.slippage,
    s.solInput,
    s.setSolInput,
    s.isUsdModeOn.trading,
    () => s.setIsUsdModeOn({ ...s.isUsdModeOn, trading: !s.isUsdModeOn.trading }),
  ]))
  const { priceSol, priceUsdc, solPriceLoading, solPriceError, usdcPriceLoading, usdcPriceError, refresh } = usePriceContext()
  const [priceFreeze, setPriceFreeze] = useState(0)
  const { hasLavaRockNFT } = useNftAccess()
  const decimalPoints = isUsdModeOn ? FIAT_DECIMAL_POINTS : CRYPTO_DECIMAL_POINTS
  const [loading, confirming] = useAlertsStore(useShallow(state => [state.loading, state.confirming]))
  const { availableSol, connected, connecting } = useBalance()
  const currentLeverage = baseToken?.whitelisted ? (leverage ?? defaultLeverage ?? MIN_LEVERAGE.toString()) : MIN_LEVERAGE.toString()
  const isOnline = useNetworkStatus()
  const [maxLeverage, setMaxLeverage] = useState<number | null>(null)

  const isBaseTokenAmountLoading = solPriceLoading || usdcPriceLoading
  const openFee = coreService.openFee({ baseCurrency: stringToNumber(solInput) || 0, leverage: parseFloat(currentLeverage), fee: PLATFORM_FEE })
  const isMinFee = openFee < MIN_PLATFORM_FEE_IN_SOL
  const fees = isMinFee ? MIN_PLATFORM_FEE_IN_SOL : openFee
  const jupiterPlatformFee = hasLavaRockNFT ? 0 : (stringToNumber(solInput) || 0) * parseFloat(currentLeverage) * JUPITER_PLATFORM_FEE_BPS / 10_000
  const baseTokenAmount = isBaseTokenAmountLoading
    ? null
    : priceSol
      ? (priceSol[SOL_ADDRESS]?.price || 0) * (stringToNumber(solInput) * parseFloat(currentLeverage) - jupiterPlatformFee)
      : null

  const maxAvailableSol = availableSol ? availableSol - fees : 0
  const isLeverageEqualToOne = parseFloat(currentLeverage) === 1 || !baseToken?.whitelisted
  const displayedFee = isLeverageEqualToOne ? jupiterPlatformFee : fees
  const total = (stringToNumber(solInput) || 0) + displayedFee
  const minTokenInputValue = isLeverageEqualToOne ? 0 : 0.05

  const initialMarginForCalcSummary = stringToNumber(solInput)
  const baseTokenAmountForCalcSummary = priceFreeze > 0 ? priceFreeze : baseTokenAmount
  const leverageForCalcSummary = Math.max(parseFloat(currentLeverage), 1)

  const borrowedAmountForCalcSummary = BigNumber(coreService.borrowedAmount({ initialMargin: isNaN(initialMarginForCalcSummary) ? 0 : initialMarginForCalcSummary, leverage: leverageForCalcSummary }) || 0)
  // POSSIBLE REGRESSION! DO NOT CHANGE THIS BACK!!!!!!!!!
  const positionSize = borrowedAmountForCalcSummary.plus(initialMarginForCalcSummary).toNumber()
  const entryPrice = (initialMarginForCalcSummary * leverageForCalcSummary) / baseTokenAmountForCalcSummary
  const liquidationPrice = coreService.liquidationPrice({
    borrowedAmount: borrowedAmountForCalcSummary,
    interestAccrued: 0,
    collateralValue: baseTokenAmountForCalcSummary || 0,
    liquidationLTV: 90,
  })
  const tradeDetailsGA: TradeDetailsGA = {
    leverage: parseFloat(currentLeverage),
    quoteAmount: stringToNumber(solInput),
    baseAmount: baseTokenAmount,
    token: baseToken?.symbol,
    fee: fees,
    total,
    positionSize,
    dailyInterest: baseToken?.dailyInterest || 0,
    entryPrice,
    liquidationPrice,
  }
  const { borrow } = useBorrow({
    solAmount: stringToNumber(solInput),
    baseTokenAmount,
    toUSDC: priceUsdc,
    baseToken,
    total,
    slippage: parseFloat(slippage),
    leverage: parseFloat(currentLeverage),
    setPriceFreeze,
    tradeDetailsGA,
  })
  const { swap } = useSwap({
    from: SOL_ADDRESS,
    to: baseToken?.address,
    amount: stringToNumber(solInput),
    slippage: parseFloat(slippage),
    decimalsOfBaseToken: baseToken?.decimals,
    setPriceFreeze,
    tradeDetailsGA,
  })
  const { pools } = usePoolsQuery()

  useEffect(() => {
    if (!solPriceLoading && priceSol !== undefined) {
      const price = priceSol[SOL_ADDRESS]?.price

      const solPriceBN = BigNumber(price)
      const relatedPool = getRelatedPool({ address: baseToken?.address } as TokenInfo, pools)

      if (!baseToken?.whitelisted || !relatedPool) return

      const marketPriceOfBasedTokenInQuoteCurrency = BigNumber(1).dividedBy(solPriceBN)
      const maxBorrowLTV = BigNumber.min(relatedPool.maxBorrow.dividedBy(marketPriceOfBasedTokenInQuoteCurrency), BigNumber(0.875))
      // without -1 transaction fails to complete
      const maxLeverageValue = BigNumber(1).dividedBy(BigNumber(1).minus(maxBorrowLTV)).multipliedBy(10).minus(1).dividedBy(10)

      setMaxLeverage(maxLeverageValue.isGreaterThan(1) ? maxLeverageValue.decimalPlaces(1).toNumber() : null)
    }
  }, [solPriceLoading, priceSol, pools, baseToken])

  const isMaxLeverageLoading = maxLeverage === null

  const borrowedAmount = coreService.borrowedAmount({
    initialMargin: isNaN(stringToNumber(solInput)) ? 0 : stringToNumber(solInput) || 0,
    leverage: parseFloat(currentLeverage),
  })

  const availableLiquidity = baseToken?.availableLiquidity || -1

  const localFormatCurrency = useCallback(
    (val: number | null, decimalPointsProps = decimalPoints) => {
      if (val === null) return null
      if (val === undefined || isNaN(val) || !isFinite(val)) return '--'
      return formatCurrency(val, priceUsdc, isUsdModeOn, decimalPointsProps)
    },
    [priceUsdc, isUsdModeOn, decimalPoints],
  )
  const actionMainButtonMode = getActionMainButtonMode({
    isOnline,
    connected,
    confirming,
    solInput,
    maxAvailableSol,
    leverage: parseFloat(currentLeverage),
    borrowedAmount,
    availableLiquidity,
    loading,
  })
  const youReceiveWithoutFee = priceFreeze > 0 ? priceFreeze : baseTokenAmount || 0
  const displayedYouReceive = formatSol(youReceiveWithoutFee, baseToken?.decimals)

  return (
    <Layout>
      <div
        className={clsxm('flex min-h-[665px] w-full max-w-[460px] flex-col justify-center', {
          'pointer-events-none': priceFreeze,
        })}
      >
        <div className='mb-[10px] flex justify-end'>
          <Conditional if={!isLeverageEqualToOne}>
            <UsdSwitch className='mr-[10px]' isActive={isUsdModeOn} onClick={setIsUsdModeOn}/>
          </Conditional>
          <SlippageModal/>
        </div>
        <section className='bg-main/5 mb-[10px] flex h-[130px] flex-col flex-wrap rounded-lg p-[10px]'>
          <div className='flex justify-between text-xs'>
            <div className='flex items-center gap-[5px]'>
              <div className='text-main opacity-40'>YOU PAY</div>
              <Conditional if={!isLeverageEqualToOne}>
                <Hint text='This is the initial margin for this position (min: 0.05 SOL required)'/>
              </Conditional>
            </div>
            <div
              className='desk:hover:opacity-70 cursor-pointer transition'
              onClick={() => {
                if (availableSol && availableSol >= 0.000001) {
                  // Without 1_000_000 a very small number is displayed as zero
                  setSolInput((Math.floor(availableSol * 1_000_000) / 1_000_000).toString())
                }
              }}
            >
              <Conditional if={!connecting && connected}>
                <span className='opacity-40'>AVAILABLE FUNDS: </span>
                <LSpan text={formatSol(availableSol)} textSize={12} unit=' SOL'/>
              </Conditional>
            </div>
          </div>
          <div className='my-auto flex items-center justify-between'>
            <div className='flex min-w-[71px] items-center justify-start gap-[5px] rounded-lg py-[3px] pl-[8px]'>
              <TokenImage altName='SOL' logoURI={SOL_LOGO_URI} tag={null}/>
              <span className='text-sm'>SOL</span>
            </div>
            <div className='flex max-w-[250px] grow-0 items-center overflow-hidden'>
              <NumericTokenInput
                connected={connected}
                MAX_VALUE={maxAvailableSol}
                MIN_VALUE={minTokenInputValue}
                refresh={refresh}
                setValue={setSolInput}
                size='l'
                value={solInput}
              />
            </div>
          </div>
          <div className='ml-auto h-[15px] text-xs opacity-40'>
            <Conditional if={!solPriceError && !usdcPriceError && priceUsdc && priceUsdc[SOL_ADDRESS]}>
              <p className='max-w-[200px] overflow-hidden'>
                <LSpan
                  isUnitLeft
                  isLoading={solPriceLoading || usdcPriceLoading}
                  text={formatSol((priceUsdc?.[SOL_ADDRESS]?.price || 0) * (stringToNumber(solInput) || 0), FIAT_DECIMAL_POINTS)}
                  textSize={12}
                  unit='$'
                />
              </p>
            </Conditional>

            <Conditional if={!!solPriceError}>
              <span>Error has occurred</span>
            </Conditional>
          </div>
        </section>

        <LeverageField isLoading={isMaxLeverageLoading} maxLeverage={maxLeverage}/>

        <section
          className={clsxm(
            'my-[10px] flex h-[130px] flex-col justify-between overflow-hidden rounded-lg bg-opacity-10 p-[10px]',
            {
              'bg-alt': baseToken?.whitelisted,
            },
            {
              'bg-main': !baseToken?.whitelisted,
            },
          )}
        >
          <div className='flex justify-between text-xs'>
            <div className='flex items-center gap-[5px]'>
              <div className='opacity-40'>YOU RECEIVE</div>
              <Conditional if={!isLeverageEqualToOne}>
                <Hint position='bottom' text='This receivable amount may differ due to slippage.'/>
              </Conditional>
            </div>
            <Conditional if={baseToken?.whitelisted}>
              <div>
                <span className='opacity-40'>AVAILABLE LIQUIDITY: </span>
                <LSpan
                  isLoading={isMaxLeverageLoading || solPriceLoading || availableLiquidity === -1}
                  text={formatSol(availableLiquidity)}
                  textSize={12}
                  unit=' SOL'
                />
              </div>
            </Conditional>
          </div>
          <div className='flex gap-[10px]'>
            <div className='flex-none items-center'>
              <TokenList/>
            </div>
            <Conditional if={!solPriceError && priceSol && stringToNumber(solInput) >= 0 && leverage !== ''}>
              <div
                className={clsxm(
                  'flex flex-auto items-center justify-end gap-[5px] overflow-hidden text-right',
                  {
                    'text-alt': baseToken?.whitelisted,
                  },
                  {
                    'text-main': !baseToken?.whitelisted,
                  },
                )}
              >
                <LSpan
                  className='flex-auto truncate text-2xl font-bold'
                  isLoading={!baseToken?.address || (baseToken?.whitelisted && isMaxLeverageLoading) || isBaseTokenAmountLoading}
                  text={displayedYouReceive}
                  textSize={24}
                />
              </div>
            </Conditional>
          </div>
          <div className='ml-auto h-[15px] text-xs opacity-40'>
            <Conditional if={!usdcPriceError && priceUsdc && priceUsdc[baseToken?.address]}>
              <p>
                {`1 SOL = ${
                  priceFreeze > 0
                    ? formatSol(priceFreeze / Number(solInput) / Number(currentLeverage), CRYPTO_DECIMAL_POINTS)
                    : formatSol(priceSol?.[SOL_ADDRESS]?.price || 0, CRYPTO_DECIMAL_POINTS)
                } ${baseToken?.symbol?.toLocaleUpperCase()}`}
              </p>
            </Conditional>

            <Conditional if={!!usdcPriceError}>
              <span>Error has occurred</span>
            </Conditional>
          </div>
        </section>
        <section className='mb-[20px] flex items-center justify-between gap-[10px] text-sm'>
          <div className='mb-[-60px] mr-[-120px] mt-[-50px] flex flex-auto flex-col gap-[5px] pb-[60px] pl-[5px] pr-[125px] pt-[50px]'>
            <LabelledValue
              hintInternalComponent={
                <>
                  <Conditional if={hasLavaRockNFT}>
                    <span>Fee reduced for </span>
                    <span><a className='underline' href='https://lavarage.gitbook.io/lavarage/community/lava-rock-alpha' rel='noreferrer' target='_blank'>Lava Rock</a></span>
                    <span> holder!</span>
                    <p className='mb-5'/>
                  </Conditional>
                  <Conditional if={!hasLavaRockNFT}>
                    <span>Own a </span>
                    <span><a className='underline' href='https://lavarage.gitbook.io/lavarage/community/lava-rock-alpha' rel='noreferrer' target='_blank'>Lava Rock</a></span>
                    <span> to reduce your fees!</span>
                    <p className='mb-5'/>
                  </Conditional>
                  <span>More details on </span>
                  <span><a className='underline' href='https://lavarage.gitbook.io/lavarage/platform/fee' rel='noreferrer' target='_blank'>fees</a></span>
                </>
              }
              hintPosition='right'
              isLoading={baseToken?.whitelisted && isMaxLeverageLoading}
              label={`Fee ${isMinFee ? '' : '(0.5%)'}`}
              value={displayedFee === 0 ? 'Free' : localFormatCurrency(displayedFee)}
            />
            <Conditional if={!isLeverageEqualToOne}>
              <LabelledValue
                hintPosition='right'
                isLoading={baseToken?.whitelisted && isMaxLeverageLoading}
                label='Total'
                labelClassName='font-bold'
                labelHint={isLeverageEqualToOne ? undefined : 'This payable amount excludes network fees such as rent and gas.'}
                labelHintClassName='tooltip-right'
                value={localFormatCurrency(total)}
                valueClassName={clsxm('font-bold', {
                  'text-yellow': stringToNumber(solInput) > maxAvailableSol,
                })}
              />
            </Conditional>
          </div>
          <div className='flex-none'>
            <ActionMainButton actionMainButtonMode={actionMainButtonMode} borrow={borrow} loading={loading} swap={swap}/>
          </div>
        </section>
        <LoanSummary
          borrowedAmount={borrowedAmount}
          className={clsxm({ invisible: parseFloat(currentLeverage) === MIN_LEVERAGE || !baseToken?.whitelisted })}
          entryPrice={entryPrice}
          hasLavaRockNFT={hasLavaRockNFT}
          isLoading={isMaxLeverageLoading}
          liquidationPrice={liquidationPrice}
          localFormatCurrency={localFormatCurrency}
          positionSize={positionSize - jupiterPlatformFee}
        />
      </div>
    </Layout>
  )
}
