import clsx from 'clsx'
import { type HTMLAttributes, type PropsWithChildren } from 'react'

type Props = HTMLAttributes<HTMLDivElement> & PropsWithChildren

export function PositionCardsGrid({
  children,
  className,
  ...props
}: Props) {
  return (
    <div {...props} className={clsx(className, 'desk:px-[30px] flex w-full flex-col items-center')}>
      <div className={clsx('grid-cols grid w-full max-w-7xl auto-rows-auto items-start justify-start gap-5')} style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(340px, 1fr))' }}>
        {children}
      </div>
    </div>
  )
}
