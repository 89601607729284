import googleAnalytics from '@analytics/google-analytics'
import Analytics, { type AnalyticsInstance } from 'analytics'

export const analytics: AnalyticsInstance = Analytics({
  app: 'Lavarage',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-0Y9ZVPFSPP'],
    }),
  ],
})

type TrackEventProps = {
  category: string
  action: string
  label: string
  value?: string | number | Record<string, any>
}

export const trackEvent = ({ category, action, label, value }: TrackEventProps) => {
  analytics.track(action, {
    category,
    label,
    value,
  })
}

// Existing event tracking functions
export const trackDemoModeDiscordBannerGA = () => {
  trackEvent({
    category: 'Navigation',
    action: 'Click',
    label: 'Demo Mode Discord',
  })
}

export const trackWalletConnectGA = () => {
  trackEvent({
    category: 'User Interaction',
    action: 'Connect',
    label: 'Wallet',
  })
}
export const trackWalletSuccessConnectGA = (details: Record<string, any>) => {
  trackEvent({
    category: 'User Interaction',
    action: 'Connect Success',
    label: 'Wallet Connect Successful',
    value: details,
  })
}

// Start event tracking functions for Trade page
export const trackTradeExecutionGA = (details: Record<string, any>) => {
  trackEvent({
    category: 'Trade',
    action: 'Execute',
    label: 'Trade',
    value: details,
  })
}

export const trackTradeFailedGA = (details: Record<string, any>) => {
  trackEvent({
    category: 'Trade',
    action: 'Trade Failed',
    label: 'Error occurred during trade',
    value: details,
  })
}

export const trackTradeCancelledGA = (details: Record<string, any>) => {
  trackEvent({
    category: 'Trade',
    action: 'Trade Canceled',
    label: 'User canceled trade',
    value: details,
  })
}

export const trackTradeSuccessGA = (details: Record<string, any>) => {
  trackEvent({
    category: 'Trade',
    action: 'Success',
    label: 'Trade Success',
    value: details,
  })
}

export const trackSwapExecutionGA = (details: Record<string, any>) => {
  trackEvent({
    category: 'Swap',
    action: 'Swap Execute',
    label: 'Swap Execute',
    value: details,
  })
}

export const trackSwapSuccessGA = (details: Record<string, any>) => {
  trackEvent({
    category: 'Swap',
    action: 'Swap Success',
    label: 'Swap Success',
    value: details,
  })
}

export const trackTokenSearchGA = (query: string) => {
  trackEvent({
    category: 'User Interaction',
    action: 'Search',
    label: 'Token Search',
    value: query,
  })
}

export const trackTokenSelectGA = (token: string) => {
  trackEvent({
    category: 'User Interaction',
    action: 'Select',
    label: 'Token',
    value: token,
  })
}

export const trackNavigationClickGA = (label: string) => {
  trackEvent({ category: 'Navigation', action: `Click ${label}`, label })
}
// End event tracking functions for Trade page

// Start event tracking functions for Positions page
export const trackSellExecuteGA = () => {
  trackEvent({
    category: 'Positions',
    action: 'Sell Execute',
    label: 'The user initiated selling his position',
  })
}

export const trackSellSuccessGA = () => {
  trackEvent({
    category: 'Positions',
    action: 'Sell Success',
    label: 'The user sold his position',
  })
}

export const trackRepayExecuteGA = () => {
  trackEvent({
    category: 'Positions',
    action: 'Repay Execute',
    label: 'The user initiated repaying his position',
  })
}

export const trackRepaySuccessGA = () => {
  trackEvent({
    category: 'Positions',
    action: 'Repay Success',
    label: 'User repaid his position',
  })
}

export const trackSellFailedGA = () => {
  trackEvent({
    category: 'Positions',
    action: 'Sell Failed',
    label: 'An error occurred during the selling of the position',
  })
}

export const trackSellCancelledGA = () => {
  trackEvent({
    category: 'Positions',
    action: 'Sell Canceled',
    label: 'User canceled the selling of his position',
  })
}

export const trackRepayFailedGA = () => {
  trackEvent({
    category: 'Positions',
    action: 'Repay Failed',
    label: 'An error occurred during the repayment of the position',
  })
}

export const trackRepayCancelledGA = () => {
  trackEvent({
    category: 'Positions',
    action: 'Repay Canceled',
    label: 'User canceled the repayment of his position',
  })
}
// End event tracking functions for Positions page

// Start event tracking functions for Staking page
export const trackStakeExecuteGA = () => {
  trackEvent({
    category: 'Staking',
    action: 'Stake Execute',
    label: 'The user initiated staking',
  })
}

export const trackStakeSuccessGA = (details: Record<string, any>) => {
  trackEvent({
    category: 'Staking',
    action: 'Stake Success',
    label: 'The user staked successfully',
    value: details,
  })
}

export const trackUnstakeExecuteGA = () => {
  trackEvent({
    category: 'Staking',
    action: 'Unstake Execute',
    label: 'The user initiated unstaking',
  })
}

export const trackUnstakeSuccessGA = (details: Record<string, any>) => {
  trackEvent({
    category: 'Staking',
    action: 'Unstake Success',
    label: 'The user unstaked successfully',
    value: details,
  })
}

export const trackClaimExecuteGA = () => {
  trackEvent({
    category: 'Staking',
    action: 'Claim Execute',
    label: 'The user initiated claim',
  })
}

export const trackClaimSuccessGA = (details: Record<string, any>) => {
  trackEvent({
    category: 'Staking',
    action: 'Claim Success',
    label: 'The user claimed successfully',
    value: details,
  })
}

export const trackStakeFailedGA = () => {
  trackEvent({
    category: 'Staking',
    action: 'Staking Failed',
    label: 'An error occurred during staking',
  })
}

export const trackStakeCancelledGA = () => {
  trackEvent({
    category: 'Staking',
    action: 'Staking Canceled',
    label: 'User canceled the staking process',
  })
}

export const trackUnstakeFailedGA = () => {
  trackEvent({
    category: 'Staking',
    action: 'Unstaking Failed',
    label: 'An error occurred during unstaking',
  })
}

export const trackUnstakeCancelledGA = () => {
  trackEvent({
    category: 'Staking',
    action: 'Unstaking Canceled',
    label: 'User canceled the unstaking process',
  })
}

export const trackClaimFailedGA = () => {
  trackEvent({
    category: 'Staking',
    action: 'Claiming Failed',
    label: 'An error occurred during claiming',
  })
}

export const trackClaimCancelledGA = () => {
  trackEvent({
    category: 'Staking',
    action: 'Claiming Canceled',
    label: 'User canceled the claiming process',
  })
}
// End event tracking functions for Staking page
