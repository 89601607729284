export function formatTimestamp(timestamp: number, timeZone = 'UTC') {
  const date = new Date(timestamp * 1000)
  if (isNaN(date.getTime())) {
    throw new Error('Invalid time value')
  }
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone,
  } as const

  const formatter = new Intl.DateTimeFormat('en-US', options)
  const parts = formatter.formatToParts(date)

  const year = parts.find(part => part.type === 'year')?.value
  const month = parts.find(part => part.type === 'month')?.value
  const day = parts.find(part => part.type === 'day')?.value
  const hour = parts.find(part => part.type === 'hour')?.value
  const minute = parts.find(part => part.type === 'minute')?.value

  if (year && month && day && hour && minute) {
    return `${year}-${month}-${day} ${hour}:${minute}`
  }
  else {
    throw new Error('Invalid time value')
  }
}
