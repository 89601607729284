import { type ReactNode } from 'react'

type ForProps<T> = {
  of: T[]
  children: (item: T, index: number) => ReactNode
}

export function For<T>({ of, children }: ForProps<T>) {
  if (!of.length) return null
  return <>{of.map((item, index) => children(item, index))}</>
}
