import { TokenPair } from '@/app/components'
import { useNetworkStatus } from '@/app/hooks/useNetworkStatus'
import { ClaimButton } from '@/app/pages/stake/components'
import { getClaimButtonMode } from '@/app/pages/stake/utils/getClaimButtonMode'
import { CRYPTO_DECIMAL_POINTS } from '@/config'
import { calculateTimeToDate } from '@/utils'
import { formatSol } from '@/utils/formatters'
import { useEffect } from 'react'
import { clsxm } from '../../../helpers/clsxm.js'

type TokenInfo = {
  symbol: string
  imageUri: string
  amount: number
}

type ClaimRowProps = {
  unstakedDate: string
  timestampClaim: number
  timeLeft?: string
  from: TokenInfo
  to: TokenInfo
  isConfirming: boolean
  isFinalizing: boolean
  onClaim: () => void
  onUnmount: () => void
}

export function ClaimRow({ unstakedDate, timestampClaim, from, to, onClaim, isConfirming, isFinalizing, onUnmount }: ClaimRowProps) {
  const timeLeft = calculateTimeToDate(timestampClaim.toString())
  const isClaimable: boolean = timeLeft === 'Expired'
  const isOnline = useNetworkStatus()
  const claimButtonMode = getClaimButtonMode({
    isOnline,
    isClaimable,
    hasAccess: true,
    isConfirming,
    isFinalizing,
  })

  useEffect(() => {
    return () => {
      onUnmount()
    }
  }, [])

  return (
    <li
      key={timestampClaim}
      className={clsxm('bg-main text-main flex w-full flex-col flex-wrap rounded-lg bg-opacity-10', {
        'bg-alt': isClaimable,
        'text-alt': isClaimable,
      })}
    >
      <div
        className={clsxm('bg-main flex h-[50px] items-center justify-between rounded-t-lg bg-opacity-10 px-[10px] text-sm', {
          'bg-alt': isClaimable,
        })}
      >
        <p>
          Unstaked on
          {' '}
          {unstakedDate}
        </p>
        <ClaimButton claimButtonMode={claimButtonMode} timeLeft={timeLeft} onClaim={onClaim}/>
      </div>
      <div className='flex items-center gap-[5px] rounded-t-lg p-[10px]'>
        <TokenPair left={{ logoURI: from.imageUri, altName: from.symbol }} right={{ logoURI: to.imageUri, altName: to.symbol }}/>
        <span>{`${`${formatSol(from.amount, CRYPTO_DECIMAL_POINTS)} ${from.symbol} -> ${formatSol(to.amount, CRYPTO_DECIMAL_POINTS)} ${to.symbol}`}`}</span>
      </div>
    </li>
  )
}
