import { type IPriceService } from '@/services'
import { BaseService } from '@/services/BaseService'
import { jupiterSource } from '@/services/sources'

export class PriceService extends BaseService {
  private priceSource: IPriceService

  constructor(priceSource: IPriceService) {
    super()
    this.priceSource = priceSource
  }

  /**
   * Retrieves the price information for a list of tokens.
   *
   * @param tokens - An array of token identifiers or ids (e.g., ["BTC", "ETH", "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"]).
   * @param secondToken - An optional parameter specifying the token to compare against.
   * @returns The price information as a response object.
   */
  async getPrice({ tokenIds, secondToken }: { tokenIds: string[]; secondToken?: string }) {
    const tokensString = tokenIds.join(',')

    try {
      const response = await this.priceSource.getPrice(tokensString, secondToken)
      return response
    }
    catch (error: unknown) {
      console.error(error)
    }
  }
}

export const priceService = new PriceService(jupiterSource)
