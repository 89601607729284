import { AxiosError } from 'axios'
import { API_HOST } from '../app/app.config.js'
import { BaseService } from './BaseService.js'

export class TephrasBonusService extends BaseService {
  async saveBonusInfo(wallet: string, nameOfWallet: string, positionId: string | undefined) {
    try {
      if (!wallet || !positionId) {
        return
      }
      if (nameOfWallet !== 'Backpack') {
        return
      }

      this.http.post(`${API_HOST}/bonus-info`, { wallet, nameOfWallet, positionId })
    }
    catch (error) {
      if (error instanceof AxiosError && error.status === 400) {
        return
      }
    }
  }
}

export const tephrasBonusService = new TephrasBonusService()
