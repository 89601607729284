import { type CloseModalButtonMode, CloseModalTabs } from '@/app/pages/positions/components'

type GetCloseModalButtonModeProps = {
  isOnline: boolean
  currentTab: CloseModalTabs
  confirming: boolean
  availableSol: number | null
  borrowedAmount: number
  isRestricted?: boolean
}

export const getCloseModalButtonMode = ({
  isOnline,
  currentTab,
  confirming,
  availableSol,
  borrowedAmount,
  isRestricted,
}: GetCloseModalButtonModeProps): CloseModalButtonMode => {
  switch (true) {
    case !isOnline:
      return 'no-connection'
    case confirming:
      return 'confirming'
    case isRestricted:
      return 'restricted' || availableSol === null
    case availableSol !== null && borrowedAmount > availableSol && currentTab === CloseModalTabs.REPAY:
      return 'insufficient-funds'
    case availableSol !== null && borrowedAmount <= availableSol && !confirming && currentTab === CloseModalTabs.REPAY:
      return 'repay'
    case !confirming && currentTab === CloseModalTabs.SELL:
      return 'sell'
    default:
      return 'restricted'
  }
}
