export function calculateTimeToDate(timestampEndDate: string) {
  const timestampEnd = Number(timestampEndDate)
  const currentTime = Date.now()

  const remainingTime = timestampEnd - currentTime
  if (remainingTime < 0) {
    return 'Expired'
  }

  if (remainingTime < 1 * 60 * 60 * 1000) {
    const minutesLeft = Math.ceil(remainingTime / (60 * 1000))
    if (minutesLeft === 1) {
      return `${minutesLeft} minute left`
    }
    return `${minutesLeft} minutes left`
  }
  else if (remainingTime < 24 * 60 * 60 * 1000) {
    const hoursLeft = Math.ceil(remainingTime / (60 * 60 * 1000))
    if (hoursLeft === 1) {
      return `${hoursLeft} hour left`
    }
    return `${hoursLeft} hours left`
  }
  else {
    const daysLeft = Math.ceil(remainingTime / (24 * 60 * 60 * 1000))
    if (daysLeft === 1) {
      return `${daysLeft} day left`
    }
    return `${daysLeft} days left`
  }
}
