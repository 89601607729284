export class NotificationService {
  static parseMessage(message: string): string {
    const coinRegex = /<coin address="([^"]+)">([^<]+)<\/coin>/g
    const linkRegex = /<link to="([^"]+)">([^<]+)<\/link>/g
    let newMessage = message

    newMessage = newMessage.replace(coinRegex, (match, address, coinName) => {
      return `<span data-address="${address}" class="underline ml-[5px] cursor-pointer">${coinName}</span>`
    })

    newMessage = newMessage.replace(linkRegex, (match, url, linkText) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="ml-[5px] underline">${linkText}</a>`
    })

    return newMessage
  }

  static hashMessage(message: string): string {
    return Math.abs(message.split('').reduce((hash, char) => {
      return char.charCodeAt(0) + (hash << 6) + (hash << 16) - hash
    }, 0)).toString()
  }
}
