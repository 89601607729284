import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

export type AlertType = 'inProgress' | 'success' | 'transactionFails' | 'error' | 'transactionWaiting'
export interface IAlert {
  reasonMessage?: string
  visible?: boolean
  type: AlertType
  tx?: () => void
  sentryId?: string
}

interface AlertsState {
  alerts: IAlert[]
  addAlert: (alert: IAlert) => void
  hideAlert: (i: number) => void
  removeAlert: (i: number) => void
  loading: boolean
  setLoading: (loading: boolean) => void
  confirming: boolean
  setConfirming: (confirming: boolean) => void
}

export const useAlertsStore = create<AlertsState>()(devtools(
  set => ({
    loading: false,
    setLoading: (loading: boolean) => set({ loading }),
    confirming: false,
    setConfirming: (confirming: boolean) => set({ confirming }),
    alerts: [],
    addAlert: alert => set(state => ({
      alerts: [...state.alerts, { ...alert, visible: true }],
    })),
    removeAlert: i => set(state => ({
      alerts: state.alerts.filter((_, index) => index !== i),
    })),
    hideAlert: i => set(state => ({
      alerts: state.alerts.map((alert, index) => (index === i ? { ...alert, visible: false } : alert)),
    })),
  }),
  {
    name: 'alerts-storage',
  },
))
