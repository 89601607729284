import { FALLBACK_RPC, PROGRAM_ID, SOLANA_RPC, SOLANA_WS_RPC } from '@/config'
import { LavarageService } from '@/services'
import { AnchorProvider } from '@coral-xyz/anchor'
import { useWallet } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import React, { createContext, useContext, useMemo } from 'react'
import FallbackConnection from 'solana-fallback-connection'

const programId = new PublicKey(PROGRAM_ID)
const LavarageContext = createContext<LavarageService | undefined>(undefined)

const LavarageProvider = ({ children }: { children: React.ReactNode }) => {
  const wallet = useWallet()

  const connection = useMemo(() => new FallbackConnection([SOLANA_RPC, FALLBACK_RPC], { wsEndpoint: SOLANA_WS_RPC }), [])

  const lavarage = useMemo(
    () => new LavarageService(new AnchorProvider(connection, wallet, AnchorProvider.defaultOptions()), programId),
    [wallet.publicKey, connection],
  )

  return <LavarageContext.Provider value={lavarage}>{children}</LavarageContext.Provider>
}

const useLavarage = () => useContext(LavarageContext)

export { LavarageProvider, useLavarage }
