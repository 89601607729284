import { Button, Conditional, For } from '@/app/components'
import { clsxm } from '@/app/helpers/clsxm'
import { IconEmptyClosedPosition } from '@/assets'
import { type TokenInfo } from '@/services'
import { useWallet } from '@solana/wallet-adapter-react'
import { useWalletModal } from '@solana/wallet-adapter-react-ui'
import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { usePositions } from '../../../hooks/usePositions.js'
import { PositionCard } from './PositionCard.js'
import { PositionCardsGrid } from './PositionCardsGrid.js'

export const ClosedPositions = () => {
  const { tokens } = useOutletContext<{ tokens: TokenInfo[] }>()
  const { closedPositions, refresh } = usePositions()
  const { connected } = useWallet()
  const { setVisible } = useWalletModal()
  const [focusedIndex, setFocusedIndex] = useState(-1)
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const hasClosedPositions = closedPositions.length > 0

  useEffect(() => {
    refresh()
  }, [refresh])

  return (
    <div className={clsxm('flex flex-1 flex-col py-6')}>
      <Conditional if={hasClosedPositions}>
        <PositionCardsGrid>
          <For of={closedPositions}>
            {(position, idx) => (
              <PositionCard
                key={position.publicKey}
                isExpanded={idx === focusedIndex}
                position={position}
                timezone={timezone}
                tokens={tokens}
                onToggleDetails={() => setFocusedIndex(idx === focusedIndex ? -1 : idx)}
              />
            )}
          </For>
        </PositionCardsGrid>
      </Conditional>
      <Conditional if={!hasClosedPositions}>
        <div className='m-auto flex max-w-[400px] flex-col items-center justify-center gap-5 text-center text-base'>
          <IconEmptyClosedPosition/>
          <Conditional if={connected}>
            <p>You have no closed positions (only recently closed positions will be displayed).</p>
          </Conditional>
          <Conditional if={!connected}>
            <p>Connect your wallet to view your closed positions.</p>
            <Button color='gradient' size='m' onClick={() => setVisible(true)}>Connect Wallet</Button>
          </Conditional>
        </div>
      </Conditional>
    </div>
  )
}
