export function tokenPrecision(number: number, maxPrecision: number) {
  if (number.toString().includes('e-')) {
    const exponent = parseInt(number.toString().split('e-')[1], 10)
    const numStr = number.toFixed(exponent + 1)
    return numStr
  }

  const numberStr = number.toFixed(maxPrecision)
  const trimmedStr = numberStr.replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, '')
  return trimmedStr
}
