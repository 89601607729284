export const PROGRAM_ID = 'CRSeeBqjDnm3UPefJ9gxrtngTsnQRhEJiTA345Q83X3v'
export const SOLANA_RPC = 'https://solana-mainnet.g.alchemy.com/v2/yTBaNPPya9CJDsgBHq-dg89gpjzbFzbQ/'
export const SOLANA_WS_RPC = 'wss://solana-mainnet.core.chainstack.com/ws/8cde996495659fabe0b76a1eb576a995'
export const STAKING_PROGRAM_ID = '85vAnW1P89t9tdNddRGk6fo5bDxhYAY854NfVJDqzf7h'
export const LSTSOL_ADDRESS = '6M9o6GD1zPWZdtR7D8EMs25vb2ssTBs8jMnfJXrakFMB'
export const FALLBACK_RPC = 'https://dinah-iq8ixm-fast-mainnet.helius-rpc.com/'
export const COLLECTION_KEY = '3HeEvzCyUK3M7Q2xkvMeZojAnVYmn3yHGHHJHmRktUVw'
export const NODE_WALLET = 'GKXdLXFicCNYD4w2muLKrwdnP9omDWvrnWEgLAqNRXx5'
export const SOL_ADDRESS = 'So11111111111111111111111111111111111111112'
export const USDC_ADDRESS = 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'
export const SOL_LOGO_URI = 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png'
export const LSTSOL_LOGO_URI = 'https://olive-brilliant-opossum-104.mypinata.cloud/ipfs/QmSKejtq8LzmQhtaJqQoK6Z4D8UyBFDAwjWFaAwLRU8gF2'
export const PLATFORM_FEE = 0.5 // 0.5%
export const MIN_PLATFORM_FEE_IN_SOL = 0.005 // 0.005 SOL or 0.5%
export const PROFIT_FEE = 5 // 5%
export const FIAT_DECIMAL_POINTS = 2
export const CRYPTO_DECIMAL_POINTS = 4
export const MIN_LEVERAGE = 1
export const LIQUIDATION_LTV = 90
export const JUPITER_PLATFORM_FEE_BPS = 50

export const BANNED_TOKENS = [
  'C5vQ5W9ymUpRK1UMmNHc8eLCN87xi9trGEzvvGaZt8EB',
  '2N53P6hfvFqnApGL71oyXnocAUpyCYBeNtcYcRDy1Qsg',
  'AjeoZ39HTivE5GuYS9DwEwRLbi7YJgoE1ADVqoa5YXDF',
]
