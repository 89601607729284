import { type ClaimButtonMode } from '../components/ClaimButton'

type GetClaimButtonModeProps = {
  isOnline: boolean
  hasAccess: boolean
  isConfirming: boolean
  isFinalizing: boolean
  isClaimable: boolean
}

export const getClaimButtonMode = ({ isOnline, hasAccess, isConfirming, isFinalizing, isClaimable }: GetClaimButtonModeProps): ClaimButtonMode => {
  switch (true) {
    case !isOnline:
      return 'no-connection'
    case !hasAccess:
      return 'restricted'
    case isConfirming:
      return 'confirming'
    case isFinalizing:
      return 'finalizing'
    case isClaimable:
      return 'claim'
    case !isClaimable:
      return 'timeLeft'
    default:
      return 'restricted'
  }
}
