import { IconAltHint, IconBirdeye, IconDown, IconSolscan, IconUp } from '@/assets/svgs/index.js'
import { CRYPTO_DECIMAL_POINTS, LIQUIDATION_LTV, SOL_ADDRESS } from '@/config.js'
import { type TokenInfo } from '@/services/types.js'
import { formatPercent, formatSol } from '@/utils/formatters/index.js'
import type { Position } from '@lavarage/entities'
import clsx from 'clsx'
import type { HTMLAttributes } from 'react'
import { Button, Conditional, TokenImage } from '../../../components/index.js'
import { LabelledValue } from '../../../components/LabelledValue.js'
import { capitalize, clsxm, getTextColorByString } from '../../../helpers/index.js'
import { calculatePositionMetrics, getPositionPnl, getPositionRoi, timeSince } from '../utils/index.js'

const getStatusColorByPosition = ({ status }: Position) => {
  switch (status) {
    case 'recalled':
      return 'recalled'
    case 'liquidated':
      return 'liquidated'
    default:
      return 'active'
  }
}

type Props = HTMLAttributes<HTMLDivElement> & {
  isExpanded?: boolean
  position: Position
  prices?: Record<string, { price: number }>
  timezone: string
  tokens?: TokenInfo[]
  onClose?: (position: Position) => void
  onToggleDetails?: () => void
}

export const PositionCard = ({
  className,
  isExpanded,
  position,
  prices,
  timezone,
  tokens,
  onClose,
  onToggleDetails,
  ...props
}: Props) => {
  const { pool: { baseCurrency } } = position
  const collateralPrice = baseCurrency ? prices?.[baseCurrency.address]?.price : undefined
  const solPrice = prices?.[SOL_ADDRESS]?.price
  const token = tokens?.find(({ address }) => address === baseCurrency?.address)

  const {
    currentPrice,
    liquidationPrice,
    currentLTV,
    roi,
    finalRoi,
    priceChangePercentage,
    pnl,
    finalPnl,
    averageDailyInterest,
    closingChangePercentage,
    startDate,
    closeDate,
  } = calculatePositionMetrics(position, solPrice, collateralPrice, timezone)

  const positionPnl = getPositionPnl({ position, pnl, finalPnl })
  const positionRoi = getPositionRoi({ position, roi, finalRoi })

  return (
    <div {...props} className={clsx(className, 'flex flex-col overflow-hidden rounded-lg text-sm')}>
      <div className='bg-main/10 flex justify-between px-[10px] py-[5px]'>
        <div className='flex flex-1 justify-start gap-[6px]'>
          <Button
            disabled
            color={getStatusColorByPosition(position)}
            size='s'
          >
            <p className='flex items-center'>
              {capitalize(position.status)}

            </p>
          </Button>
          <Conditional if={position.status === 'liquidated' || position.status === 'sold' || position.status === 'repaid'}>
            <span className='my-auto text-center'>{timeSince((position.closeDate?.getTime() ?? 0) / 1000)}</span>
          </Conditional>
          <Conditional if={position.status === 'active'}>
            <span className='my-auto text-center'>{timeSince(position.openDate.getTime() / 1000)}</span>
          </Conditional>
          <Conditional if={position.status === 'recalled'}>
            <div
              className='tooltip tooltip-primary my-auto text-left'
              data-tip='Lender has recalled this position. Close within 48 hours to avoid liquidation.'
            >
              <IconAltHint width={16}/>
            </div>
          </Conditional>
        </div>
        <div className='flex'>
          <IconSolscan className='my-auto mr-[10px] cursor-pointer' onClick={() => window.open(`https://solscan.io/account/${position?.publicKey}`, '_blank', 'noopener,noreferrer')}/>
          <IconBirdeye className='my-auto mr-[6px] cursor-pointer' onClick={() => window.open(`https://birdeye.so/token/${position?.pool.baseCurrency?.address}?chain=solana`, '_blank', 'noopener,noreferrer')}/>
          <Conditional if={position.status === 'active'}>
            <Button color='main' size='s' onClick={() => onClose?.(position)}>Close</Button>
          </Conditional>
        </div>
      </div>
      <div className='bg-main/5 flex flex-col gap-[10px] px-[10px] py-[15px]'>
        <div className='flex h-[30px] justify-between'>
          <div className='flex items-center gap-2 text-base'>
            <TokenImage altName={token?.name ?? ''} logoURI={token?.logoURI ?? null}/>
            <LabelledValue
              size='lg'
              value={`${formatSol(position.collateralSize.toNumber(), CRYPTO_DECIMAL_POINTS)} ${token?.symbol.toLocaleUpperCase() || ''}`}
            />
          </div>
          <LabelledValue
            isLoading={position.status === 'active' ? (!solPrice || !currentPrice) : false}
            size='lg'
            subValue={position.status === 'active' ? formatPercent(priceChangePercentage.toNumber(), 1) : formatPercent(closingChangePercentage.toNumber(), 1)}
            subValueClassName={position.status === 'active' ? getTextColorByString(formatPercent(priceChangePercentage.toNumber(), 1)) : getTextColorByString(formatPercent(closingChangePercentage.toNumber(), 1))}
            value={position.status === 'active' ? `${formatSol(currentPrice * position.collateralSize.toNumber())} SOL` : `${formatSol(position.positionSize.toNumber())} SOL`}
          />
        </div>
        <div className='flex h-[17px] items-center justify-between gap-[10px]'>
          <LabelledValue
            className='w-[150px]'
            isLoading={position.status === 'active' ? !collateralPrice : false}
            label='LTV'
            labelHint='Current vs. liquidation LTV. Risk of liquidation arises when current LTV ≥ liquidation LTV.'
            labelHintClassName='tooltip-right'
            value={`${position.status === 'active' ? `${formatSol(currentLTV.toNumber(), 0)}` : formatSol(position.borrowedAmount.div(position.positionSize).multipliedBy(100).toNumber(), 0)}%/${LIQUIDATION_LTV}%`}
            valueClassName={clsxm(position.status === 'active' ? {
              'text-purple': currentLTV.isGreaterThanOrEqualTo(90),
              'text-yellow': currentLTV.isGreaterThanOrEqualTo(70) && currentLTV.isLessThan(90),
              'text-main': currentLTV.isLessThan(70),
            } : {
              'text-purple': position.borrowedAmount.div(position.positionSize).multipliedBy(100).isGreaterThanOrEqualTo(90),
              'text-yellow':
                        position.borrowedAmount.div(position.positionSize).multipliedBy(100).isGreaterThanOrEqualTo(70) &&
                        position.borrowedAmount.div(position.positionSize).multipliedBy(100).isLessThan(90),
              'text-main': position.borrowedAmount.div(position.positionSize).multipliedBy(100).isLessThan(70),
            })}
          />
          <LabelledValue
            className='flex-1'
            isLoading={position.status === 'active' && (!solPrice || !collateralPrice)}
            label='PnL'
            subValue={positionRoi === undefined ? '--' : `${formatPercent(positionRoi, 1)}`}
            subValueClassName={getTextColorByString(formatSol(positionRoi ?? NaN))}
            value={positionPnl === undefined ? '--' : `${formatSol(positionPnl)} SOL`}
            valueClassName={getTextColorByString(formatSol(positionPnl ?? NaN))}
          />
        </div>
      </div>
      <div className='flex flex-col justify-between'>
        <button className={clsxm('bg-main desk:hover:bg-opacity-20 flex h-[36px] items-center justify-center bg-opacity-10 transition')} onClick={onToggleDetails}>
          <span>{isExpanded ? 'Hide Details' : 'Show Details'}</span>
          <div className='my-auto ml-[5px]'>{isExpanded ? <IconUp height={20}/> : <IconDown height={20}/>}</div>
        </button>
        <Conditional if={isExpanded}>
          <div className='bg-main/10 p-[10px]'>
            <LabelledValue label='Open Date' value={startDate}/>
            <LabelledValue label='Close Date' value={position.status === 'active' ? '--' : closeDate}/>
            <LabelledValue label='Initial Margin' value={`${formatSol(position.initialMargin.toNumber())} SOL`}/>
            <LabelledValue label='Initial Position Size' subValue={`${position.initialLeverage.toNumber().toFixed(1)}x`} value={`${formatSol(position.initialPositionSize.toNumber())} SOL`}/>
            <LabelledValue label='Entry Price' value={`${formatSol(position.entryPrice.toNumber())} SOL`}/>
            <LabelledValue isLoading={position.status === 'active' && !solPrice} label='Current Price' labelHint='Latest collateral price (for reference while position is open; may vary at close).' value={`${position.status === 'active' ? formatSol(currentPrice) : formatSol(position.price.toNumber())} SOL`}/>
            <LabelledValue label='Liquidation Price' labelHint='If asset price ≤ this amount, your position risks liquidation.' value={`${formatSol(liquidationPrice)} SOL`}/>
            <LabelledValue label='Daily Interest' value={`${formatSol(averageDailyInterest.toNumber())}%`}/>
            <LabelledValue label='Interest Accrued' labelHint='Total interest accrued, calculated daily at 00:00 UTC.' value={`${formatSol(position.interestAccrued.toNumber(), CRYPTO_DECIMAL_POINTS)} SOL`}/>
          </div>
        </Conditional>
      </div>
    </div>
  )
}
