export function getTextColorByString(str: string): string {
  const number = parseFloat(str)
  switch (true) {
    case number > 0:
      return 'text-green'
    case number < 0:
      return 'text-yellow'
    case number === 0:
      return 'text-main'
    default:
      return ''
  }
}
