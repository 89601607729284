import type { HTMLAttributes } from 'react'
import { clsxm } from '../helpers/clsxm.js'
import { Conditional } from './Conditional.js'
import { Hint } from './Hint.js'

type Props = HTMLAttributes<HTMLDivElement> & {
  isLoading?: boolean
  label?: string
  labelClassName?: string
  labelHint?: string
  labelHintClassName?: string
  hintPosition?: 'top' | 'bottom' | 'left' | 'right'
  size?: 'sm' | 'lg'
  subValue?: string
  subValueClassName?: string
  value?: unknown
  valueClassName?: string
  hintInternalComponent?: React.ReactNode
}

export function LabelledValue({
  className,
  isLoading = false,
  label,
  labelClassName,
  labelHint,
  labelHintClassName,
  hintPosition,
  size = 'sm',
  subValue,
  subValueClassName,
  value,
  valueClassName,
  hintInternalComponent,
  ...props
}: Props) {
  return (
    <div
      {...props}
      className={clsxm('flex items-center', {
        'text-sm': size === 'sm',
        'text-base': size === 'lg',
      }, className)}
    >
      <Conditional if={label !== undefined}>
        <div className='flex items-center overflow-hidden'>
          <span className={clsxm('truncate opacity-70', labelClassName)}>{label}</span>
        </div>
        <Conditional if={!!labelHint}>
          <Hint className={clsxm('ml-1', labelHintClassName)} position={hintPosition} text={labelHint}/>
        </Conditional>
        <Conditional if={!!hintInternalComponent && !labelHint}>
          <Hint className={clsxm('ml-1', labelHintClassName)} position={hintPosition}>
            {hintInternalComponent}
          </Hint>
        </Conditional>
        <div className='min-w-2 flex-1'/>
      </Conditional>
      <Conditional if={isLoading || value !== undefined}>
        <div className='flex shrink-0 items-center gap-1'>
          <Conditional if={isLoading}>
            <span className='loading loading-xxs loading-spinner'/>
          </Conditional>
          <Conditional if={!isLoading}>
            <span className={clsxm(valueClassName)}>{`${value}`}</span>
            {subValue && (
              <span
                className={clsxm({
                  'text-[10px]': size === 'sm',
                  'text-xs': size === 'lg',
                }, subValueClassName)}
              >
                {subValue}
              </span>
            )}
          </Conditional>
        </div>
      </Conditional>
    </div>
  )
}
