import { Conditional } from '@/app/components'
import type { HTMLAttributes } from 'react'
import { clsxm } from '../helpers/clsxm.js'

type Props = HTMLAttributes<HTMLSpanElement> & {
  text: string | null | undefined
  unit?: string
  isUnitLeft?: boolean
  textSize: number
  className?: string
  isLoading?: boolean
  isInvalid?: boolean
}

export function LSpan({
  text,
  unit = '',
  isUnitLeft,
  textSize,
  isLoading,
  isInvalid,
  className,
  ...props
}: Props) {
  const getClassName = () => {
    if (textSize === 12) {
      return 'loading-xxxs'
    }
    else if (textSize === 14) {
      return 'loading-xxs'
    }
    else if (textSize === 16) {
      return 'loading-xs'
    }
    else if (textSize === 36) {
      return 'loading-lg'
    }
    else if (textSize < 20) {
      return 'loading-sm'
    }
    else {
      return 'loading-md'
    }
  }

  const isInvalidText = (typeof text !== 'string' && text !== null) || text === '--' || isInvalid

  return (
    <>
      <Conditional if={isLoading}>
        <span {...props} className={clsxm(getClassName(), 'loading loading-spinner')}/>
      </Conditional>
      <Conditional if={isInvalidText && !isLoading}>
        <span {...props} className={className}>--</span>
      </Conditional>
      <Conditional if={text !== null && !isInvalidText && !isLoading}>
        <span {...props} className={className}>{isUnitLeft ? unit + text : text + unit}</span>
      </Conditional>
    </>
  )
}
