import { usePoolsQuery } from '@/app/hooks/queries'
import { useLavarage } from '@/app/providers/LavarageProvider'
import { TokenService } from '@/services'
import { useQuery } from '@tanstack/react-query'
import { useCallback, useRef } from 'react'

const tokenService = new TokenService()

export const useTokensQuery = () => {
  const lavarage = useLavarage()
  const { pools } = usePoolsQuery()
  const fetchController = useRef<AbortController | null>(null)
  const firstRenderRef = useRef(true)

  const fetchTokens = useCallback(async () => {
    if (fetchController.current) {
      fetchController.current.abort()
    }

    fetchController.current = new AbortController()
    const signal = fetchController.current.signal

    try {
      if (signal.aborted) return

      const whitelistedTokenAddresses = pools.map(pool => pool.baseCurrency.address)

      const fetchedTokens = await tokenService.revalidateBasedOnWhitelistedTokens({
        whitelistedAddresses: whitelistedTokenAddresses,
        cache: firstRenderRef.current,
        signal,
      })

      if (signal.aborted) return

      firstRenderRef.current = false
      return fetchedTokens
    }
    catch (error) {
      if (signal.aborted) return
      console.error('Error fetching tokens:', error)
      return []
    }
    finally {
      fetchController.current = null
    }
  }, [pools])

  const {
    data: tokens,
    refetch,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['tokens'],
    queryFn: fetchTokens,
    enabled: !!lavarage,
    refetchInterval: 60_000,
    retry: 5,
  })

  return { tokens, refetch, isLoading, isError }
}
