import { clsxm } from '@/app/helpers/clsxm'

interface TabItemProps {
  isActive: boolean
  handleClick: () => void
  label: string
  quantity?: number
}

export const TabItem = ({ isActive, handleClick, label, quantity }: TabItemProps) => {
  return (
    <button
      className={clsxm('flex h-full w-[100px] items-center justify-center gap-2 rounded-full transition', {
        'bg-alt bg-opacity-10 text-alt': isActive,
        'desk:hover:opacity-70': !isActive,
        'pointer-events-none': isActive,
      })}
      role='tab'
      onClick={handleClick}
    >
      <div>{label}</div>
      {typeof quantity === 'number' && (
        <span
          className={clsxm('flex h-5 items-center justify-center rounded-full px-2 text-xs transition', {
            'bg-alt text-main': isActive,
            'bg-main text-background': !isActive,
          })}
        >
          {quantity > 99 ? '99+' : quantity}
        </span>
      )}
    </button>
  )
}
