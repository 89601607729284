import { isValidNumber } from '../isValidNumber'

export function formatPercentWithoutPlus(number: number, maxCharacters?: number): string {
  if (!isValidNumber(number)) return '--'
  if (number === undefined || isNaN(number) || !isFinite(number)) return '--'
  const numberStr = number.toFixed(maxCharacters || 0)
  const trimmedStr = numberStr.replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, '')
  if (trimmedStr === '0') return '0'
  return `${trimmedStr}%`
}
