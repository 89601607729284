import { trackSwapExecutionGA, trackSwapSuccessGA } from '@/app/analytics'
import { sendTransaction } from '@/app/helpers/sendTransaction'
import { useLavarage } from '@/app/providers/LavarageProvider'
import { useAlertsStore } from '@/app/stores'
import { jupiterSource } from '@/services/sources'
import { useWallet } from '@solana/wallet-adapter-react'
import {
    AddressLookupTableAccount,
    ComputeBudgetProgram,
    PublicKey,
    SystemProgram,
    TransactionInstruction,
    TransactionMessage,
    VersionedTransaction,
} from '@solana/web3.js'
import BigNumber from 'bignumber.js'
import { useCallback } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useNftAccess } from '../../../hooks/useNftAccess.js'

type TradeDetailsGA = {
  leverage: number
  quoteAmount: number
  baseAmount: number
  token: string
  fee: number
  total: number
  positionSize: number
  dailyInterest: number
  entryPrice: number
  liquidationPrice: number
}

type UseSwapProps = {
  from: string
  to: string
  amount: number
  slippage: number
  decimalsOfBaseToken: number
  setPriceFreeze: (arg0: number) => void
  tradeDetailsGA: TradeDetailsGA
}

export const useSwap = ({ from, to, amount, slippage, setPriceFreeze, decimalsOfBaseToken, tradeDetailsGA }: UseSwapProps) => {
  const lavarage = useLavarage()
  const wallet = useWallet()
  const [addAlert, removeAlert, setLoading, setConfirming] = useAlertsStore(useShallow(state => [state.addAlert, state.removeAlert, state.setLoading, state.setConfirming]))
  const { hasLavaRockNFT } = useNftAccess()
  const swap = useCallback(async () => {
    if (!lavarage?.program.provider.publicKey) {
      console.error('Provider public key is not available.')
      return
    }
    try {
      trackSwapExecutionGA(tradeDetailsGA)
      setLoading(true)
      setConfirming(true)
      const ix = await jupiterSource.getSwapIx(from, to, BigNumber(amount).times(1_000_000_000), slippage, lavarage.program.provider.publicKey.toBase58(), undefined, hasLavaRockNFT)

      const { setupInstructions, swapInstruction: swapInstructionPayload, addressLookupTableAddresses, cleanupInstruction } = ix.instructions

      const price = Number(ix.quoteResponse.outAmount) / 10 ** decimalsOfBaseToken
      setPriceFreeze(price)
      const deserializeInstruction = (instruction: any) => {
        return new TransactionInstruction({
          programId: new PublicKey(instruction.programId),

          keys: instruction.accounts.map((key: any) => ({
            pubkey: new PublicKey(key.pubkey),
            isSigner: key.isSigner,
            isWritable: key.isWritable,
          })),
          data: Buffer.from(instruction.data, 'base64'),
        })
      }

      const getAddressLookupTableAccounts = async (keys: string[]): Promise<AddressLookupTableAccount[]> => {
        const addressLookupTableAccountInfos = await lavarage.program.provider.connection.getMultipleAccountsInfo(keys.map(key => new PublicKey(key)))

        return addressLookupTableAccountInfos.reduce((acc, accountInfo, index) => {
          const addressLookupTableAddress = keys[index]
          if (accountInfo) {
            const addressLookupTableAccount = new AddressLookupTableAccount({
              key: new PublicKey(addressLookupTableAddress),
              state: AddressLookupTableAccount.deserialize(accountInfo.data),
            })
            acc.push(addressLookupTableAccount)
          }

          return acc
        }, new Array<AddressLookupTableAccount>())
      }

      const addressLookupTableAccounts: AddressLookupTableAccount[] = []

      addressLookupTableAccounts.push(...(await getAddressLookupTableAccounts(addressLookupTableAddresses)))

      const jupiterIxs = [
        ...setupInstructions.map(deserializeInstruction),
        deserializeInstruction(swapInstructionPayload),
        deserializeInstruction(cleanupInstruction),
        ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 0 }),
        SystemProgram.transfer({
          fromPubkey: lavarage.program.provider.publicKey,
          toPubkey: new PublicKey('96gYZGLnJYVFmbjzopPSU6QiEV5fGqZNyN9nmNhvrZU5'),
          lamports: 880000,
        }),
      ]

      const { blockhash } = await lavarage.program.provider.connection.getLatestBlockhash('finalized')

      const messageV0 = new TransactionMessage({
        payerKey: lavarage.program.provider.publicKey,
        recentBlockhash: blockhash,
        instructions: jupiterIxs,
      }).compileToV0Message(addressLookupTableAccounts)

      const tx = new VersionedTransaction(messageV0)

      const result = await sendTransaction(tx, addAlert, removeAlert, setConfirming, lavarage.program.provider, wallet)
      if (result) {
        trackSwapSuccessGA(tradeDetailsGA)
      }
    }
    catch (error) {
      console.error('Error during swapping:', error)
    }
    finally {
      setConfirming(false)
      setLoading(false)
      setPriceFreeze(0)
    }
  }, [lavarage?.program.provider, from, to, amount, slippage, addAlert, setLoading, setConfirming, removeAlert, wallet, tradeDetailsGA])

  return { swap }
}
