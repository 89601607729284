import { useLavarage } from '@/app/providers/LavarageProvider'
import { COLLECTION_KEY } from '@/config'
import { fetchAllDigitalAssetByOwner, mplTokenMetadata } from '@metaplex-foundation/mpl-token-metadata'
import { type PublicKey } from '@metaplex-foundation/umi'
import { createUmi } from '@metaplex-foundation/umi-bundle-defaults'
import { useWallet } from '@solana/wallet-adapter-react'
import { useCallback, useEffect, useMemo, useState } from 'react'

type Access = {
  hasLavaRockNFT: boolean
  isCheckingNFT: boolean
}
interface Collection {
  value?: {
    key: string
  }
}

export function useNftAccess(): Access {
  const { connected, publicKey } = useWallet()
  const lavarage = useLavarage()
  const [hasLavaRockNFT, setHasLavaRockNFT] = useState(false)
  const [isCheckingNFT, setIsCheckingNFT] = useState(false)

  const umi = useMemo(() => createUmi(lavarage.program.provider.connection).use(mplTokenMetadata()), [lavarage.program.provider.connection])
  const checkForLavaRockNFT = useCallback(async () => {
    if (!publicKey || !connected || !umi || !lavarage) return

    try {
      const assets = await fetchAllDigitalAssetByOwner(umi, lavarage.program.provider.publicKey as PublicKey)
      for (const asset of assets) {
        const currentCollection = asset.metadata.collection as Collection
        if (currentCollection?.value?.key === COLLECTION_KEY) {
          setHasLavaRockNFT(true)
          return
        }
      }
      setHasLavaRockNFT(false)
    }
    catch (error) {
      console.error('Error checking for Lava Rock NFT:', error)
      setHasLavaRockNFT(false)
    }
    finally {
      setIsCheckingNFT(false)
    }
  }, [publicKey, connected, umi, lavarage, setHasLavaRockNFT, setIsCheckingNFT])

  useEffect(() => {
    if (!publicKey || !connected) {
      setHasLavaRockNFT(false)
      return
    }
    setIsCheckingNFT(true)
    if (connected) {
      checkForLavaRockNFT()
    }
  }, [connected, publicKey, checkForLavaRockNFT, setHasLavaRockNFT, setIsCheckingNFT])

  return { hasLavaRockNFT, isCheckingNFT }
}
