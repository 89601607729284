import {
  BaseWalletAdapter,
  type EventEmitter,
  type SendTransactionOptions,
  type TransactionOrVersionedTransaction,
  type WalletName,
  WalletReadyState,
} from '@solana/wallet-adapter-base'
import { type Connection, type PublicKey, type SendOptions, type Transaction, type TransactionSignature, type TransactionVersion, type VersionedTransaction } from '@solana/web3.js'

interface GateWalletEvents {
  connect: (...args: unknown[]) => unknown
  disconnect: (...args: unknown[]) => unknown
  accountChanged: (newPublicKey: PublicKey) => unknown
}

interface GateWallet extends EventEmitter<GateWalletEvents> {
  isPhantom?: boolean
  publicKey?: { toBytes: () => Uint8Array }
  isConnected: boolean
  signTransaction: <T extends Transaction | VersionedTransaction>(transaction: T) => Promise<T>
  signAllTransactions: <T extends Transaction | VersionedTransaction>(transactions: T[]) => Promise<T[]>
  signAndSendTransaction: <T extends Transaction | VersionedTransaction>(transaction: T, options?: SendOptions) => Promise<{ signature: TransactionSignature }>
  signMessage: (message: Uint8Array) => Promise<{ signature: Uint8Array }>
  connect: () => Promise<void>
  disconnect: () => Promise<void>
}

interface GateWindow extends Window {
  gatewallet?: {
    solana?: GateWallet
  }
  solana?: GateWallet
}

declare const window: GateWindow

export const GateWalletName = 'Gate Wallet' as WalletName<'Gate Wallet'>

export class GateWalletAdapter extends BaseWalletAdapter {
  name = GateWalletName
  url = 'https://www.gate.io/web3'
  icon =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAP1BMVEUAAAAX5aIjVOYjVeYjVOYjU+UjVOYgUN8jVeYkVOcgWOcoWOcjVOYjVeYjVOcjVOYkVOYjU+YY5KIjVOYX5aFnl2IZAAAAE3RSTlMAn+/fn5CAEGBAICDPr3C/v1Bg8gPz/wAAAYNJREFUWMOtl9lywzAIRYkQ2mzZSen/f2uX6UzGt7IJVc87R4sRYDpjKdJXVqU/UYX1B/KTU9Ank+F+wRZUJwRtV8S7/JRAVKcEUecEq84Jos4Jks4JNp0TtDApYB0R7pLKDXkbxJdRdK+ZPnlHboMDDDYgX9GvCkQRrkQXAvMGYyaPoCiQiFwCxvXJJ6h4/uwURBAs5BQwHsApaLgBr+CBG3AK8B1Xt6AfXwA5BVjJ7l4BfgTxCyCLZwXlvwV29cEjuOs3XqK3ATJ8xp1Mjg18xUTKZHGsX53ESGWjfCS81O7soQ9qapzBfP0MDcG1AR50herZQDSKKpJ5tFpA6+tTCI9ni2RkMT6dPGptA3JUICwnYuVlkEGsiBA9twBEUGTRi2U2HRDrM7r2oL8p+MKQsEsqJckedATDiOAlLDDmedmwzjhJRpJZRGPYtliNRLeIRqpbiPHLYxDg/mFmNbkvdEVh7/JIk3ARnjLZtMLucKRGVoClkov2SH391vDapZze3Ac97ZDnSKHPHwAAAABJRU5ErkJggg=='
  supportedTransactionVersions: ReadonlySet<TransactionVersion> = new Set(['legacy', 0])
  standart = true
  wallet = null
  readyState = typeof window === 'undefined' || typeof document === 'undefined' ? WalletReadyState.Unsupported : WalletReadyState.Loadable
  connecting: boolean
  publicKey: PublicKey

  constructor() {
    super()
    this.wallet = window.gatewallet?.solana
  }

  disconnect(): Promise<void> {
    this.connecting = false
    return this.wallet?.disconnect()
  }

  connect() {
    if (this.wallet) {
      return this.wallet?.connect()
    }

    if (window && window.document && !window.gatewallet) {
      window.open('https://www.gate.io/web3', '_blank')
    }
  }
  async sendTransaction(
    transaction: TransactionOrVersionedTransaction<this['supportedTransactionVersions']>,
    connection: Connection,
    options?: SendTransactionOptions,
  ): Promise<string> {
    return this.wallet?.signAndSendTransaction(transaction, options).then(({ signature }) => {
      return signature
    })
  }
}
