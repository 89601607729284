import { type Price } from '@lavarage/entities'
import React, { createContext, useCallback, useContext, useState, type PropsWithChildren } from 'react'
import { SOL_ADDRESS } from '../../config.js'
import { useSolPriceQuery, useUsdcPriceQuery } from '../hooks/queries/usePriceQuery'
import { usePreferencesStore } from '../stores/usePreferencesStore.js'

interface PriceContextValue {
  priceUsdc: Record<string, Price>
  priceSol: Record<string, Price>
  solPriceLoading: boolean
  usdcPriceLoading: boolean
  solPriceError?: Error
  usdcPriceError?: Error
  refresh: () => Promise<void>
  setTokenIds: (tokenIds: string[]) => void
}

const PriceContext = createContext<PriceContextValue | undefined>(undefined)

export const PriceProvider: React.FC<PropsWithChildren<object>> = ({ children }) => {
  const baseToken = usePreferencesStore(state => state.baseToken)
  const [tokenIds, setTokenIds] = useState<string[]>([])

  const { solPrice, isLoading: solPriceLoading, error: solError, refetch: refetchSolPrice } = useSolPriceQuery({
    tokenIds: [SOL_ADDRESS],
    against: baseToken?.address,
  })

  const { usdcPrice, isLoading: usdcPriceLoading, error: usdcError, refetch: refetchUsdcPrice } = useUsdcPriceQuery({
    tokenIds: [SOL_ADDRESS, baseToken?.address, ...tokenIds],
  })

  const refresh = useCallback(async () => {
    refetchSolPrice()
    refetchUsdcPrice()
  }, [refetchSolPrice, refetchUsdcPrice])

  const contextValue = {
    priceUsdc: usdcPrice || {},
    priceSol: solPrice || {},
    solPriceLoading,
    usdcPriceLoading,
    solPriceError: solError,
    usdcPriceError: usdcError,
    refresh,
    setTokenIds,
  }

  return <PriceContext.Provider value={contextValue}>{children}</PriceContext.Provider>
}

export const usePriceContext = () => {
  const context = useContext(PriceContext)
  if (context === undefined) {
    throw new Error('usePriceContext must be used within a PriceProvider')
  }
  return context
}
