import { IconCopy } from '@/assets/svgs'
import { useWallet } from '@solana/wallet-adapter-react'
import clsx from 'clsx'
import { forwardRef, type HTMLAttributes } from 'react'

type Props = HTMLAttributes<HTMLDivElement> & {
  onChangeWallet?: () => void
  onDisconnect?: () => void
}

export const UserMenu = forwardRef<HTMLDivElement, Props>(({
  className,
  onChangeWallet,
  onDisconnect,
  ...props
}, ref) => {
  const { publicKey } = useWallet()
  const userAddress = publicKey?.toBase58()
  const shortKey = `${userAddress?.slice(0, 4)}..${userAddress?.slice(-4)}`

  const onCopy = () => {
    if (!userAddress) return
    navigator.clipboard.writeText(userAddress)
  }

  return (
    <div
      {...props}
      ref={ref}
      className={clsx(className, 'bg-vibrant flex flex-col items-end gap-2 rounded-lg px-[10px] pb-4 pt-14 text-sm')}
    >
      {publicKey && (
        <button className='btn-icon flex items-center gap-2' onClick={onCopy}>
          <span>{shortKey}</span>
          <IconCopy/>
        </button>
      )}
      {onChangeWallet && (
        <button className='btn-icon' onClick={onChangeWallet}>Change Wallet</button>
      )}
      {onDisconnect && (
        <button className='btn-icon text-alt' onClick={onDisconnect}>Disconnect</button>
      )}
    </div>
  )
})
