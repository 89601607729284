import '@/app/styles.css'
import '@solana/wallet-adapter-react-ui/styles.css'

import { useTokensQuery } from '@/app/hooks/queries'
import { NotFoundPage } from '@/app/pages/404/NotFoundPage'
import { MainPage } from '@/app/pages/main/MainPage'
import { ClosedPositions, OpenPositions } from '@/app/pages/positions/components'
import { PositionsPage } from '@/app/pages/positions/PositionsPage'
import { RewardsPage } from '@/app/pages/rewards/RewardsPage'
import { ClaimWidget, StakeUnstakeWidget } from '@/app/pages/stake/components'
import { StakePage } from '@/app/pages/stake/StakePage'
import { LavarageProvider } from '@/app/providers/LavarageProvider.js'
import { StendingProvider } from '@/app/providers/StendingProvider.js'
import { useWallet } from '@solana/wallet-adapter-react'
import { useEffect } from 'react'
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom'
import { referralService } from '../services/ReferralService.js'
import { STAKE_ENABLED } from './app.config.js'
import { PositionsProvider } from './providers/PositionsProvider.js'
import { PriceProvider } from './providers/PriceProvider.js'
import { TephrasProvider } from './providers/TephrasProvider.js'

export function App() {
  const [searchParams] = useSearchParams()
  const { publicKey } = useWallet()
  const { tokens, refetch } = useTokensQuery()
  const referralCode = searchParams.get('ref') ?? searchParams.get('referrer')

  useEffect(() => {
    refetch()
  }, [refetch])

  useEffect(() => {
    if (publicKey) {
      referralService.generateReferralCode(publicKey.toString())
      if (referralCode) {
        referralService.registerReferee(publicKey.toBase58(), referralCode, false)
      }
    }
  }, [publicKey])

  return (
    <LavarageProvider>
      <PositionsProvider userAddress={publicKey?.toBase58()}>
        <TephrasProvider userAddress={publicKey?.toBase58()}>
          <PriceProvider>
            <StendingProvider>
              <Routes>
                <Route element={<MainPage/>} path='/'/>
                <Route element={<PositionsPage tokens={tokens}/>} path='/positions'>
                  <Route index element={<Navigate replace to={`/positions/open?${searchParams.toString()}`}/>}/>
                  <Route element={<OpenPositions/>} path='open'/>
                  <Route element={<ClosedPositions/>} path='closed'/>
                </Route>
                <Route element={<RewardsPage/>} path='/rewards'/>
                {STAKE_ENABLED && (
                  <Route element={<StakePage/>} path='/stake'>
                    <Route index element={<StakeUnstakeWidget mode='stake'/>}/>
                    <Route element={<StakeUnstakeWidget mode='unstake'/>} path='unstake'/>
                    <Route element={<ClaimWidget/>} path='claim'/>
                  </Route>
                )}
                <Route element={<NotFoundPage/>} path='*'/>
              </Routes>
            </StendingProvider>
          </PriceProvider>
        </TephrasProvider>
      </PositionsProvider>
    </LavarageProvider>
  )
}
