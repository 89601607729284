import { useStendingService } from '@/app/providers/StendingProvider'
import { useQuery } from '@tanstack/react-query'

const REFETCH_INTERVAL = 60_000

export const useUnstakeAccountsQuery = () => {
  const stendingService = useStendingService()

  if (!stendingService) {
    throw new Error('useUnstakeAccountsQuery requires a StendingService')
  }

  const {
    data: unstakeAccounts,
    refetch,
    isPending,
    error,
  } = useQuery({
    queryKey: ['unstakeAccounts'],
    queryFn: () => stendingService.getUnstakeAccounts(),
    refetchInterval: REFETCH_INTERVAL,
  })

  return {
    unstakeAccounts,
    fetchUnstakeAccounts: refetch,
    isLoading: isPending,
    error,
  }
}
