import { useEffect, useRef, type HTMLAttributes } from 'react'
import { clsxm } from '../helpers/clsxm.js'

type Props = HTMLAttributes<HTMLDivElement> & {
  walletAddress: string
}

function createIdenticon(key: string, size: number, gridSize: number, color: string) {
  const cellSize = size / gridSize

  const rng = (seed: string) => {
    let hash = 0
    for (let i = 0; i < seed.length; i++) {
      hash = seed.charCodeAt(i) + ((hash << 5) - hash)
      hash = hash & hash
    }
    return Math.abs(hash)
  }

  const random = rng(key)

  const svgNS = 'http://www.w3.org/2000/svg'
  const svgElement = document.createElementNS(svgNS, 'svg')
  svgElement.setAttribute('width', size.toString())
  svgElement.setAttribute('height', size.toString())
  svgElement.setAttribute('viewBox', `0 0 ${size} ${size}`)

  const backgroundElement = document.createElementNS(svgNS, 'rect')
  backgroundElement.setAttribute('width', size.toString())
  backgroundElement.setAttribute('height', size.toString())
  backgroundElement.setAttribute('fill', 'transparent')
  svgElement.appendChild(backgroundElement)

  for (let y = 0; y < gridSize; y++) {
    for (let x = 0; x < Math.ceil(gridSize / 2); x++) {
      if ((random >> (x + y * Math.ceil(gridSize / 2))) & 1) {
        const rect = document.createElementNS(svgNS, 'rect')
        rect.setAttribute('width', cellSize.toString())
        rect.setAttribute('height', cellSize.toString())
        rect.setAttribute('fill', color)
        rect.setAttribute('x', (x * cellSize).toString())
        rect.setAttribute('y', (y * cellSize).toString())
        svgElement.appendChild(rect)

        if (x !== gridSize - x - 1) {
          const mirrorRect = document.createElementNS(svgNS, 'rect')
          mirrorRect.setAttribute('width', cellSize.toString())
          mirrorRect.setAttribute('height', cellSize.toString())
          mirrorRect.setAttribute('fill', color)
          mirrorRect.setAttribute('x', ((gridSize - x - 1) * cellSize).toString())
          mirrorRect.setAttribute('y', (y * cellSize).toString())
          svgElement.appendChild(mirrorRect)
        }
      }
    }
  }

  return svgElement
}

export function Identicon({ className, walletAddress, ...props }: Props) {
  const rootRef = useRef<HTMLDivElement>(null)
  const identicon = createIdenticon(walletAddress, 20, 7, '#FFFFFF')

  useEffect(() => {
    rootRef.current?.appendChild(identicon)

    return () => {
      rootRef.current?.removeChild(identicon)
    }
  }, [walletAddress])

  return (
    <div {...props} ref={rootRef} className={clsxm(className, 'flex items-center justify-center overflow-hidden bg-[#9264D7]')}/>
  )
}
