import type { PublicKey } from '@solana/web3.js'
import clsx from 'clsx'
import { type HTMLAttributes } from 'react'
import { Button } from './buttons/Button.js'
import { Identicon } from './Identicon.js'

type Props = HTMLAttributes<HTMLButtonElement> & {
  publicKey?: PublicKey
}

export function ConnectButton({ className, publicKey, ...props }: Props) {
  return publicKey ? (
    <button {...props} className={clsx(className, 'btn-icon')}>
      <Identicon className='relative size-[30px] rounded-full' walletAddress={publicKey.toBase58()}/>
    </button>
  ) : (
    <Button {...props} className={className} color='alt' size='adaptive'>Connect</Button>
  )
}
