import { useEffect, type HTMLAttributes, type PropsWithChildren } from 'react'
import { clsxm } from '../helpers/clsxm'

type Props = HTMLAttributes<HTMLDivElement> & PropsWithChildren

export function Modal({ className, children, ...props }: Props) {
  useEffect(() => {
    const windowHeight = window.innerHeight
    const pageContentHeight = document.body.scrollHeight
    const headerElement = document.querySelector('header')

    if (pageContentHeight > windowHeight) {
      document.documentElement.style.setProperty('scrollbar-gutter', 'stable')
      const htmlWidth = document.documentElement.offsetWidth
      headerElement?.style.setProperty('max-width', `${htmlWidth}px`)
    }

    return () => {
      document.documentElement.style.scrollbarGutter = 'auto'
      headerElement?.style.removeProperty('max-width')
    }
  }, [])

  return <div {...props} className={clsxm('modal modal-open', className)}>{children}</div>
}
