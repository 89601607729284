import { log, warn } from '@lavarage/utils'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { createContext, useCallback, type PropsWithChildren } from 'react'
import { API_HOST } from '../app.config.js'

type Props = PropsWithChildren<{
  userAddress?: string
}>

type ContextValue = {
  data?: {
    borrowedAmount: number
    initialMargin: number
    interestPaid: number
    openPositionSize: number
    profit: number
    repaidPositionSize: number
    soldPositionSize: number
    social: number
    total: number
  }
  error?: Error
  isLoading: boolean
  refresh: () => void
}

async function fetchTephras(userAddress?: string) {
  if (!userAddress) {
    log('Fetching tephras...', 'SKIP', 'No user address provided')

    return {
      borrowedAmount: 0,
      initialMargin: 0,
      interestPaid: 0,
      openPositionSize: 0,
      profit: 0,
      repaidPositionSize: 0,
      soldPositionSize: 0,
      social: 0,
      total: 0,
    }
  }

  try {
    const { data } = await axios.get(`/tephras?user=${userAddress}`, { baseURL: API_HOST })
    const tephras = {
      borrowedAmount: Number(data.borrowedAmount ?? 0),
      initialMargin: Number(data.initialMargin ?? 0),
      interestPaid: Number(data.interestPaid ?? 0),
      openPositionSize: Number(data.openPositionSize ?? 0),
      profit: Number(data.profit ?? 0),
      repaidPositionSize: Number(data.repaidPositionSize ?? 0),
      soldPositionSize: Number(data.soldPositionSize ?? 0),
      social: Number(data.social ?? 0),
      total: Number(data.total ?? 0),
    }

    log('Fetching tephras...', 'OK', tephras)

    return tephras
  }
  catch (err) {
    warn('Fetching tephras...', 'ERR', `${err}`)

    throw err
  }
}

export const TephrasContext = createContext<ContextValue>({
  isLoading: false,
  refresh: () => {},
})

export function TephrasProvider({ children, userAddress }: Props) {
  const { data, error, isPending, refetch } = useQuery({
    queryKey: ['tephras', userAddress],
    queryFn: () => fetchTephras(userAddress),
  })

  const refresh = useCallback(() => refetch(), [refetch])

  return (
    <TephrasContext.Provider
      value={{
        data,
        error: error ?? undefined,
        isLoading: isPending,
        refresh,
      }}
    >
      {children}
    </TephrasContext.Provider>
  )
}
