import { IconRewards } from '@/assets'
import { useWallet } from '@solana/wallet-adapter-react'
import clsx from 'clsx'
import type { HTMLAttributes } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { trackNavigationClickGA } from '../../analytics.js'
import { clsxm } from '../../helpers/clsxm.js'
import { useTephras } from '../../hooks/useTephras.js'
import { Conditional } from '../Conditional.js'
import { LSpan } from '../LSpan.js'

type Props = HTMLAttributes<HTMLButtonElement> & {
  alwaysShowsTephras?: boolean
}

export const RewardsButton = ({ className, alwaysShowsTephras, ...props }: Props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { connected } = useWallet()
  const { isLoading, data } = useTephras()

  return (
    <button
      {...props}
      className={clsx(className, 'desk:hover:opacity-70 relative mr-[10px] flex cursor-pointer items-center justify-center gap-[5px] transition')}
      onClick={() => {
        trackNavigationClickGA('Rewards Page')
        navigate({ pathname: '/rewards', search: location.search })
      }}
    >
      <IconRewards/>
      <div
        className={clsxm('desk:flex text-alt items-center justify-center', {
          'hidden': !alwaysShowsTephras,
          'text-gradient': !isLoading,
        })}
      >
        <Conditional if={connected}>
          <LSpan className='font-bold' isLoading={isLoading} text={data?.total.toLocaleString()} textSize={16}/>
        </Conditional>
        <Conditional if={!connected}>
          <span className='desk:inline-block hidden'>Rewards</span>
        </Conditional>
      </div>
    </button>
  )
}
