import { type Program } from '@coral-xyz/anchor'
import { Pool } from '@lavarage/entities'
import type { LavarageIdl } from '@lavarage/idls'

export class PoolService {
  constructor(private program: Program<typeof LavarageIdl>) {}

  async getPools(): Promise<Pool[]> {
    const pools = await this.program.account.pool.all()
    return pools.map(Pool.factory)
  }

  async getPoolByKey(poolKey: string) {
    const pools = await this.program.account.pool.all()
    const pool = pools.find(({ publicKey }) => publicKey.toBase58() === poolKey)

    return Pool.factory(pool)
  }
}
