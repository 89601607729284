import { LIQUIDATION_LTV } from '@/config'
import { coreService } from '@/services/CoreService'
import { formatTimestamp, tokenPrecision } from '@/utils'
import { type Position } from '@lavarage/entities'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'
import BigNumber from 'bignumber.js'

export function calculatePositionMetrics(position: Position, solUSD = 0, collateralUSD = 0, timezone: string = 'UTC') {
  const calculateCurrentLTV = (loanValue: BigNumber, baseAmount: BigNumber): BigNumber => {
    if (baseAmount.isZero() || collateralUSD === 0) return BigNumber(NaN)
    return loanValue.times(solUSD).dividedBy(baseAmount.times(collateralUSD)).times(100)
  }
  const currentPrice = coreService.entryPrice({ quotePriceUSD: solUSD, basePriceUSD: collateralUSD })
  const liquidationPrice = coreService.liquidationPrice({
    borrowedAmount: position.borrowedAmount,
    interestAccrued: position.interestAccrued.dividedBy(LAMPORTS_PER_SOL).toNumber(),
    collateralValue: position.collateralSize.toNumber(),
    liquidationLTV: LIQUIDATION_LTV,
  })
  const loanValue = position.borrowedAmount.plus(position.interestAccrued)
  const currentLTV = calculateCurrentLTV(loanValue, position.collateralSize)
  const priceChangePercentage = BigNumber(currentPrice).minus(position.entryPrice).div(position.entryPrice).times(100)
  const averageDailyInterest = position.interestAccrued.div(position.durationDays).div(position.borrowedAmount).times(100).absoluteValue()
  const positionSize = position.collateralSize.times(collateralUSD).div(solUSD)
  const closingPositionSize = position.positionSize
  const pnl = positionSize.minus(position.initialPositionSize).minus(position.interestAccrued)
  const finalPnl = closingPositionSize.minus(position.initialPositionSize).minus(position.interestAccrued)
  const sellFromPosition = tokenPrecision(position.collateralSize.times(currentPrice).toNumber(), 9)
  const totalOwing = position.borrowedAmount.plus(position.interestAccrued)
  const transferredToWallet = position.collateralSize.times(currentPrice).minus(totalOwing)
  const roi = pnl.div(position.initialMargin).times(100)
  const finalRoi = finalPnl.div(position.initialMargin).times(100)
  const currentPositionSize = position.collateralSize.times(collateralUSD).div(solUSD)
  const closingChangePercentage = closingPositionSize.minus(position.initialPositionSize).div(position.initialPositionSize).times(100)
  const startDate = formatTimestamp(position.openDate.getTime() / 1000, timezone)
  const closeDate = position.state === 'closed' ? formatTimestamp(position.closeDate!.getTime() / 1000, timezone) : undefined

  return {
    currentPrice,
    liquidationPrice,
    currentLTV,
    roi,
    finalRoi,
    priceChangePercentage,
    pnl,
    finalPnl,
    averageDailyInterest,
    positionSize,
    sellFromPosition,
    transferredToWallet,
    currentPositionSize,
    closingChangePercentage,
    startDate,
    closeDate,
  }
}

export const getPositionPnl = ({ position, pnl, finalPnl }: { position: Position; pnl: BigNumber; finalPnl: BigNumber }) => {
  switch (position.status) {
    case 'active':
      return pnl.toNumber()
    case 'sold':
    case 'recalled':
      return finalPnl.toNumber()
    case 'liquidated':
      return position.initialMargin.multipliedBy(-1).toNumber()
    case 'repaid':
      return 0
    default:
      return undefined
  }
}

export const getPositionRoi = ({ position, roi, finalRoi }: { position: Position; roi: BigNumber; finalRoi: BigNumber }) => {
  switch (position.status) {
    case 'active':
      return roi.toNumber()
    case 'sold':
    case 'recalled':
      return finalRoi.toNumber()
    case 'liquidated':
      return -100
    case 'repaid':
      return 0
    default:
      return undefined
  }
}
