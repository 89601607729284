import * as Sentry from '@sentry/react'
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react'
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui'
import { PhantomWalletAdapter, SolflareWalletAdapter } from '@solana/wallet-adapter-wallets'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { StrictMode, useEffect } from 'react'
import * as ReactDOM from 'react-dom/client'
import { Route, BrowserRouter as Router, Routes, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import { AnalyticsProvider } from 'use-analytics'
import { analytics } from './app/analytics.js'
import { ENABLE_REACT_QUERY_DEVTOOLS, SENTRY_DSN, SENTRY_PROPAGATION_TARGETS } from './app/app.config.js'
import { App } from './app/App.js'
import { BackpackWalletAdapter, GateWalletAdapter, OkxWalletAdapter } from './app/wallet-adapters/index.js'
import { SOLANA_RPC, SOLANA_WS_RPC } from './config.js'

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: SENTRY_PROPAGATION_TARGETS,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

const queryClient = new QueryClient()

function AppWithProviders() {
  const solNetwork = SOLANA_RPC
  const endpoint = solNetwork
  const wallets = [new PhantomWalletAdapter(), new BackpackWalletAdapter(), new SolflareWalletAdapter(), new GateWalletAdapter(), new OkxWalletAdapter()]

  return (
    <AnalyticsProvider instance={analytics}>
      <ConnectionProvider config={{ wsEndpoint: SOLANA_WS_RPC }} endpoint={endpoint}>
        <QueryClientProvider client={queryClient}>
          {ENABLE_REACT_QUERY_DEVTOOLS && <ReactQueryDevtools initialIsOpen={false}/>}
          <WalletProvider autoConnect wallets={wallets}>
            <WalletModalProvider>
              <App/>
            </WalletModalProvider>
          </WalletProvider>
        </QueryClientProvider>
      </ConnectionProvider>
    </AnalyticsProvider>
  )
}
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render((
  <StrictMode>
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>} onError={console.warn}>
      <Router>
        <SentryRoutes>
          <Route element={<AppWithProviders/>} path='*'/>
        </SentryRoutes>
      </Router>
    </Sentry.ErrorBoundary>
  </StrictMode>
))
