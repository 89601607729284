import { trackTokenSearchGA, trackTokenSelectGA } from '@/app/analytics'
import { NEW_COIN_ADDRESSES_ARRAY } from '@/app/app.config'
import { CoinButton, Conditional, For, TokenImage } from '@/app/components'
import { Modal } from '@/app/components/Modal'
import { clsxm } from '@/app/helpers/clsxm'
import { usePoolsQuery, useTokenLiquidity, useTokensQuery, useTopCoinsQuery } from '@/app/hooks/queries'
import { usePreferencesStore } from '@/app/stores'
import { IconSearch, IconX } from '@/assets/svgs'
import { type TokenInfo } from '@/services'
import { getRelatedPool } from '@/utils/getRelatedPool'
import Fuse from 'fuse.js'
import React, { type CSSProperties, type ChangeEvent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FixedSizeList as List } from 'react-window'
import { useShallow } from 'zustand/react/shallow'

enum TokenListFilters {
  ALL = 'all',
  MARGIN = 'margin',
}

export const TokenList = memo(() => {
  const [inputValue, setInputValue] = useState<string>('')
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [hasInteracted, setHasInteracted] = useState<boolean>(false)
  const [tokensFilter, setTokensFilter] = useState<TokenListFilters>(TokenListFilters.ALL)
  const [baseToken, setBaseToken] = usePreferencesStore(useShallow(s => [s.baseToken, s.setBaseToken]))
  const { pools } = usePoolsQuery()
  const { tokens = [], refetch: fetchTokens, isLoading: areTokensLoading } = useTokensQuery()
  const { topCoins = [] } = useTopCoinsQuery(tokens)
  const { refetchWithToken: updateTokenLiquidity } = useTokenLiquidity()
  const wrapperRef = useRef<HTMLDivElement>(null)
  const listRef = useRef<List>(null)

  const scrollModalToTop = () => {
    if (listRef.current) {
      listRef.current.scrollTo(0)
    }
  }

  const fuse = useMemo(() => {
    const options = {
      keys: ['name', 'symbol'],
      threshold: 0.3,
    }
    return new Fuse(tokens, options)
  }, [tokens])

  useEffect(() => {
    const preventTouchMove = (e: TouchEvent) => {
      if (wrapperRef.current && e.target instanceof Node && wrapperRef.current.contains(e.target)) {
        return
      }
      e.preventDefault()
    }

    if (isModalVisible) {
      document.addEventListener('touchmove', preventTouchMove, {
        passive: false,
      })
      document.body.style.overflow = 'hidden'
    }
    else {
      document.removeEventListener('touchmove', preventTouchMove)
      document.body.style.overflow = ''
    }
    return () => {
      document.removeEventListener('touchmove', preventTouchMove)
      document.body.style.overflow = ''
    }
  }, [isModalVisible])

  useEffect(() => {
    if (isModalVisible) {
      fetchTokens()
    }
  }, [isModalVisible, fetchTokens])

  const filteredTokens: TokenInfo[] = useMemo(() => {
    if (!hasInteracted || inputValue === '') {
      return tokensFilter === TokenListFilters.MARGIN ? tokens.filter(t => t.whitelisted) : tokens
    }

    const fuseSearchResult = fuse.search(inputValue)

    const exactMatches = tokens.filter(token => token.address === inputValue)
    const fuseMatches = fuseSearchResult.filter(({ item }) => item.address !== inputValue).map(({ item }) => item)

    const out = [...exactMatches, ...fuseMatches].sort((a, b) => (a.whitelisted === b.whitelisted ? 0 : a.whitelisted ? -1 : 1))

    return tokensFilter === TokenListFilters.ALL ? out : out.filter((t: TokenInfo) => t.whitelisted)
  }, [tokens, tokensFilter, inputValue, hasInteracted, fuse])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    setHasInteracted(true)
    scrollModalToTop()
  }

  const handleTokenClick = useCallback(
    (selectedToken: TokenInfo) => {
      trackTokenSelectGA(selectedToken.symbol)
      setInputValue('')
      updateTokenLiquidity(selectedToken)
      setBaseToken(selectedToken)
      setIsModalVisible(false)
      setHasInteracted(false)
    },
    [setBaseToken, updateTokenLiquidity],
  )

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (isModalVisible && wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setInputValue('')
        setIsModalVisible(false)
        setHasInteracted(false)
      }
    },
    [isModalVisible],
  )

  const toggleModal = () => {
    setInputValue('')
    setIsModalVisible(prev => !prev)
  }

  useEffect(() => {
    if (isModalVisible) {
      setHasInteracted(false)
      fetchTokens()
    }
  }, [isModalVisible, fetchTokens])

  useEffect(() => {
    if (isModalVisible) {
      document.addEventListener('mousedown', handleClickOutside as EventListener, true)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside as EventListener, true)
    }
  }, [isModalVisible, handleClickOutside])

  useEffect(() => {
    if (!tokens.length || baseToken) return

    // WIF
    const defaultToken = tokens.find(t => t.address === 'EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm')

    const relatedPool = getRelatedPool(defaultToken, pools)
    setBaseToken({
      ...defaultToken,
      availableLiquidity: relatedPool?.nodeWallet.totalFunds?.toNumber() || 0,
      dailyInterest: (relatedPool?.interestRate || 0) / 365,
    })
  }, [tokens.length, baseToken, setBaseToken, pools, updateTokenLiquidity, fetchTokens, tokens])

  const prettyAddress = (publicKey: string) => {
    return `${publicKey.slice(0, 4)}..${publicKey.slice(-3)}`
  }

  const quantityOfMarginTokens = useMemo(() => tokens.filter(t => t.whitelisted).length, [tokens])

  const Row = memo(({ index, style }: { index: number; style: CSSProperties }) => {
    const token = filteredTokens[index]
    return (
      <div key={`token${index}`} className='px-[10px] last:pb-[64px]' style={style}>
        <div
          className={clsxm(
            'desk:hover:bg-opacity-20 mt-[10px] flex h-[44px] w-full cursor-pointer items-center justify-between rounded-lg bg-opacity-10 pt-[-10px] text-base transition',
            {
              'bg-alt text-alt': token.whitelisted,
              'bg-main text-main': !token.whitelisted,
            },
          )}
          style={{ cursor: 'pointer' }}
          onClick={() => handleTokenClick(token)}
        >
          <div className='flex min-w-0 flex-1 items-center px-2'>
            <TokenImage altName={token.name} logoURI={token.logoURI} tag={token?.isNewCoin ? 'NEW' : token?.isTopTrendingCoins ? 'HOT' : null}/>
            <span className='min-w-0 truncate px-[5px]'>{token.name}</span>
            <span
              className='bg-main text-main desk:hover:bg-opacity-10 flex h-[24px] max-w-[220px] cursor-pointer items-center rounded-xl bg-opacity-5 px-[10px] text-center text-xs'
              title={token.address.toLowerCase()}
              onClick={() => window.open(`https://birdeye.so/token/${token.address}?chain=solana`, '_blank', 'noopener,noreferrer')}
            >
              {prettyAddress(token.address.toLowerCase())}
            </span>
          </div>
          <span className='shrink-0 px-2 uppercase'>{token.symbol}</span>
        </div>
      </div>
    )
  })

  Row.displayName = 'TokenList:Row'

  const dragScrollRef = useRef<HTMLDivElement>(null)
  const isDragging = useRef(false)
  const startX = useRef(0)
  const scrollLeft = useRef(0)
  const dragStarted = useRef(false)

  const onMouseDown = (e: React.MouseEvent) => {
    isDragging.current = true
    startX.current = e.pageX - (dragScrollRef.current?.offsetLeft || 0)
    scrollLeft.current = dragScrollRef.current?.scrollLeft || 0
    dragScrollRef.current!.style.cursor = 'grabbing'
    dragStarted.current = false

    document.addEventListener('mousemove', onMouseMove, true)
    document.addEventListener('mouseup', onMouseUp, true)
    e.stopPropagation()
  }

  const onMouseUp = (e: MouseEvent) => {
    isDragging.current = false
    dragScrollRef.current!.style.cursor = 'grab'

    document.removeEventListener('mousemove', onMouseMove, true)
    document.removeEventListener('mouseup', onMouseUp, true)
    e.stopPropagation()
  }

  const onMouseMove = (e: MouseEvent) => {
    if (!isDragging.current) return
    e.preventDefault()
    const x = e.pageX - (dragScrollRef.current?.offsetLeft || 0)
    const walk = (x - startX.current) * 2
    if (dragScrollRef.current) {
      dragScrollRef.current.scrollLeft = scrollLeft.current - walk
    }
    dragStarted.current = true
    e.stopPropagation()
  }

  const handleCoinButtonClick = (token: TokenInfo) => {
    if (!dragStarted.current) {
      handleTokenClick(token)
    }
  }

  return (
    <div className='w-full'>
      <CoinButton className='cursor-pointer' loading={!baseToken?.address} token={baseToken} onClick={toggleModal}/>
      <Conditional if={isModalVisible}>
        <Modal>
          <div ref={wrapperRef} className='bg-vibrant modal-box h-[540px] max-w-[380px] overflow-x-hidden px-0 pb-[20px] pt-[10px] text-xs'>
            <div className='mb-[10px] flex items-center justify-between px-[10px]'>
              <div className='bg-main desk:hover:bg-opacity-10 flex grow items-center rounded-lg bg-opacity-5 text-white transition'>
                <div className='ml-[5px]'>
                  <IconSearch/>
                </div>
                <input
                  className='h-[36px] w-full rounded-[8px] bg-transparent p-2 text-base outline-none placeholder:text-white placeholder:text-opacity-40'
                  placeholder='Search...'
                  type='text'
                  value={inputValue}
                  onBlur={() => inputValue !== '' && trackTokenSearchGA(inputValue)}
                  onChange={handleInputChange}
                />
              </div>
              <button className='ml-[5px]' onClick={toggleModal}>
                <IconX className='btn-icon'/>
              </button>
            </div>

            <div
              ref={dragScrollRef}
              className='hide-scrollbar user-select-none -mt-2 mb-5 flex h-[52px] gap-2 overflow-x-auto px-[10px] pt-2'
              style={{ cursor: 'grab' }}
              onMouseDown={onMouseDown}
            >
              <Conditional if={topCoins.length}>
                <For of={topCoins}>
                  {(recentToken, index) => {
                    const token = tokens.find(t => t?.address === recentToken?.address)
                    const topCoin = topCoins.find(coin => coin.address === token?.address)
                    if (topCoin) {
                      token.isTopTrendingCoins = true
                    }
                    const isNewCoin = NEW_COIN_ADDRESSES_ARRAY.includes(token?.address)
                    if (isNewCoin) {
                      token.isNewCoin = true
                    }

                    return (
                      <CoinButton
                        key={index + token?.symbol}
                        className='bg-alt desk:hover:bg-opacity-20 cursor-col-resize bg-opacity-10 transition'
                        token={recentToken}
                        onClick={() => handleCoinButtonClick(token)}
                      />
                    )
                  }}
                </For>
              </Conditional>
              <Conditional if={!topCoins.length}>
                <div className='flex w-full items-center justify-center'>
                  <span className='loading loading-spinner loading-md'/>
                </div>
              </Conditional>
            </div>

            <div className='tabs-boxed tabs bg-vibrant mx-auto mb-2 flex h-9 w-[250px] rounded-lg p-1' role='tablist'>
              <div
                className={clsxm(
                  'flex h-full w-1/2 items-center justify-center rounded-lg',
                  {
                    'cursor-default bg-alt bg-opacity-10 text-alt transition': tokensFilter === TokenListFilters.ALL,
                  },
                  {
                    'cursor-pointer transition desk:hover:opacity-70': tokensFilter === TokenListFilters.MARGIN,
                  },
                )}
                role='tab'
                onClick={() => {
                  setTokensFilter(TokenListFilters.ALL)
                  scrollModalToTop()
                }}
              >
                All
              </div>
              <div
                className={clsxm(
                  'flex h-full w-1/2 items-center justify-center rounded-lg',
                  {
                    'cursor-default bg-alt bg-opacity-10 text-alt transition': tokensFilter === TokenListFilters.MARGIN,
                  },
                  {
                    'cursor-pointer transition desk:hover:opacity-70': tokensFilter === TokenListFilters.ALL,
                  },
                )}
                role='tab '
                onClick={() => {
                  setTokensFilter(TokenListFilters.MARGIN)
                  scrollModalToTop()
                }}
              >
                <span>Leverage</span>
                <Conditional if={quantityOfMarginTokens}>
                  <span
                    className={clsxm('ml-[5px] rounded-full px-[5px] py-[2.5px] text-xs transition', {
                      'bg-alt text-main': tokensFilter === TokenListFilters.MARGIN,
                      'bg-main text-background': tokensFilter !== TokenListFilters.MARGIN,
                    })}
                  >
                    {quantityOfMarginTokens < 999 ? quantityOfMarginTokens : '999+'}
                  </span>
                </Conditional>
              </div>
            </div>
            <div className='h-max-[328px] w-full bg-transparent'>
              <Conditional if={filteredTokens.length}>
                <List ref={listRef} className='hide-scrollbar h-fit max-h-[328px]' height={328} itemCount={filteredTokens.length} itemSize={50} width='auto'>
                  {Row}
                </List>
              </Conditional>
              <Conditional if={!filteredTokens.length && areTokensLoading}>
                <div className='flex h-[328px] w-full items-center justify-center'>
                  <span className='loading loading-spinner loading-lg my-10'/>
                </div>
              </Conditional>
              <Conditional if={!filteredTokens.length && !areTokensLoading}>
                <div className='flex h-[328px] w-full items-center justify-center'>
                  <span className='text-[14px]'>No results found, please revise your search.</span>
                </div>
              </Conditional>
            </div>
            <div className='flex justify-center pt-[10px]'>
              <span>
                Missing a token on this list?
                {' '}
                <a href='https://discord.com/invite/lavarage' rel='noopener noreferrer' target='_blank'>
                  <span className='text-alt border-b hover:border-b-0'>Let us know on Discord!</span>
                </a>
              </span>
            </div>
          </div>
        </Modal>
      </Conditional>
    </div>
  )
})
