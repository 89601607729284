import { type PropsWithChildren } from 'react'

type ConditionalProps = PropsWithChildren<{
  if: boolean | (() => boolean) | unknown
}>

export const Conditional = ({ if: condition, children }: ConditionalProps) => {
  const shouldRender = Boolean(typeof condition === 'function' ? condition() : condition)
  return shouldRender ? <>{children}</> : null
}
