import { usePreferencesStore } from '@/app/stores'
import { formatSol } from '@/utils/formatters'
import { memo, type HTMLAttributes } from 'react'
import { Conditional } from '../../../components/Conditional.js'
import { LabelledValue } from '../../../components/LabelledValue.js'
import { clsxm } from '../../../helpers/clsxm.js'

type Props = HTMLAttributes<HTMLDivElement> & {
  borrowedAmount: number
  positionSize: number
  entryPrice: number
  liquidationPrice: number
  isLoading: boolean
  hasLavaRockNFT: boolean
  localFormatCurrency: (val: number, decimals?: number) => string | null
}

export const LoanSummary = memo(({
  className,
  borrowedAmount,
  positionSize,
  entryPrice,
  liquidationPrice,
  isLoading,
  hasLavaRockNFT,
  localFormatCurrency,
  ...props
}: Props) => {
  const baseToken = usePreferencesStore(state => state.baseToken)

  return (
    <div {...props} className={clsxm(className, 'border-main mx-9 flex flex-col rounded-lg border border-opacity-5 p-3')}>
      <div className='mb-[10px] text-center text-xs opacity-40'>LOAN SUMMARY</div>
      <div className='flex flex-col gap-[5px] text-sm'>
        <LabelledValue
          isLoading={isLoading}
          label='Borrowed Amount'
          value={localFormatCurrency(borrowedAmount)}
        />
        <LabelledValue
          hintInternalComponent={
            <>
              <Conditional if={hasLavaRockNFT}>
                <span>Fee reduced for </span>
                <span><a className='underline' href='https://lavarage.gitbook.io/lavarage/community/lava-rock-alpha' rel='noreferrer' target='_blank'>Lava Rock</a></span>
                <span> holder!</span>
                <p className='mb-5'/>
              </Conditional>
              <Conditional if={!hasLavaRockNFT}>
                <span>Own a </span>
                <span><a className='underline' href='https://lavarage.gitbook.io/lavarage/community/lava-rock-alpha' rel='noreferrer' target='_blank'>Lava Rock</a></span>
                <span> to reduce your fees!</span>
                <p className='mb-5'/>
              </Conditional>
              <span>More details on </span>
              <span><a className='underline' href='https://lavarage.gitbook.io/lavarage/platform/fee' rel='noreferrer' target='_blank'>fees</a></span>
            </>
          }
          isLoading={isLoading}
          label='Position Size'
          value={localFormatCurrency(positionSize)}
        />
        <LabelledValue
          isLoading={isLoading}
          label='Daily Interest'
          value={`${formatSol(baseToken?.dailyInterest || 0)}%`}
        />
        <LabelledValue
          isLoading={isLoading}
          label='Entry Price'
          value={localFormatCurrency(entryPrice, 6)}
        />
        <LabelledValue
          isLoading={isLoading}
          label='Liquidation Price'
          labelHint="Lenders may liquidate the position if the asset's price falls to or below a 90% LTV threshold."
          value={localFormatCurrency(liquidationPrice, 6)}
        />
      </div>
    </div>
  )
})
