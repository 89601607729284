import { Conditional, TokenImage } from '@/app/components'
import { clsxm } from '@/app/helpers/clsxm'
import { type TokenInfo } from '@/services/types'

type CoinButtonProps = {
  token: TokenInfo
  disabled?: boolean
  onClick?: () => void
  className?: string
  loading?: boolean
}

export const CoinButton = ({ token, onClick, className, loading }: CoinButtonProps) => {
  return (
    <>
      <Conditional if={loading}>
        <div
          className={clsxm('desk:hover:bg-opacity-10 flex h-[44px] select-none items-center justify-between gap-[5px] rounded-lg bg-opacity-5 px-[8px] transition')}
        >
          <div className='loading loading-spinner loading-md'/>
        </div>
      </Conditional>
      <Conditional if={!loading}>
        <button
          className={clsxm(
            'desk:hover:bg-opacity-10 flex h-[44px] select-none items-center justify-between gap-[5px] rounded-lg bg-opacity-5 px-[8px] transition',
            {
              'bg-alt text-alt': token?.whitelisted,
              'bg-main text-main': !token?.whitelisted,
            },
            className,
          )}
          onClick={onClick}
        >
          <TokenImage altName={token?.name} logoURI={token?.logoURI} tag={token?.isNewCoin ? 'NEW' : token?.isTopTrendingCoins ? 'HOT' : null}/>
          <span
            className={clsxm('select-none text-sm', {
              'text-alt': token?.whitelisted,
            })}
          >
            {token?.symbol?.toUpperCase()}
          </span>
        </button>
      </Conditional>
    </>
  )
}
