import { Conditional } from '@/app/components'
import type { PropsWithChildren } from 'react'
import { IconHint, IconHintAsc } from '../../assets/index.js'
import { clsxm } from '../helpers/clsxm.js'

type HintProps = PropsWithChildren<{
  text?: string
  className?: string
  position?: 'top' | 'bottom' | 'left' | 'right'
  isAscIcon?: boolean
  width?: number
}>

export function Hint({ text, className, position = 'top', isAscIcon = false, width, children }: HintProps) {
  return (
    <div className={`dropdown dropdown-${position}`}>
      <div className={clsxm('text-info', className)} role='button' tabIndex={0}>
        {isAscIcon ? <IconHintAsc/> : <IconHint height={16} width={16}/>}
      </div>
      <div
        className={clsxm('card dropdown-content bg-background/90 z-10 rounded-xl p-3', width ? `w-[${width}px]` : 'w-48')}
        tabIndex={0}
      >
        <Conditional if={text}>
          <span className='text-left text-sm opacity-70'>{text}</span>
        </Conditional>
        <Conditional if={!text && children}>
          <div className=''>{children}</div>
        </Conditional>

      </div>
    </div>
  )
}
