import { useLavarage } from '@/app/providers/LavarageProvider'
import { usePreferencesStore } from '@/app/stores'
import { formatLamportsToSol } from '@/utils'
import { useWallet } from '@solana/wallet-adapter-react'
import { type PublicKey } from '@solana/web3.js'
import { useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'

type Balance = {
  availableSol: number | null
  connected: boolean
  connecting: boolean
  publicKey: PublicKey
}

const activeSubscriptions = {}

export function useBalance(): Balance {
  const lavarage = useLavarage()
  const [availableSol, setAvailableSol] = usePreferencesStore(useShallow(s => [s.availableSol, s.setAvailableSol]))
  const { connected, connecting, publicKey } = useWallet()

  useEffect(() => {
    if (!lavarage || !publicKey || !connected) {
      setAvailableSol(null)
      return
    }

    const { connection } = lavarage.program.provider

    if (availableSol === null) {
      connection
        .getBalance(publicKey)
        .then(res => setAvailableSol(formatLamportsToSol(res).toNumber()))
        .catch(error => {
          console.error('Error fetching balance:', error)
          setAvailableSol(null)
        })
    }

    const pubKeyString = publicKey.toString()

    if (activeSubscriptions[pubKeyString]) return
    const subscriptionId = connection.onAccountChange(
      publicKey,
      info => {
        if (!info.lamports) return
        setAvailableSol(formatLamportsToSol(info.lamports).toNumber())
      },
      'confirmed',
    )

    activeSubscriptions[pubKeyString] = subscriptionId

    return () => {
      if (activeSubscriptions[pubKeyString]) {
        connection.removeAccountChangeListener(activeSubscriptions[pubKeyString])
        delete activeSubscriptions[pubKeyString]
      }
    }
  }, [lavarage, publicKey, connected, setAvailableSol])

  return { availableSol, connected, connecting, publicKey }
}
