import { NodeWalletService, PoolService, type TokenInfo } from '@/services'
import { jupiterSource } from '@/services/sources'
import { formatSolToLamports, getPda, getRelatedPool } from '@/utils'
import { type AnchorProvider, Program, web3 } from '@coral-xyz/anchor'
import { type NodeWallet, type Pool, type Position } from '@lavarage/entities'
import { LavarageIdl } from '@lavarage/idls'
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, TokenAccountNotFoundError, TokenInvalidAccountOwnerError, createAssociatedTokenAccountInstruction, getAccount, getAssociatedTokenAddressSync, getMint } from '@solana/spl-token'
import { AddressLookupTableAccount, type Keypair, LAMPORTS_PER_SOL, PublicKey, SYSVAR_INSTRUCTIONS_PUBKEY, TransactionInstruction, TransactionMessage, VersionedTransaction } from '@solana/web3.js'
import BigNumber from 'bignumber.js'
import BN from 'bn.js'
import { API_HOST } from '../app/app.config.js'
import { BaseService } from './BaseService'

export class LavarageService extends BaseService {
  readonly program: Program<typeof LavarageIdl>
  tokenDecimals: Record<string, number> = {}
  positions: Position[] | [] = []
  private readonly nodeWalletService: NodeWalletService
  private readonly poolService: PoolService
  private tokenDecimalsCache: Record<string, number> = {}

  constructor(provider: AnchorProvider, programId: PublicKey) {
    super()
    this.program = new Program(LavarageIdl, programId, provider)
    this.nodeWalletService = new NodeWalletService()
    this.poolService = new PoolService(this.program)
  }

  async getTokenDecimals(tokenAddress: string) {
    if (this.tokenDecimals?.[tokenAddress]) {
      return this.tokenDecimals[tokenAddress]
    }

    const mintPublicKey = new web3.PublicKey(tokenAddress)
    const mintInfo = await getMint(this.program.provider.connection, mintPublicKey)
    const decimals = mintInfo.decimals

    this.tokenDecimals[tokenAddress] = decimals

    return decimals
  }

  async getTrendingCoins() {
    return (await this.http.get(`${API_HOST}/trending-coins`)).data
  }

  async getTokenAccountOrCreateIfNotExists(ownerPublicKey: PublicKey, tokenAddress: PublicKey) {
    if (!this.program.provider.publicKey) {
      throw new Error('Provider public key is not set')
    }

    const associatedTokenAddress = getAssociatedTokenAddressSync(tokenAddress, ownerPublicKey, true, TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID)

    try {
      const tokenAccount = await getAccount(this.program.provider.connection, associatedTokenAddress, 'finalized')
      return { account: tokenAccount, instruction: null }
    }
    catch (error) {
      if (error instanceof TokenAccountNotFoundError || error instanceof TokenInvalidAccountOwnerError) {
        const instruction = createAssociatedTokenAccountInstruction(
          this.program.provider.publicKey,
          associatedTokenAddress,
          ownerPublicKey,
          tokenAddress,
          TOKEN_PROGRAM_ID,
          ASSOCIATED_TOKEN_PROGRAM_ID,
        )

        return {
          account: {
            address: associatedTokenAddress,
          },
          instruction,
        }
      }
      else {
        console.error('Error in getTokenAccountOrCreateIfNotExists: ', error)

        return { account: null, instruction: null }
      }
    }
  }

  async getPools(): Promise<Pool[]> {
    const pools = await this.poolService.getPools()

    const uniqueNodeWalletKeys = [...new Set(pools.map(pool => pool.nodeWallet.publicKey))]

    const nodeWallets = await this.nodeWalletService.getNodeWallets()

    const uniqueNodeWallets = nodeWallets.filter((wallet: NodeWallet) => uniqueNodeWalletKeys.includes(wallet.publicKey))

    const nodeWalletsMap = uniqueNodeWallets.reduce((acc, wallet) => {
      acc[wallet.publicKey] = wallet
      return acc
    }, {})

    const poolsWithNodeWallet = pools.map(pool => ({
      ...pool,
      nodeWallet: nodeWalletsMap[pool.nodeWallet.publicKey],
    }))

    return poolsWithNodeWallet
  }

  async getPoolByTokenAddress(tokenAddress: string) {
    const pools = await this.getPools()

    return getRelatedPool({ address: tokenAddress } as TokenInfo, pools)
  }

  async batchGetTokenDecimals(tokenAddresses) {
    const decimalsInfo = {}

    for (const address of tokenAddresses) {
      if (!this.tokenDecimalsCache[address]) {
        const mintPublicKey = new web3.PublicKey(address)
        const mintInfo = await getMint(this.program.provider.connection, mintPublicKey)
        this.tokenDecimalsCache[address] = mintInfo.decimals
      }
      decimalsInfo[address] = this.tokenDecimalsCache[address]
    }

    return decimalsInfo
  }

  async openBorrowingPosition(
    positionSize: number,
    tokenAddress: string,
    userPays: number,
    jupInfo: {
      instructions: {
        setupInstructions: Record<string, unknown>[]
        swapInstruction: Record<string, unknown>
        addressLookupTableAddresses: string[]
      }
    },
    seed: Keypair,
    poolKey: string,
    nodeWallet: NodeWallet,
    maxInterestRate: number,
  ) {
    if (!this.program.provider.publicKey) {
      throw new Error('Provider public key is not set')
    }

    const [positionSizeBN, userPaysBN] = [
      new BN(formatSolToLamports(positionSize).decimalPlaces(0, 1).toString()),
      new BN(formatSolToLamports(userPays).toString()),
    ]

    const tokenAddressPubKey = new PublicKey(tokenAddress)
    const poolPubKey = new PublicKey(poolKey)

    const positionAccountPDA = getPda([Buffer.from('position'), this.program.provider.publicKey?.toBuffer(), poolPubKey.toBuffer(), seed.publicKey.toBuffer()])

    const fromTokenAccount = await this.getTokenAccountOrCreateIfNotExists(this.program.provider.publicKey, tokenAddressPubKey)

    const toTokenAccount = await this.getTokenAccountOrCreateIfNotExists(positionAccountPDA, tokenAddressPubKey)

    const tokenAccountCreationTx = new web3.Transaction()

    if (fromTokenAccount.instruction) {
      tokenAccountCreationTx.add(fromTokenAccount.instruction)
    }

    if (toTokenAccount.instruction) {
      tokenAccountCreationTx.add(toTokenAccount.instruction)
    }

    const instructionsJup = jupInfo.instructions

    const { setupInstructions, swapInstruction: swapInstructionPayload, addressLookupTableAddresses } = instructionsJup

    const deserializeInstruction = (instruction: any) => {
      return new TransactionInstruction({
        programId: new PublicKey(instruction.programId),

        keys: instruction.accounts.map((key: any) => ({
          pubkey: new PublicKey(key.pubkey),
          isSigner: key.isSigner,
          isWritable: key.isWritable,
        })),
        data: Buffer.from(instruction.data, 'base64'),
      })
    }

    const getAddressLookupTableAccounts = async (keys: string[]): Promise<AddressLookupTableAccount[]> => {
      const addressLookupTableAccountInfos = await this.program.provider.connection.getMultipleAccountsInfo(keys.map(key => new PublicKey(key)))

      return addressLookupTableAccountInfos.reduce((acc, accountInfo, index) => {
        const addressLookupTableAddress = keys[index]
        if (accountInfo) {
          const addressLookupTableAccount = new AddressLookupTableAccount({
            key: new PublicKey(addressLookupTableAddress),
            state: AddressLookupTableAccount.deserialize(accountInfo.data),
          })
          acc.push(addressLookupTableAccount)
        }

        return acc
      }, new Array<AddressLookupTableAccount>())
    }

    const addressLookupTableAccounts: AddressLookupTableAccount[] = []

    addressLookupTableAccounts.push(...(await getAddressLookupTableAccounts(addressLookupTableAddresses)))

    const { blockhash } = await this.program.provider.connection.getLatestBlockhash('finalized')

    const tradingOpenBorrowInstruction = await this.program.methods
      .tradingOpenBorrow(positionSizeBN, userPaysBN)
      .accountsStrict({
        nodeWallet: nodeWallet.publicKey,
        instructions: SYSVAR_INSTRUCTIONS_PUBKEY,
        tradingPool: poolPubKey,
        positionAccount: positionAccountPDA,
        trader: this.program.provider.publicKey,
        systemProgram: web3.SystemProgram.programId,
        clock: web3.SYSVAR_CLOCK_PUBKEY,
        randomAccountAsId: seed.publicKey.toBase58(),
        feeReceipient: '6JfTobDvwuwZxZP6FR5JPmjdvQ4h4MovkEVH2FPsMSrF',
      })
      .instruction()

    const openAddCollateralInstruction = await this.program.methods
      .tradingOpenAddCollateral(maxInterestRate)
      .accountsStrict({
        tradingPool: poolPubKey,
        trader: this.program.provider.publicKey,
        mint: tokenAddressPubKey,
        toTokenAccount: toTokenAccount.account.address,
        systemProgram: web3.SystemProgram.programId,
        positionAccount: positionAccountPDA,
        randomAccountAsId: seed.publicKey.toBase58(),
      })
      .instruction()

    const jupiterIxs = [
      ...setupInstructions.map(deserializeInstruction),
      deserializeInstruction(swapInstructionPayload),
    ]

    const allInstructions = [
      fromTokenAccount.instruction,
      toTokenAccount.instruction,
      tradingOpenBorrowInstruction,
      ...jupiterIxs,
      openAddCollateralInstruction,
    ].filter(Boolean)

    const messageV0 = new TransactionMessage({
      payerKey: this.program.provider.publicKey,
      recentBlockhash: blockhash,
      instructions: allInstructions,
    }).compileToV0Message(addressLookupTableAccounts)

    const tx = new VersionedTransaction(messageV0)

    return tx
  }

  async repaySol(poolKey: string, borrowedAmount: number, seed: PublicKey, collateralAmount: number) {
    if (!this.program.provider.publicKey) {
      throw new Error('Provider public key is not set')
    }

    const pool = await this.poolService.getPoolByKey(poolKey)
    const poolPubKey = new PublicKey(poolKey)
    const { blockhash } = await this.program.provider.connection.getLatestBlockhash('finalized')
    const tokenAddressPubKey = new PublicKey(pool.baseCurrency.address)
    const positionAccountPDA = getPda([Buffer.from('position'), this.program.provider.publicKey?.toBuffer(), poolPubKey.toBuffer(), seed.toBuffer()])
    const fromTokenAccount = await this.getTokenAccountOrCreateIfNotExists(positionAccountPDA, tokenAddressPubKey)
    const toTokenAccount = await this.getTokenAccountOrCreateIfNotExists(this.program.provider.publicKey, tokenAddressPubKey)

    const closePositionIx = await this.program.methods
      .tradingCloseBorrowCollateral()
      .accountsStrict({
        tradingPool: poolKey,
        instructions: SYSVAR_INSTRUCTIONS_PUBKEY,
        mint: pool.baseCurrency.address,
        fromTokenAccount: fromTokenAccount.account.address,
        toTokenAccount: toTokenAccount.account.address,
        positionAccount: positionAccountPDA,
        clock: web3.SYSVAR_CLOCK_PUBKEY,
        systemProgram: web3.SystemProgram.programId,
        trader: this.program.provider.publicKey,
        tokenProgram: TOKEN_PROGRAM_ID,
        randomAccountAsId: seed,
      })
      .instruction()

    const jupiterQuote = await jupiterSource.getPrice(tokenAddressPubKey.toBase58(), 'SOL')

    const decimal = await this.getTokenDecimals(tokenAddressPubKey.toBase58())

    const repaySolIx = await this.program.methods
      .tradingCloseRepaySol(
        new BN(BigNumber(collateralAmount)
          .multipliedBy(LAMPORTS_PER_SOL)
          .div(10 ** decimal)
          .multipliedBy(BigNumber(jupiterQuote.data[tokenAddressPubKey.toBase58()].price).times(BigNumber(10).pow(decimal))).toNumber()),
        new BN(9997),
      )
      .accountsStrict({
        nodeWallet: pool.nodeWallet.publicKey,
        positionAccount: positionAccountPDA,
        tradingPool: poolKey,
        trader: this.program.provider.publicKey,
        systemProgram: web3.SystemProgram.programId,
        clock: web3.SYSVAR_CLOCK_PUBKEY,
        randomAccountAsId: seed,
        feeReceipient: '6JfTobDvwuwZxZP6FR5JPmjdvQ4h4MovkEVH2FPsMSrF',
      })
      .instruction()

    const messageV0 = new TransactionMessage({
      payerKey: this.program.provider.publicKey,
      recentBlockhash: blockhash,
      instructions: [
        toTokenAccount.instruction,
        closePositionIx,
        repaySolIx,
      ].filter(Boolean),
    }).compileToV0Message()
    const versionedTx = new VersionedTransaction(messageV0)

    return versionedTx
  }

  async sellPosition(
    poolKey: string,
    collateralAmount: string,
    seed: PublicKey,
    sellInfo: {
      instructions: {
        setupInstructions: Record<string, unknown>[]
        swapInstruction: Record<string, unknown>
        cleanupInstruction: Record<string, unknown>
        addressLookupTableAddresses: string[]
      }
    },
  ) {
    if (!this.program.provider.publicKey) {
      throw new Error('Provider public key is not set')
    }

    const pool = await this.poolService.getPoolByKey(poolKey)
    const poolPubKey = new PublicKey(poolKey)

    const tokenAddressPubKey = new PublicKey(pool.baseCurrency.address)

    const positionAccountPDA = getPda([Buffer.from('position'), this.program.provider.publicKey.toBuffer(), poolPubKey.toBuffer(), seed.toBuffer()])

    const fromTokenAccount = await this.getTokenAccountOrCreateIfNotExists(positionAccountPDA, tokenAddressPubKey)

    const toTokenAccount = await this.getTokenAccountOrCreateIfNotExists(this.program.provider.publicKey, tokenAddressPubKey)

    const jupiterSellIx = sellInfo.instructions

    const { setupInstructions, swapInstruction: swapInstructionPayload, cleanupInstruction, addressLookupTableAddresses } = jupiterSellIx

    const deserializeInstruction = (instruction: any) => {
      return new TransactionInstruction({
        programId: new PublicKey(instruction.programId),

        keys: instruction.accounts.map((key: any) => ({
          pubkey: new PublicKey(key.pubkey),
          isSigner: key.isSigner,
          isWritable: key.isWritable,
        })),
        data: Buffer.from(instruction.data, 'base64'),
      })
    }

    const getAddressLookupTableAccounts = async (keys: string[]): Promise<AddressLookupTableAccount[]> => {
      const addressLookupTableAccountInfos = await this.program.provider.connection.getMultipleAccountsInfo(keys.map(key => new PublicKey(key)))

      return addressLookupTableAccountInfos.reduce((acc, accountInfo, index) => {
        const addressLookupTableAddress = keys[index]
        if (accountInfo) {
          const addressLookupTableAccount = new AddressLookupTableAccount({
            key: new PublicKey(addressLookupTableAddress),
            state: AddressLookupTableAccount.deserialize(accountInfo.data),
          })
          acc.push(addressLookupTableAccount)
        }

        return acc
      }, new Array<AddressLookupTableAccount>())
    }

    const addressLookupTableAccounts: AddressLookupTableAccount[] = []

    addressLookupTableAccounts.push(...(await getAddressLookupTableAccounts(addressLookupTableAddresses)))

    const { blockhash } = await this.program.provider.connection.getLatestBlockhash('finalized')

    const jupiterIxs = [
      ...setupInstructions.map(deserializeInstruction),
      deserializeInstruction(swapInstructionPayload),
      deserializeInstruction(cleanupInstruction),
    ]

    const closePositionIx = await this.program.methods
      .tradingCloseBorrowCollateral()
      .accountsStrict({
        tradingPool: poolKey,
        instructions: SYSVAR_INSTRUCTIONS_PUBKEY,
        mint: pool.baseCurrency.address,
        fromTokenAccount: fromTokenAccount.account.address,
        toTokenAccount: toTokenAccount.account.address,
        positionAccount: positionAccountPDA,
        clock: web3.SYSVAR_CLOCK_PUBKEY,
        systemProgram: web3.SystemProgram.programId,
        trader: this.program.provider.publicKey,
        tokenProgram: TOKEN_PROGRAM_ID,
        randomAccountAsId: seed,
      })
      .instruction()

    const decimal = await this.getTokenDecimals(tokenAddressPubKey.toBase58())

    const repaySolIx = await this.program.methods
      .tradingCloseRepaySol(new BN(sellInfo.quoteResponse.outAmount), new BN(9998))
      .accountsStrict({
        nodeWallet: pool.nodeWallet.publicKey,
        positionAccount: positionAccountPDA,
        tradingPool: poolKey,
        trader: this.program.provider.publicKey,
        systemProgram: web3.SystemProgram.programId,
        clock: web3.SYSVAR_CLOCK_PUBKEY,
        randomAccountAsId: seed,
        feeReceipient: '6JfTobDvwuwZxZP6FR5JPmjdvQ4h4MovkEVH2FPsMSrF',
      })
      .instruction()

    const allInstructions = [
      toTokenAccount.instruction,
      closePositionIx,
      ...jupiterIxs,
      repaySolIx,
    ].filter(i => i)

    const messageV0 = new TransactionMessage({
      payerKey: this.program.provider.publicKey,
      recentBlockhash: blockhash,
      instructions: allInstructions,
    }).compileToV0Message(addressLookupTableAccounts)

    const tx = new VersionedTransaction(messageV0)

    return tx
  }

  async accruedInterest(poolKey: string, positionAccount: string, seed: PublicKey) {
    const [poolKeyPubKey, positionAccountPubKey] = [new PublicKey(poolKey), new PublicKey(positionAccount)]

    const { nodeWallet } = await this.poolService.getPoolByKey(poolKey)

    const ix = await this.program.methods
      .tradingDataAccruedInterest()
      .accountsStrict({
        positionAccount: positionAccountPubKey,
        trader: this.program.provider.publicKey,
        tradingPool: poolKeyPubKey,
        nodeWallet,
        clock: web3.SYSVAR_CLOCK_PUBKEY,
        systemProgram: web3.SystemProgram.programId,
        randomAccountAsId: seed,
        feeReceipient: '6JfTobDvwuwZxZP6FR5JPmjdvQ4h4MovkEVH2FPsMSrF',
      })
      .instruction()

    const data = await this.program.provider.connection.getLatestBlockhash()

    const msg = new TransactionMessage({
      payerKey: this.program.provider.publicKey,
      recentBlockhash: data.blockhash,
      instructions: [ix],
    }).compileToV0Message()

    const tx = new VersionedTransaction(msg)

    const transactionSimulation = await this.program.provider.connection.simulateTransaction(tx)

    const transactionLogs = transactionSimulation.value.logs

    const returnPrefix = `Program return: ${this.program.programId} `
    const returnLogEntry = transactionLogs?.find(log => log.startsWith(returnPrefix))

    if (returnLogEntry) {
      const encodedReturnData = returnLogEntry.slice(returnPrefix.length)
      const decodedBuffer = Buffer.from(encodedReturnData, 'base64')
      const dataView = new DataView(decodedBuffer.buffer, decodedBuffer.byteOffset, decodedBuffer.byteLength)
      if (typeof dataView.getBigInt64 === 'function') {
        const number = Number(dataView.getBigInt64(0, true))
        return number
      }
      else {
        const number = Number(dataView.getFloat64(0))
        return number
      }
    }
  }

  private async createAccruedInterestInstruction(position: Position) {
    if (!this.program.provider.publicKey) {
      throw new Error('Provider public key is not set')
    }

    if (!position.pool.nodeWallet?.publicKey) {
      throw new Error(`Pool's Node wallet public key is not set`)
    }

    const ix = await this.program.methods
      .tradingDataAccruedInterest()
      .accountsStrict({
        positionAccount: position.publicKey,
        trader: this.program.provider.publicKey,
        tradingPool: position.pool.publicKey,
        nodeWallet: position.pool.nodeWallet.publicKey,
        clock: web3.SYSVAR_CLOCK_PUBKEY,
        systemProgram: web3.SystemProgram.programId,
        randomAccountAsId: position.seed,
        feeReceipient: '6JfTobDvwuwZxZP6FR5JPmjdvQ4h4MovkEVH2FPsMSrF',
      })
      .instruction()

    return ix
  }

  private async batchAccruedInterest(positions: Position[]): Promise<Record<string, number>> {
    if (!this.program.provider.publicKey) {
      throw new Error('Provider public key is not set')
    }

    const instructions = await Promise.all(positions.map(position => this.createAccruedInterestInstruction(position)))

    function parseLogEntry(logEntry, programId) {
      const returnPrefix = `Program return: ${programId} `
      if (logEntry.startsWith(returnPrefix)) {
        const encodedReturnData = logEntry.slice(returnPrefix.length)
        const decodedBuffer = Buffer.from(encodedReturnData, 'base64')
        const dataView = new DataView(decodedBuffer.buffer, decodedBuffer.byteOffset, decodedBuffer.byteLength)

        if (typeof dataView.getBigInt64 === 'function') {
          return Number(dataView.getBigInt64(0, true))
        }
        else {
          return Number(dataView.getFloat64(0))
        }
      }
      return null
    }

    function processTransactionLogs(transactionLogs, programId) {
      const accruedInterests: number[] = []

      for (const logEntry of transactionLogs) {
        const interest = parseLogEntry(logEntry, programId)
        if (interest !== null) {
          accruedInterests.push(interest)
        }
      }

      return accruedInterests
    }

    const data = await this.program.provider.connection.getLatestBlockhash()

    const msg = new TransactionMessage({
      payerKey: this.program.provider.publicKey,
      recentBlockhash: data.blockhash,
      instructions: [...instructions],
    }).compileToV0Message()

    const tx = new VersionedTransaction(msg)

    const transactionSimulation = await this.program.provider.connection.simulateTransaction(tx)

    const transactionLogs = transactionSimulation.value.logs

    const accruedInterests = processTransactionLogs(transactionLogs, this.program.programId)

    return positions.reduce((acc, position, index) => {
      acc[position.publicKey] = accruedInterests[index]
      return acc
    }, {})
  }
}
