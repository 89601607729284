import { trackClaimCancelledGA, trackClaimExecuteGA, trackClaimFailedGA, trackClaimSuccessGA } from '@/app/analytics'
import { Button, Conditional, For } from '@/app/components'
import { sendTransaction } from '@/app/helpers/sendTransaction'
import { useUnstakeAccountsQuery } from '@/app/hooks/queries'
import { ClaimRow } from '@/app/pages/stake/components'
import { useStendingService } from '@/app/providers/StendingProvider'
import { useAlertsStore } from '@/app/stores'
import { IconEmptyClaim } from '@/assets'
import { LSTSOL_LOGO_URI, SOL_LOGO_URI } from '@/config'
import { formatTimestamp } from '@/utils/formatters/formatTimestamp'
import { useWallet } from '@solana/wallet-adapter-react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'

export function ClaimWidget() {
  const wallet = useWallet()
  const location = useLocation()
  const navigate = useNavigate()
  const stendingService = useStendingService()
  if (!stendingService) return null
  const { unstakeAccounts = [] } = useUnstakeAccountsQuery()
  const [addAlert, removeAlert, setConfirming] = useAlertsStore(useShallow(state => [state.addAlert, state.removeAlert, state.setConfirming]))
  const [confirmingIndexes, setConfirmingIndexes] = useState<number[]>([])
  const [finalizingIndexes, setFinalizingIndexes] = useState<number[]>([])
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC'

  const claimCallback = async (claimPk: string, index: number, amountForGA: number) => {
    trackClaimExecuteGA()
    const tx = await stendingService.claim(claimPk)
    const txDetailsForSentry = {
      type: 'Claim',
      walletAddress: wallet.publicKey?.toBase58(),
      walletAdapter: wallet.wallet?.adapter.name,
    }

    setConfirmingIndexes(prev => [...prev, index])
    try {
      const result = await sendTransaction(tx, addAlert, removeAlert, setConfirming, stendingService.program.provider, wallet, txDetailsForSentry)
      if (!result) {
        setConfirmingIndexes(prev => prev.filter(i => i !== index))
      }

      if (result) {
        trackClaimSuccessGA({ solReceived: amountForGA })
        let accountFetched = false
        while (!accountFetched) {
          try {
            await stendingService.program.account.unstakeAccount.fetch(claimPk)
          }
          catch (e) {
            accountFetched = true
          }
          if (!accountFetched) {
            await new Promise(resolve => {
              setTimeout(resolve, 2000)
            })
          }
        }

        setConfirmingIndexes(prev => prev.filter(i => i !== index))
        setFinalizingIndexes(prev => [...prev, index])
        await stendingService.getUnstakeAccounts(true)
      }
    }
    catch (err) {
      if ((err as Error).message.includes('User rejected the request')) {
        trackClaimCancelledGA()
        console.error('User rejected the claim request', err)
      }
      else {
        trackClaimFailedGA()
        console.error('Failed to claim', err)
      }
    }
    finally {
      setConfirmingIndexes(prev => prev.filter(i => i !== index))
    }
  }

  useEffect(() => {
    if (!confirmingIndexes.length) {
      setConfirming(false)
    }
  }, [confirmingIndexes, setConfirming])

  const sortedClaims = unstakeAccounts?.sort((claimA, claimB) => claimA.unstakeTimestamp - claimB.unstakeTimestamp) || []

  return (
    <>
      <Conditional if={unstakeAccounts?.length}>
        <ul className='flex flex-col gap-[10px]'>
          <For of={sortedClaims}>
            {(claim, index) => (
              <ClaimRow
                key={claim.publicKey}
                from={{
                  symbol: 'LSTSOL',
                  imageUri: LSTSOL_LOGO_URI,
                  amount: claim.sourceTokenQuantity.toNumber(),
                }}
                isConfirming={confirmingIndexes.includes(index)}
                isFinalizing={finalizingIndexes.includes(index)}
                timestampClaim={claim.timestampClaim}
                to={{
                  symbol: 'SOL',
                  imageUri: SOL_LOGO_URI,
                  amount: claim.targetTokenQuantity.toNumber(),
                }}
                unstakedDate={formatTimestamp(claim.unstakeTimestamp, timezone)}
                onClaim={() => claimCallback(claim.publicKey, index, claim.targetTokenQuantity.toNumber())}
                onUnmount={() => setFinalizingIndexes(prev => prev.filter(i => i !== index))}
              />
            )}
          </For>
        </ul>
      </Conditional>
      <Conditional if={!unstakeAccounts?.length}>
        <div className='m-auto flex flex-col items-center justify-center text-center text-base'>
          <IconEmptyClaim/>
          <p className='text-main my-5 max-w-[400px]'>You have no pending claims at the moment.</p>
          <Button className='mx-auto' color='gradient' size='m' onClick={() => navigate({ pathname: '/stake/unstake', search: location.search })}>
            Unstake
          </Button>
        </div>
      </Conditional>
    </>
  )
}
