import { TabItem, TabsList } from '@/app/components'
import { Hint } from '../../../components/Hint.js'

enum Tabs {
  OPEN = 'open',
  CLOSED = 'closed',
}

type TabControlProps = {
  currentTab: Tabs
  setCurrentTab: (t: Tabs) => void
  quantityOfActivePositions: number
}

interface TabItemProps {
  tab: Tabs
  currentTab: string
  setCurrentTab: (t: Tabs) => void
  label: string
  quantity?: number
}

const PositionTabItem = ({ tab, currentTab, setCurrentTab, label, quantity }: TabItemProps) => {
  const isActive = currentTab === tab
  const handleClick = () => {
    setCurrentTab(tab)
    window.scrollTo(0, 0)
  }

  return <TabItem handleClick={handleClick} isActive={isActive} label={label} quantity={quantity}/>
}

export const PositionTabControl = ({ currentTab, setCurrentTab, quantityOfActivePositions }: TabControlProps) => {
  return (
    <TabsList>
      <PositionTabItem currentTab={currentTab} label='Open' quantity={quantityOfActivePositions} setCurrentTab={setCurrentTab} tab={Tabs.OPEN}/>
      <PositionTabItem currentTab={currentTab} label='Closed' setCurrentTab={setCurrentTab} tab={Tabs.CLOSED}/>
      <Hint className='ml-[5px]' isAscIcon={true} position='left' text='New positions may take minutes to appear due to network congestion and processing times.'/>
    </TabsList>
  )
}
